import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'primereact/badge';

export const ModbusStatusToIcon = ({ modbusStatus, csq }) => {
  return csq === 0 ? (
    <FontAwesomeIcon icon="plug" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, color: 'gray' }} />
  ) : modbusStatus === 'ESP_OK' ? (
    <FontAwesomeIcon icon="plug-circle-check" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, color: 'green' }} />
  ) : (
    <FontAwesomeIcon icon="plug-circle-xmark" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, color: 'red' }} />
  );
};

export const WifiSignalToIcon = ({ csq }) => {
  return csq >= 2 && csq < 10 ? (
    <FontAwesomeIcon icon="wifi" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, marginRight: 5, color: 'red' }} />
  ) : csq >= 10 && csq < 20 ? (
    <FontAwesomeIcon icon="wifi" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, marginRight: 5, color: 'orange' }} />
  ) : csq >= 20 ? (
    <FontAwesomeIcon icon="wifi" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, marginRight: 5, color: 'green' }} />
  ) : (
    <FontAwesomeIcon icon="wifi-slash" style={{ fontSize: '1.3rem', margin: 1, marginLeft: 5, marginRight: 5, color: 'red' }} />
  );
};

export const AlarmCountToIcon = ({ alarmCount }) => {
  return (
    <i
      className={'pi pi-bell ml-2 mr-2 p-text-secondary p-overlay-badge ' + (alarmCount > 0 && 'scalein animation-duration-1000 animation-iteration-infinite')}
      style={{ fontSize: '1.3rem', color: 'var(--main-color-0)' }}
    >
      <Badge value={alarmCount}></Badge>
    </i>
  );
};

// export const wifiSignalToClassName = (csq: any) => {
//   return !csq ? 'gray' : csq >= 20 ? 'green' : csq >= 12 ? 'orange' : csq >= 7 ? 'red' : 'gray';
// };

// export const wifiSignalToClassName1 = (csq: any) => {
//   return !csq ? 'lightgray' : csq >= 20 ? 'green' : csq >= 12 ? 'orange' : csq >= 7 ? 'red' : 'lightgray';
// };

// export const wifiSignalToString = (csq: any) => {
//   const { t } = useTranslation();
//   return csq >= 20
//     ? t('smartmanagerApp.device.csqs.high')
//     : csq >= 12
//     ? t('smartmanagerApp.device.csqs.medium')
//     : csq >= 7
//     ? t('smartmanagerApp.device.csqs.low')
//     : t('smartmanagerApp.device.csqs.off');
// };

// export const itemTemplate = value => {
//   const { t } = useTranslation();
//   return (
//     <div className="p-clearfix">
//       <span style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}>{t('smartmanagerApp.device.' + value.field)}</span>
//     </div>
//   );
// };

// export const selectedTemplate = value => {
//   const { t } = useTranslation();
//   if (value) {
//     return (
//       <div className="my-multiselected-item-token">
//         <span>{t('smartmanagerApp.device.' + value.field)}</span>
//       </div>
//     );
//   } else {
//     return <span className="my-multiselected-empty-token">Choose</span>;
//   }
// };

// const formatter = new Intl.NumberFormat('es-ES', {
//   style: 'currency',
//   currency: 'EUR',
// });

// export const generatePassword = length => {
//   const chars = 'abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWYZ123456789';
//   return _.sampleSize(chars, length).join('');
// };

// export const graphFilter = (prefixTranslate, variableType, options) => {};
