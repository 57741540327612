import { createSlice } from '@reduxjs/toolkit';
import { IPlant } from 'src/shared/model/plant.model';

export const plantSelectorSlice = createSlice({
  name: 'plantSelector',
  initialState: {
    selectedPlants: [] as IPlant[],
    selectedPlant: {} as IPlant,
    selectedDeviceId: 0,
    convElectricSearch: '',
    convElectricFirst: 0,
    convElectricTotalRecords: 0,
  },
  reducers: {
    setSelectedPlants(state, action) {
      state.selectedPlants = action.payload;
      if (action.payload.length > 0) {
        state.selectedPlant = action.payload[0];
      }
    },
    setSelectedPlant(state, action) {
      state.selectedPlant = action.payload;
      if (action.payload.length > 0) {
        state.selectedPlant = action.payload[0];
      }
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
  },
});

export const { setSelectedPlant, setSelectedPlants, setSelectedDeviceId } = plantSelectorSlice.actions;
export default plantSelectorSlice.reducer;
