import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import errorMiddleware from './config/error-middleware';
import loggerMiddleware from './config/logger-middleware';
import loadingBarReducer from './reducers/loading-bar.reducer';
import localeReducer from './reducers/currentLocale.reducer';
import appMenuReducer from './reducers/app-menu.reducer';
import appSettingReducer from './reducers/app-setting.reducer';
import authenticationReducer from './reducers/authentication';
import electricSgmAlarmConfigReducer from './reducers/entities/electric-sgm-alarm-config.reducer';
import electricSgmAlarmLogReducer from './reducers/entities/electric-sgm-alarm-log.reducer';

import plotsReducer from './reducers/modules/plots.reducer';
import plantReducer from './reducers/entities/plant.reducer';
import plantSelectorReducer from './reducers/plant-selector.reducer';

import electricCostReportReducer from './reducers/entities/electric-cost-report.reducer';
import deviceReducer from './reducers/entities/device.reducer';

import electricPeriodReducer from './reducers/entities/electric-period.reducer';
import electricTariffReducer from './reducers/entities/electric-tariff.reducer';
import toastMessageReducer from './reducers/toast-message.reducer';

import notificationMiddleware from './config/notification-middleware';

export const store = configureStore({
  reducer: {
    loadingBar: loadingBarReducer,
    authentication: authenticationReducer,
    appSettings: appSettingReducer,
    appMenu: appMenuReducer,
    device: deviceReducer,
    locale: localeReducer,
    toastMessage: toastMessageReducer,
    electricPeriod: electricPeriodReducer,
    electricSgmAlarmConfig: electricSgmAlarmConfigReducer,
    electricSgmAlarmLog: electricSgmAlarmLogReducer,
    electricCostReport: electricCostReportReducer,
    electricTariff: electricTariffReducer,
    plots: plotsReducer,
    plant: plantReducer,
    plantSelector: plantSelectorReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.config', 'payload.headers', 'payload.request', 'error', 'meta.arg'],
      },
    }).concat(errorMiddleware, loggerMiddleware, notificationMiddleware), //notificationMiddleware
});

const getStore = () => store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default getStore;
