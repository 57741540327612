import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

export const PlotDonut = (props: { data: any; centerText: any; height: any }) => {
  const currentLocale = 'es';
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({
    datarevision: 0,

    annotations: [
      {
        font: {
          size: 20,
        },
        showarrow: false,
        textinfo: 'label+percent',
        text: '',
        x: 0.5,
        y: 0.5,
      },
    ],
    margin: {
      b: 0,
      t: 0,
      r: 50,
      l: 50,
    },
    autosize: true,
    showlegend: true,
    legend: {
      orientation: 'h',
      font: {
        size: 14,
      },
    },
  });

  useEffect(() => {
    if (props.data) {
      setLayout(layoutT => ({
        ...layoutT,
        annotations: [
          {
            font: {
              size: 14,
            },
            showarrow: false,
            textinfo: 'label+percent',
            textposition: 'outside',
            text: props.centerText,
            x: 0.5,
            y: 0.5,
          },
        ],
        datarevision: layout.datarevision + 1,
        showlegend: true,
        legend: {
          orientation: 'h',
          font: {
            size: 14,
          },
        },
      }));
      setData([props.data]);
    }
  }, [props.data]);

  return (
    <div style={{ height: props.height }}>
      {props.data && props.data.values.length > 0 && <Plot className="w-full h-full" data={data} layout={layout} config={{ responsive: true, locale: currentLocale, displaylogo: false }} />}
    </div>
  );
};
