import i18next from 'i18next';
import { PLOT_ENERGY, PLOT_PV_ENERGY, SINGLE_PHASE } from 'src/app/config/constants';

const t = i18next.t;

export const electricDataTriOptions = model =>
  model === 'MT61WF-4P'
    ? [
        { label: t('convElectricData:tri.volPA'), value: 'volPA', suffix: ' V' },
        { label: t('convElectricData:tri.volPB'), value: 'volPB', suffix: ' V' },
        { label: t('convElectricData:tri.volPC'), value: 'volPC', suffix: ' V' },
        { label: t('convElectricData:tri.volLA'), value: 'volLA', suffix: ' V' },
        { label: t('convElectricData:tri.volLB'), value: 'volLB', suffix: ' V' },
        { label: t('convElectricData:tri.volLC'), value: 'volLC', suffix: ' V' },
        { label: t('convElectricData:tri.curA'), value: 'curA', suffix: ' A' },
        { label: t('convElectricData:tri.curB'), value: 'curB', suffix: ' A' },
        { label: t('convElectricData:tri.curC'), value: 'curC', suffix: ' A' },
        { label: t('convElectricData:tri.insPT'), value: 'insPT', suffix: ' kW' },
        { label: t('convElectricData:tri.insPA'), value: 'insPA', suffix: ' kW' },
        { label: t('convElectricData:tri.insPB'), value: 'insPB', suffix: ' kW' },
        { label: t('convElectricData:tri.insPC'), value: 'insPC', suffix: ' kW' },
        { label: t('convElectricData:tri.insQT'), value: 'insQT', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insQA'), value: 'insQA', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insQB'), value: 'insQB', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insQC'), value: 'insQC', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insST'), value: 'insST', suffix: ' kVA' },
        { label: t('convElectricData:tri.insSA'), value: 'insSA', suffix: ' kVA' },
        { label: t('convElectricData:tri.insSB'), value: 'insSB', suffix: ' kVA' },
        { label: t('convElectricData:tri.insSC'), value: 'insSC', suffix: ' kVA' },
        { label: t('convElectricData:tri.pwrFA'), value: 'pwrFA', suffix: ' ' },
        { label: t('convElectricData:tri.pwrFB'), value: 'pwrFB', suffix: ' ' },
        { label: t('convElectricData:tri.pwrFC'), value: 'pwrFC', suffix: ' ' },
        { label: t('convElectricData:tri.epIT'), value: 'epIT', suffix: ' kWh' },
        { label: t('convElectricData:tri.epET'), value: 'epET', suffix: ' kWh' },
      ]
    : [
        { label: t('convElectricData:tri.volPA'), value: 'volPA', suffix: ' V' },
        { label: t('convElectricData:tri.volPB'), value: 'volPB', suffix: ' V' },
        { label: t('convElectricData:tri.volPC'), value: 'volPC', suffix: ' V' },
        { label: t('convElectricData:tri.volLA'), value: 'volLA', suffix: ' V' },
        { label: t('convElectricData:tri.volLB'), value: 'volLB', suffix: ' V' },
        { label: t('convElectricData:tri.volLC'), value: 'volLC', suffix: ' V' },
        { label: t('convElectricData:tri.curA'), value: 'curA', suffix: ' A' },
        { label: t('convElectricData:tri.curB'), value: 'curB', suffix: ' A' },
        { label: t('convElectricData:tri.curC'), value: 'curC', suffix: ' A' },
        { label: t('convElectricData:tri.insPT'), value: 'insPT', suffix: ' kW' },
        { label: t('convElectricData:tri.insPA'), value: 'insPA', suffix: ' kW' },
        { label: t('convElectricData:tri.insPB'), value: 'insPB', suffix: ' kW' },
        { label: t('convElectricData:tri.insPC'), value: 'insPC', suffix: ' kW' },
        { label: t('convElectricData:tri.insQT'), value: 'insQT', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insQA'), value: 'insQA', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insQB'), value: 'insQB', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insQC'), value: 'insQC', suffix: ' kVAr' },
        { label: t('convElectricData:tri.insST'), value: 'insST', suffix: ' kVA' },
        { label: t('convElectricData:tri.insSA'), value: 'insSA', suffix: ' kVA' },
        { label: t('convElectricData:tri.insSB'), value: 'insSB', suffix: ' kVA' },
        { label: t('convElectricData:tri.insSC'), value: 'insSC', suffix: ' kVA' },
        { label: t('convElectricData:tri.pwrFA'), value: 'pwrFA', suffix: ' ' },
        { label: t('convElectricData:tri.pwrFB'), value: 'pwrFB', suffix: ' ' },
        { label: t('convElectricData:tri.pwrFC'), value: 'pwrFC', suffix: ' ' },
        { label: t('convElectricData:tri.volPATHD'), value: 'volPATHD', suffix: '%' },
        { label: t('convElectricData:tri.volPBTHD'), value: 'volPBTHD', suffix: '%' },
        { label: t('convElectricData:tri.volPCTHD'), value: 'volPCTHD', suffix: '%' },
        { label: t('convElectricData:tri.curATHD'), value: 'curATHD', suffix: '%' },
        { label: t('convElectricData:tri.curBTHD'), value: 'curBTHD', suffix: '%' },
        { label: t('convElectricData:tri.curCTHD'), value: 'curCTHD', suffix: '%' },
        { label: t('convElectricData:tri.freq'), value: 'freq', suffix: ' Hz' },
        { label: t('convElectricData:tri.epIT'), value: 'epIT', suffix: ' kWh' },
        { label: t('convElectricData:tri.epIA'), value: 'epIA', suffix: ' kWh' },
        { label: t('convElectricData:tri.epIB'), value: 'epIB', suffix: ' kWh' },
        { label: t('convElectricData:tri.epIC'), value: 'epIC', suffix: ' kWh' },
        { label: t('convElectricData:tri.epET'), value: 'epET', suffix: ' kWh' },
        { label: t('convElectricData:tri.epEA'), value: 'epEA', suffix: ' kWh' },
        { label: t('convElectricData:tri.epEB'), value: 'epEB', suffix: ' kWh' },
        { label: t('convElectricData:tri.epEC'), value: 'epEC', suffix: ' kWh' },
      ];

export const electricDataMonoOptions = () => {
  return [
    { label: t('convElectricData:mono.volPA'), value: 'volPA', suffix: ' V' },
    { label: t('convElectricData:mono.curT'), value: 'curT', suffix: ' A' },
    { label: t('convElectricData:mono.insPT'), value: 'insPT', suffix: ' kW' },
    { label: t('convElectricData:mono.insQT'), value: 'insQT', suffix: ' kVAr' },
    { label: t('convElectricData:mono.insST'), value: 'insST', suffix: ' kVA' },
    { label: t('convElectricData:mono.pwrFT'), value: 'pwrFA', suffix: ' ' },
    { label: t('convElectricData:mono.freq'), value: 'freq', suffix: ' Hz' },
    { label: t('convElectricData:mono.temp'), value: 'temp', suffix: ' ºC' },
    { label: t('convElectricData:mono.epIT'), value: 'epIT', suffix: ' kWh' },
    { label: t('convElectricData:mono.epET'), value: 'epET', suffix: ' kWh' },
  ];
};

export const energyDataTriOptions = model =>
  model && model === 'MT61WF-4P'
    ? [
        { label: t('convElectricData:tri.insEpIT'), value: 'insEpIT' },
        { label: t('convElectricData:tri.insEpET'), value: 'insEpET' },
        { label: t('convElectricData:tri.insEqIT'), value: 'insEqIT' },
        { label: t('convElectricData:tri.insEqET'), value: 'insEqET' },
      ]
    : [
        { label: t('convElectricData:tri.insEpIT'), value: 'insEpIT' },
        { label: t('convElectricData:tri.insEpIA'), value: 'insEpIA' },
        { label: t('convElectricData:tri.insEpIB'), value: 'insEpIB' },
        { label: t('convElectricData:tri.insEpIC'), value: 'insEpIC' },
        { label: t('convElectricData:tri.insEpET'), value: 'insEpET' },
        { label: t('convElectricData:tri.insEpEA'), value: 'insEpEA' },
        { label: t('convElectricData:tri.insEpEB'), value: 'insEpEB' },
        { label: t('convElectricData:tri.insEpEC'), value: 'insEpEC' },
        { label: t('convElectricData:tri.insEqIT'), value: 'insEqIT' },
        { label: t('convElectricData:tri.insEqIA'), value: 'insEqIA' },
        { label: t('convElectricData:tri.insEqIB'), value: 'insEqIB' },
        { label: t('convElectricData:tri.insEqIC'), value: 'insEqIC' },
        { label: t('convElectricData:tri.insEqET'), value: 'insEqET' },
        { label: t('convElectricData:tri.insEqEA'), value: 'insEqEA' },
        { label: t('convElectricData:tri.insEqEB'), value: 'insEqEB' },
        { label: t('convElectricData:tri.insEqEC'), value: 'insEqEC' },
      ];

export const energyDataMonoOptions = () => [
  { label: t('convElectricData:mono.insEpIT'), value: 'insEpIT' },
  { label: t('convElectricData:mono.insEpET'), value: 'insEpET' },
  { label: t('convElectricData:mono.insEqIT'), value: 'insEqIT' },
  { label: t('convElectricData:mono.insEqET'), value: 'insEqET' },
];

export const convElectricPlotVariableOptions = ({ plotVariable, voltajeLines, model }) => {
  if (plotVariable === PLOT_PV_ENERGY || plotVariable === PLOT_ENERGY) {
    if (voltajeLines === SINGLE_PHASE) {
      return [
        { label: t('convElectricData:mono.insEpIT'), value: 'insEpIT', suffix: ' kWh' },
        { label: t('convElectricData:mono.insEpET'), value: 'insEpET', suffix: ' kWh' },
        { label: t('convElectricData:mono.insEqIT'), value: 'insEqIT', suffix: ' kVArh' },
        { label: t('convElectricData:mono.insEqET'), value: 'insEqET', suffix: ' kVArh' },
      ];
    } else {
      return model && model === 'MT61WF-4P'
        ? [
            { label: t('convElectricData:tri.insEpIT'), value: 'insEpIT', suffix: ' kWh' },
            { label: t('convElectricData:tri.insEpET'), value: 'insEpET', suffix: ' kWh' },
            { label: t('convElectricData:tri.insEqIT'), value: 'insEqIT', suffix: ' kVArh' },
            { label: t('convElectricData:tri.insEqET'), value: 'insEqET', suffix: ' kVArh' },
          ]
        : [
            { label: t('convElectricData:tri.insEpIT'), value: 'insEpIT', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpIA'), value: 'insEpIA', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpIB'), value: 'insEpIB', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpIC'), value: 'insEpIC', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpET'), value: 'insEpET', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpEA'), value: 'insEpEA', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpEB'), value: 'insEpEB', suffix: ' kW' },
            { label: t('convElectricData:tri.insEpEC'), value: 'insEpEC', suffix: ' kW' },
            { label: t('convElectricData:tri.insEqIT'), value: 'insEqIT', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqIA'), value: 'insEqIA', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqIB'), value: 'insEqIB', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqIC'), value: 'insEqIC', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqET'), value: 'insEqET', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqEA'), value: 'insEqEA', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqEB'), value: 'insEqEB', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insEqEC'), value: 'insEqEC', suffix: ' kVAr' },
          ];
    }
  } else {
    if (voltajeLines === SINGLE_PHASE) {
      return [
        { label: t('convElectricData:mono.volPA'), value: 'volPA', suffix: ' V' },
        { label: t('convElectricData:mono.curT'), value: 'curT', suffix: ' A' },
        { label: t('convElectricData:mono.insPT'), value: 'insPT', suffix: ' kW' },
        { label: t('convElectricData:mono.insQT'), value: 'insQT', suffix: ' kVAr' },
        { label: t('convElectricData:mono.insST'), value: 'insST', suffix: ' kVA' },
        { label: t('convElectricData:mono.freq'), value: 'freq', suffix: ' Hz' },
        { label: t('convElectricData:mono.epIT'), value: 'epIT', suffix: ' kWh' },
        { label: t('convElectricData:mono.epET'), value: 'epET', suffix: ' kWh' },
        { label: t('convElectricData:mono.csq'), value: 'csq', suffix: ' ' },
      ];
    } else {
      return model && model === 'MT61WF-4P'
        ? [
            { label: t('convElectricData:tri.volPA'), value: 'volPA', suffix: ' V' },
            { label: t('convElectricData:tri.volPB'), value: 'volPB', suffix: ' V' },
            { label: t('convElectricData:tri.volPC'), value: 'volPC', suffix: ' V' },
            { label: t('convElectricData:tri.volLA'), value: 'volLA', suffix: ' V' },
            { label: t('convElectricData:tri.volLB'), value: 'volLB', suffix: ' V' },
            { label: t('convElectricData:tri.volLC'), value: 'volLC', suffix: ' V' },
            { label: t('convElectricData:tri.curA'), value: 'curA', suffix: ' A' },
            { label: t('convElectricData:tri.curB'), value: 'curB', suffix: ' A' },
            { label: t('convElectricData:tri.curC'), value: 'curC', suffix: ' A' },
            { label: t('convElectricData:tri.insPT'), value: 'insPT', suffix: ' kW' },
            { label: t('convElectricData:tri.insPA'), value: 'insPA', suffix: ' kW' },
            { label: t('convElectricData:tri.insPB'), value: 'insPB', suffix: ' kW' },
            { label: t('convElectricData:tri.insPC'), value: 'insPC', suffix: ' kW' },
            { label: t('convElectricData:tri.insQT'), value: 'insQT', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insQA'), value: 'insQA', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insQB'), value: 'insQB', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insQC'), value: 'insQC', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insST'), value: 'insST', suffix: ' kVA' },
            { label: t('convElectricData:tri.insSA'), value: 'insSA', suffix: ' kVA' },
            { label: t('convElectricData:tri.insSB'), value: 'insSB', suffix: ' kVA' },
            { label: t('convElectricData:tri.insSC'), value: 'insSC', suffix: ' kVA' },
            { label: t('convElectricData:tri.pwrFA'), value: 'pwrFA', suffix: ' ' },
            { label: t('convElectricData:tri.pwrFB'), value: 'pwrFB', suffix: ' ' },
            { label: t('convElectricData:tri.pwrFC'), value: 'pwrFC', suffix: ' ' },
            { label: t('convElectricData:tri.epIT'), value: 'epIT', suffix: ' kWh' },
            { label: t('convElectricData:tri.epET'), value: 'epET', suffix: ' kWh' },
            { label: t('convElectricData:tri.csq'), value: 'csq', suffix: ' ' },
          ]
        : [
            { label: t('convElectricData:tri.volPA'), value: 'volPA', suffix: ' V' },
            { label: t('convElectricData:tri.volPB'), value: 'volPB', suffix: ' V' },
            { label: t('convElectricData:tri.volPC'), value: 'volPC', suffix: ' V' },
            { label: t('convElectricData:tri.volLA'), value: 'volLA', suffix: ' V' },
            { label: t('convElectricData:tri.volLB'), value: 'volLB', suffix: ' V' },
            { label: t('convElectricData:tri.volLC'), value: 'volLC', suffix: ' V' },
            { label: t('convElectricData:tri.curA'), value: 'curA', suffix: ' A' },
            { label: t('convElectricData:tri.curB'), value: 'curB', suffix: ' A' },
            { label: t('convElectricData:tri.curC'), value: 'curC', suffix: ' A' },
            { label: t('convElectricData:tri.insPT'), value: 'insPT', suffix: ' kW' },
            { label: t('convElectricData:tri.insPA'), value: 'insPA', suffix: ' kW' },
            { label: t('convElectricData:tri.insPB'), value: 'insPB', suffix: ' kW' },
            { label: t('convElectricData:tri.insPC'), value: 'insPC', suffix: ' kW' },
            { label: t('convElectricData:tri.insQT'), value: 'insQT', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insQA'), value: 'insQA', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insQB'), value: 'insQB', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insQC'), value: 'insQC', suffix: ' kVAr' },
            { label: t('convElectricData:tri.insST'), value: 'insST', suffix: ' kVA' },
            { label: t('convElectricData:tri.insSA'), value: 'insSA', suffix: ' kVA' },
            { label: t('convElectricData:tri.insSB'), value: 'insSB', suffix: ' kVA' },
            { label: t('convElectricData:tri.insSC'), value: 'insSC', suffix: ' kVA' },
            { label: t('convElectricData:tri.pwrFA'), value: 'pwrFA', suffix: ' ' },
            { label: t('convElectricData:tri.pwrFB'), value: 'pwrFB', suffix: ' ' },
            { label: t('convElectricData:tri.pwrFC'), value: 'pwrFC', suffix: ' ' },
            { label: t('convElectricData:tri.volPATHD'), value: 'volPATHD', suffix: '%' },
            { label: t('convElectricData:tri.volPBTHD'), value: 'volPBTHD', suffix: '%' },
            { label: t('convElectricData:tri.volPCTHD'), value: 'volPCTHD', suffix: '%' },
            { label: t('convElectricData:tri.curATHD'), value: 'curATHD', suffix: '%' },
            { label: t('convElectricData:tri.curBTHD'), value: 'curBTHD', suffix: '%' },
            { label: t('convElectricData:tri.curCTHD'), value: 'curCTHD', suffix: '%' },
            { label: t('convElectricData:tri.freq'), value: 'freq', suffix: ' Hz' },
            { label: t('convElectricData:tri.epIT'), value: 'epIT', suffix: ' kWh' },
            { label: t('convElectricData:tri.epIA'), value: 'epIA', suffix: ' kWh' },
            { label: t('convElectricData:tri.epIB'), value: 'epIB', suffix: ' kWh' },
            { label: t('convElectricData:tri.epIC'), value: 'epIC', suffix: ' kWh' },
            { label: t('convElectricData:tri.epET'), value: 'epET', suffix: ' kWh' },
            { label: t('convElectricData:tri.epEA'), value: 'epEA', suffix: ' kWh' },
            { label: t('convElectricData:tri.epEB'), value: 'epEB', suffix: ' kWh' },
            { label: t('convElectricData:tri.epEC'), value: 'epEC', suffix: ' kWh' },
            { label: t('convElectricData:tri.csq'), value: 'csq', suffix: ' ' },
          ];
    }
  }
};

export const energyDateFormatOptions = () => [
  { label: t('electricSgmPlots:groupDataOption.hour'), value: 'YYYY-MM-DD HH' },
  { label: t('electricSgmPlots:groupDataOption.day'), value: 'YYYY-MM-DD' },
  { label: t('electricSgmPlots:groupDataOption.month'), value: 'YYYY-MM' },
];

export const variableSuffixOptions = ({ plotVariable }) => {
  if (plotVariable === PLOT_PV_ENERGY || plotVariable === PLOT_ENERGY) {
    return {
      insEpIT: 'kWh',
      insEpIA: 'kWh',
      insEpIB: 'kWh',
      insEpIC: 'kWh',
      insEpET: 'kWh',
      insEpEA: 'kWh',
      insEpEB: 'kWh',
      insEpEC: 'kWh',
      insEqIT: 'kVArh',
      insEqIA: 'kVArh',
      insEqIB: 'kVArh',
      insEqIC: 'kVArh',
      insEqET: 'kVArh',
      insEqEA: 'kVArh',
      insEqEB: 'kVArh',
      insEqEC: 'kVArh',
      pwrFT: '',
      pwrFA: '',
      pwrFB: '',
      pwrFC: '',
    };
  } else {
    return {
      csq: '',
      insPT: 'kW',
      insPA: 'kW',
      insPB: 'kW',
      insPC: 'kW',
      insQT: 'kVAr',
      insQA: 'kVAr',
      insQB: 'kVAr',
      insQC: 'kVAr',
      insST: 'kVA',
      insSA: 'kVA',
      insSB: 'kVA',
      insSC: 'kVA',
      volLA: 'V',
      volLB: 'V',
      volLC: 'V',
      curA: 'A',
      curB: 'A',
      curC: 'A',
      curT: 'A',
      volPA: 'V',
      volPB: 'V',
      volPC: 'V',
      pwrFT: '',
      pwrFA: '',
      pwrFB: '',
      pwrFC: '',
      volPATHD: '%',
      volPBTHD: '%',
      volPCTHD: '%',
      curATHD: '%',
      curBTHD: '%',
      curCTHD: '%',
      freq: 'Hz',
      temp: 'ºC',
      epIT: 'Wh',
      epIA: 'Wh',
      epIB: 'Wh',
      epIC: 'Wh',
      epET: 'Wh',
      epEA: 'Wh',
      epEB: 'Wh',
      epEC: 'Wh',
      eqIT: 'kVArh',
      eqIA: 'kVArh',
      eqIB: 'kVArh',
      eqIC: 'kVArh',
      eqET: 'kVArh',
      eqEA: 'kVArh',
      eqEB: 'kVArh',
      eqEC: 'kVArh',
    };
  }
};

export const variableSuffix = {
  csq: '',
  insEpIT: 'kWh',
  insEpIA: 'kWh',
  insEpIB: 'kWh',
  insEpIC: 'kWh',
  insEpET: 'kWh',
  insEpEA: 'kWh',
  insEpEB: 'kWh',
  insEpEC: 'kWh',
  insEqIT: 'kVArh',
  insEqIA: 'kVArh',
  insEqIB: 'kVArh',
  insEqIC: 'kVArh',
  insEqET: 'kVArh',
  insEqEA: 'kVArh',
  insEqEB: 'kVArh',
  insEqEC: 'kVArh',
  pwrFT: '',
  pwrFA: '',
  pwrFB: '',
  pwrFC: '',
  insPT: 'kW',
  insPA: 'kW',
  insPB: 'kW',
  insPC: 'kW',
  insQT: 'kVAr',
  insQA: 'kVAr',
  insQB: 'kVAr',
  insQC: 'kVAr',
  insST: 'kVA',
  insSA: 'kVA',
  insSB: 'kVA',
  insSC: 'kVA',
  volLA: 'V',
  volLB: 'V',
  volLC: 'V',
  curA: 'A',
  curB: 'A',
  curC: 'A',
  curT: 'A',
  volPA: 'V',
  volPB: 'V',
  volPC: 'V',
  volPATHD: '%',
  volPBTHD: '%',
  volPCTHD: '%',
  curATHD: '%',
  curBTHD: '%',
  curCTHD: '%',
  freq: 'Hz',
  temp: 'ºC',
  epIT: 'Wh',
  epIA: 'Wh',
  epIB: 'Wh',
  epIC: 'Wh',
  epET: 'Wh',
  epEA: 'Wh',
  epEB: 'Wh',
  epEC: 'Wh',
  eqIT: 'kVArh',
  eqIA: 'kVArh',
  eqIB: 'kVArh',
  eqIC: 'kVArh',
  eqET: 'kVArh',
  eqEA: 'kVArh',
  eqEB: 'kVArh',
  eqEC: 'kVArh',
};

export const energyVariableSuffix = {
  insEpIT: 'kWh',
  insEpIA: 'kWh',
  insEpIB: 'kWh',
  insEpIC: 'kWh',
  insEpET: 'kWh',
  insEpEA: 'kWh',
  insEpEB: 'kWh',
  insEpEC: 'kWh',
  insEqIT: 'kVArh',
  insEqIA: 'kVArh',
  insEqIB: 'kVArh',
  insEqIC: 'kVArh',
  insEqET: 'kVArh',
  insEqEA: 'kVArh',
  insEqEB: 'kVArh',
  insEqEC: 'kVArh',
  pwrFT: '',
  pwrFA: '',
  pwrFB: '',
  pwrFC: '',
};

export const electricalVariableSuffix = {
  csq: '',
  insPT: 'kW',
  insPA: 'kW',
  insPB: 'kW',
  insPC: 'kW',
  insQT: 'kVAr',
  insQA: 'kVAr',
  insQB: 'kVAr',
  insQC: 'kVAr',
  insST: 'kVA',
  insSA: 'kVA',
  insSB: 'kVA',
  insSC: 'kVA',
  volLA: 'V',
  volLB: 'V',
  volLC: 'V',
  curA: 'A',
  curB: 'A',
  curC: 'A',
  curT: 'A',
  volPA: 'V',
  volPB: 'V',
  volPC: 'V',
  pwrFT: '',
  pwrFA: '',
  pwrFB: '',
  pwrFC: '',
  volPATHD: '%',
  volPBTHD: '%',
  volPCTHD: '%',
  curATHD: '%',
  curBTHD: '%',
  curCTHD: '%',
  freq: 'Hz',
  temp: 'ºC',
  epIT: 'Wh',
  epIA: 'Wh',
  epIB: 'Wh',
  epIC: 'Wh',
  epET: 'Wh',
  epEA: 'Wh',
  epEB: 'Wh',
  epEC: 'Wh',
  eqIT: 'kVArh',
  eqIA: 'kVArh',
  eqIB: 'kVArh',
  eqIC: 'kVArh',
  eqET: 'kVArh',
  eqEA: 'kVArh',
  eqEB: 'kVArh',
  eqEC: 'kVArh',
};
