import { dateTimeFormat, timeStampFormat } from 'src/shared/utils/date-utils';
import { useAppSelector } from '../hooks';
import _ from 'lodash';
import { hexToHSL } from 'src/shared/utils/color-converter';
import { useEffect, useState } from 'react';
import { decimalFormat } from 'src/shared/utils/number-utils';

export const useConvElectricPlotGenerator = ({ datas = [], style = 'bar', variableType, variableName, highlightPeriod = true, suffix, dateFormat, devName }) => {
  const appSetting = useAppSelector(state => state.appSettings.entity);
  const [lineData, setLineData] = useState({ devName, suffix, dateFormat, datas: [] });
  const [dounutData, setDounutData] = useState({
    labels: [],
    values: [],
    centerText: '',
    hole: 0.7,
    type: 'pie',
    automargin: true,
    showlegend: true,
    hoverinfo: 'none',
    legend: { orientation: 'h' },
    sort: false,
    marker: {
      colors: [],
    },
  });
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    const lineDataT = [];
    const dounutDataLabels = [];
    const dounutDataValues = [];
    const dounutDataColor = [];
    const dataTableT = [];
    for (let i = 0; i < (style === 'bar' && highlightPeriod ? 6 : 1); i++) {
      const convElectricDatasByPeriod = datas?.filter(res => (style === 'bar' && highlightPeriod ? res.energyPeriod === i + 1 : true));
      if (convElectricDatasByPeriod && convElectricDatasByPeriod.length > 0) {
        const xyStacked = [];
        let yTotal = 0;
        convElectricDatasByPeriod.forEach((data, index) => {
          xyStacked.push({
            x: timeStampFormat(data['timeStamp'], 'Europe/Madrid', dateFormat),
            y: data[variableType],
            suffix,
          });
          yTotal += data[variableType];
        });
        const convElectricDatasT = _(xyStacked)
          .groupBy('x')
          .map((objs, key) => {
            return {
              x: key,
              y: _.sumBy(objs, 'y'),
              suffix: _.findLast(objs)['suffix'],
            };
          })
          .value();
        lineDataT.push({
          barmode: 'stack',
          type: style === 'bar' ? 'bar' : 'line',
          x: convElectricDatasT.map(dv => dv.x),
          y: convElectricDatasT.map(dv => dv.y),
          stackgroup: '1',
          name: variableName + (style === 'bar' && highlightPeriod ? ' P' + (i + 1) : ''),
          showlegend: true,
          marker: {
            color: hexToHSL(appSetting['appPlotColor' + (i + 1)]),
          },
        });
        dounutDataLabels.push(variableName + (style === 'bar' && highlightPeriod ? ' P' + (i + 1) : ''));
        dounutDataValues.push(yTotal);
        dounutDataColor.push(hexToHSL(appSetting['appPlotColor' + (i + 1)]));
        const dounutDataT = {
          labels: dounutDataLabels,
          values: dounutDataValues,
          centerText:
            'Total <br>' +
            decimalFormat(
              dounutDataValues.reduce((a, b) => a + b, 0),
              2
            ) +
            ' ' +
            suffix,
          hole: 0.7,
          type: 'pie',
          automargin: true,
          showlegend: true,
          hoverinfo: 'none',
          legend: { orientation: 'h' },
          sort: false,
          marker: {
            colors: dounutDataColor,
          },
        };
        setLineData({ devName, dateFormat, datas: lineDataT, suffix });
        setDounutData(dounutDataT);
        dataTableT.push(...convElectricDatasT);
      }
    }
    setDataTable(dataTableT);
  }, [datas, variableType, style, dateFormat, highlightPeriod]);
  return { lineData, dounutData, dataTable };
};
