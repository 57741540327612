import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { bodyLeftUpperCaseTemplate, bodyPlantTypePlate } from 'src/shared/components/general/datatable-field-templates';
import { Dropdown } from 'primereact/dropdown';
import { useLocalStorage } from 'primereact/hooks';

export const PlantSelectorDataTable = (props: { plants; selectedDevices; selectionMode?; onSelectionChange?; onHide?; rows?; showDevId? }) => {
  const { t } = useTranslation();
  const dt = useRef(null);
  const [devices, setDevices] = useState();
  const [plantType, setPlantType] = useLocalStorage(null, 'plantTypeFilter');

  useEffect(() => {
    if (props.plants) {
      setDevices(props.plants);
    }
  }, [props.plants]);

  const typeOptions = [
    { label: 'ELÉCTRICA', value: 1 },
    { label: 'FOTOVOLTÁICA', value: 2 },
  ];

  const typeFilterTemplate = options => {
    return (
      <Dropdown
        filter
        value={plantType}
        options={typeOptions}
        onChange={e => {
          setPlantType(e.value);
          dt.current.filter(e.value, 'type', 'equals');
        }}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <>
      <DataTable
        ref={dt}
        stateStorage="session"
        stateKey="dt-plant-selector"
        footer={props.selectionMode === 'multiple' && <Button label="Cerrar" onClick={props.onHide} />}
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={props.rows}
        value={[...(devices || [])]}
        sortField={'name'}
        sortOrder={1}
        selection={props.selectedDevices}
        onSelectionChange={props.onSelectionChange}
        selectionMode={props.selectionMode ? props.selectionMode : 'single'}
        dataKey="name"
        emptyMessage="No customers found."
      >
        <Column selectionMode={props.selectionMode} headerStyle={{ width: '3em' }}></Column>
        <Column
          field="name"
          filter
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showApplyButton={false}
          showClearButton={false}
          header={t('electricSgmDevice:name')}
          body={bodyLeftUpperCaseTemplate}
        ></Column>
        <Column
          field="type"
          filter
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showApplyButton={false}
          showClearButton={false}
          filterElement={typeFilterTemplate}
          header={t('electricSgmDevice:type')}
          body={bodyPlantTypePlate}
        ></Column>
      </DataTable>
    </>
  );
};
