import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/hooks';
import { InputNumberV2, InputTextV2, StringDropdownV2 } from 'src/shared/components/general/primereact-custom';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { electricDataMonoOptions, electricDataTriOptions } from 'src/shared/utils/conv-electric-data-utils';
import { ToggleButton } from 'primereact/togglebutton';
import useSWR, { mutate } from 'swr';
import { swrFetcher } from 'src/shared/utils/swrUtil';

export const ElectricSgmAlarmComponent = ({ plantId, electricSgmDevice, updateAlarmConfig, url }) => {
  const { t } = useTranslation();
  const { data: convElectricAlarmConfig } = useSWR(url, swrFetcher, { fallbackData: {} });
  const [visible, setVisible] = useState(false);
  const [suffix, setSuffix] = useState('');

  const [convElectricAlarmConfigToSave, setConvElectricAlarmConfigToSave] = useState({
    enable: 0,
    variableName: '',
    variable: '',
    comparator: '',
    value: 0,
    message: '',
  });

  const [objectOptions, setOptions] = useState<any>({
    comparator: [
      { label: t('alarmConfig:greaterThan'), value: 'GREATER_THAN' },
      { label: t('alarmConfig:lessThan'), value: 'LESS_THAN' },
    ],
    variable:
      electricSgmDevice.voltageLines === 1
        ? electricDataMonoOptions().map(res => ({
            label: res.label,
            value: res.value,
            suffix: res.suffix,
          }))
        : electricDataTriOptions(electricSgmDevice.model).map(res => ({
            label: res.label,
            value: res.value,
            suffix: res.suffix,
          })),
  });

  const footerCreateDialog = (
    <>
      <Button
        label={t('entity:action.modify')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          convElectricAlarmConfigToSave['macId'] = electricSgmDevice.macId;
          convElectricAlarmConfigToSave['plantId'] = plantId;
          convElectricAlarmConfigToSave['key'] = convElectricAlarmConfigToSave.variable;
          convElectricAlarmConfigToSave['enable'] = convElectricAlarmConfigToSave.enable ? 1 : 0;
          updateAlarmConfig(convElectricAlarmConfigToSave);
          setVisible(false);
          setTimeout(() => {
            mutate(url);
          }, 2000);
        }}
        disabled={!convElectricAlarmConfigToSave.variable || !convElectricAlarmConfigToSave.comparator || !convElectricAlarmConfigToSave.message}
      />
      <Button label={t('entity:action.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => setVisible(false)} />
    </>
  );

  const AlarmComponent = ({ enabled, name, comparatorString, value, valueSuffix, message }) => {
    const { t } = useTranslation();

    const comparator = [
      { label: t('alarmConfig:greaterThan'), value: 'GREATER_THAN' },
      { label: t('alarmConfig:lessThan'), value: 'LESS_THAN' },
    ];

    return (
      <div className="col-12" style={{ display: 'inline-block ', marginTop: 5 }}>
        <div style={{ display: 'inline-block', marginRight: 5 }}>
          <div style={{ width: 120 }}>Estado</div>
          <div className="p-inputtext" style={{ width: 120, background: (enabled & 0x1) === 1 && 'green', color: (enabled & 0x1) === 1 && 'white' }}>
            {(enabled & 0x1) === 1 ? 'Habilitado' : 'Deshabilitado'}
          </div>
        </div>
        <div style={{ display: 'inline-block ', marginRight: 5 }}>
          <div style={{ width: 200 }}>Variable</div>
          <div className="p-inputtext" style={{ width: 200 }}>
            {t('convElectricData:' + name)}
          </div>
        </div>
        <div style={{ display: 'inline-block', marginRight: 5 }}>
          <div style={{ width: 100 }}>Comparador</div>
          <div className="p-inputtext" style={{ width: 100 }}>
            {(enabled & 0x2) === 0x2 ? comparator[0].label : comparator[1].label}
          </div>
        </div>
        <div style={{ display: 'inline-block', marginRight: 5 }}>
          <div style={{ width: 150 }}>Valor</div>
          <div className="p-inputtext" style={{ width: 150 }}>
            {Math.abs(value) + valueSuffix}
          </div>
        </div>
        <div style={{ display: 'inline-block', marginRight: 5, marginTop: 5 }}>
          <div style={{ width: 300 }}>Mensaje</div>
          <div className="p-inputtext" style={{ width: 300 }}>
            {message ? message : '-'}
          </div>
        </div>

        <Button
          style={{ display: 'inline-block' }}
          label="Modificar"
          onClick={() => {
            setConvElectricAlarmConfigToSave({
              ...convElectricAlarmConfigToSave,
              enable: convElectricAlarmConfig[name.split('.')[1] + 'Ena'],
              variable: name.split('.')[1],
              variableName: t('convElectricData:' + name),
              value: Math.abs(convElectricAlarmConfig[name.split('.')[1]]),
              message: convElectricAlarmConfig[name.split('.')[1] + 'Message'],
              comparator: convElectricAlarmConfig[name.split('.')[1]] > 0 ? comparator[0].value : comparator[1].value,
            });
            setVisible(true);
          }}
          disabled={name === 'tri.csq' || name === 'mono.csq'}
        />
      </div>
    );
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Dialog
          visible={visible}
          style={{ width: '800px' }}
          header={t('entity:action.modify') + '(' + convElectricAlarmConfigToSave.variableName + ')'}
          modal
          className="p-fluid"
          onHide={() => setVisible(false)}
          footer={footerCreateDialog}
        >
          <div className="p-field grid">
            <div className="col-12">
              <ToggleButton
                onLabel="Habilitado"
                offLabel="Deshalilitado"
                checked={(convElectricAlarmConfigToSave.enable & 0x01) === 1}
                onChange={e => setConvElectricAlarmConfigToSave({ ...convElectricAlarmConfigToSave, enable: e.value ? 1 : 0 })}
              />
            </div>
            <div className="col-12">
              <StringDropdownV2
                label={t('alarmConfig:comparator')}
                entity={convElectricAlarmConfigToSave}
                field={'comparator'}
                setEntity={setConvElectricAlarmConfigToSave}
                options={objectOptions.comparator}
              />
            </div>
            <div className="col-12">
              <InputNumberV2 label={t('alarmConfig:value')} entity={convElectricAlarmConfigToSave} field={'value'} setEntity={setConvElectricAlarmConfigToSave} minFractionDigits={2} suffix={suffix} />
            </div>
            <div className="col-12">
              <InputTextV2 label={t('alarmConfig:message')} entity={convElectricAlarmConfigToSave} field={'message'} setEntity={setConvElectricAlarmConfigToSave} />
            </div>
          </div>
        </Dialog>
        <Fieldset legend="Basica">
          {electricSgmDevice.voltageLines === 1 ? (
            <div className="p-field grid">
              <AlarmComponent enabled={1} name="mono.csq" comparatorString="Igual" valueSuffix="" value={0} message={'Señal Perdida'} />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volPAEna}
                name="mono.volPA"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volPA}
                message={convElectricAlarmConfig.volPAMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.insPTEna}
                name="mono.insPT"
                comparatorString={null}
                valueSuffix="kW"
                value={convElectricAlarmConfig.insPA}
                message={convElectricAlarmConfig.insPAMessage}
              />
            </div>
          ) : (
            <div className="p-field grid">
              <AlarmComponent enabled={1} name="tri.csq" comparatorString="Igual" valueSuffix="" value={0} message="Señal Perdida" />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volPAEna}
                name="tri.volPA"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volPA}
                message={convElectricAlarmConfig.volPAMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volPBEna}
                name="tri.volPB"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volPB}
                message={convElectricAlarmConfig.volPBMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volPCEna}
                name="tri.volPC"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volPC}
                message={convElectricAlarmConfig.volPCMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volLAEna}
                name="tri.volLA"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volLA}
                message={convElectricAlarmConfig.volLAMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volLBEna}
                name="tri.volLB"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volLB}
                message={convElectricAlarmConfig.volLBMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.volLCEna}
                name="tri.volLC"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.volLC}
                message={convElectricAlarmConfig.volLCMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.curAEna}
                name="tri.curA"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.curA}
                message={convElectricAlarmConfig.curAMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.curBEna}
                name="tri.curB"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.curB}
                message={convElectricAlarmConfig.curBMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.curCEna}
                name="tri.curC"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.curC}
                message={convElectricAlarmConfig.curCMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.insPAEna}
                name="tri.insPA"
                comparatorString={null}
                valueSuffix="kW"
                value={convElectricAlarmConfig.insPA}
                message={convElectricAlarmConfig.insPAMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.insPBEna}
                name="tri.insPB"
                comparatorString={null}
                valueSuffix="kW"
                value={convElectricAlarmConfig.insPB}
                message={convElectricAlarmConfig.insPBMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.insPCEna}
                name="tri.insPC"
                comparatorString={null}
                valueSuffix="kW"
                value={convElectricAlarmConfig.insPC}
                message={convElectricAlarmConfig.insPCMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.pwrFAEna}
                name="tri.pwrFA"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.pwrFA}
                message={convElectricAlarmConfig.pwrFAMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.pwrFBEna}
                name="tri.pwrFB"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.pwrFB}
                message={convElectricAlarmConfig.pwrFBMessage}
              />
              <AlarmComponent
                enabled={convElectricAlarmConfig.pwrFCEna}
                name="tri.pwrFC"
                comparatorString={null}
                valueSuffix="V"
                value={convElectricAlarmConfig.pwrFC}
                message={convElectricAlarmConfig.pwrFCMessage}
              />
            </div>
          )}
        </Fieldset>
      </div>
    </>
  );
};
