import { createSlice } from '@reduxjs/toolkit';
export const AUTH_TOKEN_KEY = 'smartmanager-authenticationToken';
export const OIDC_URL_KEY = 'smartmanager-oidcUrl';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: { account: {} as any, isAuthenticated: false },
  reducers: {
    setAccount(state, action) {
      state.account = action.payload;
      state.isAuthenticated = true;
    },
    clearAuth(state) {
      state.account = {};
      state.isAuthenticated = false;
    },
  },
});

export const setOidcUrl = (url: any) => {
  localStorage.setItem(OIDC_URL_KEY, url);
  sessionStorage.setItem(OIDC_URL_KEY, url);
};

export const clearOidUrl = () => {
  if (localStorage.getItem(OIDC_URL_KEY)) {
    localStorage.remove(OIDC_URL_KEY);
  }
  if (sessionStorage.getItem(OIDC_URL_KEY)) {
    sessionStorage.remove(OIDC_URL_KEY);
  }
};

export const setSession = (jwt: any) => {
  localStorage.setItem(AUTH_TOKEN_KEY, jwt);
  sessionStorage.setItem(AUTH_TOKEN_KEY, jwt);
};

export const clearAuthToken = () => {
  if (localStorage.getItem(AUTH_TOKEN_KEY)) {
    localStorage.remove(AUTH_TOKEN_KEY);
  }
  if (sessionStorage.getItem(AUTH_TOKEN_KEY)) {
    sessionStorage.remove(AUTH_TOKEN_KEY);
  }
};

export const clearAuthentication = () => dispatch => {
  clearOidUrl();
  clearAuthToken();
  dispatch(clearAuth());
};

export const { setAccount, clearAuth } = authenticationSlice.actions;
export default authenticationSlice.reducer;
