import { useContext, useEffect, useRef, useState } from 'react';
import './App.css';
import './app.scss';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'src/routes';
import classNames from 'classnames';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import './content/theme/theme.css'; //icons
import { AppTopbar } from './app-topbar';
import { AppProfile } from './app-profile';
import { ScrollPanel } from 'primereact/scrollpanel';
import { AppMenu } from './app-menu';
import { AppContext } from './app/contexts/AppContext';
import { Toast } from 'primereact/toast';
import { useAppSelector } from './app/hooks';

export const App = () => {
  const appData = useContext(AppContext);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const layoutMenuScroller = useRef(null);
  const layoutColorMode = 'dark';
  const message = useAppSelector(state => state.toastMessage.message);
  const toast = useRef(null);
  useEffect(() => {
    if (toast !== null) {
      toast.current.show(message);
    }
  }, [message]);

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': true,
    'layout-static': false,
    'layout-static-sidebar-inactive': false,
    'layout-overlay-sidebar-active': overlayMenuActive && true,
    'layout-mobile-sidebar-active': mobileMenuActive,
  });

  const sidebarClassName = classNames('layout-sidebar', {
    'layout-sidebar-dark': layoutColorMode === 'dark',
  });

  const isDesktop = (): boolean => {
    return window.innerWidth > 1024;
  };
  const onToggleMenu = () => {
    if (isDesktop()) {
      setOverlayMenuActive(!overlayMenuActive);
    } else {
      setMobileMenuActive(!mobileMenuActive);
    }
  };

  const closeMenu = () => {
    if (isDesktop()) {
      setOverlayMenuActive(false);
    } else {
      setMobileMenuActive(false);
    }
  };

  const closeMenuItemClick = () => {
    setOverlayMenuActive(false);
  };
  const onMenuItemClick = (event: { originalEvent: Event; item: any }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
    }
  };

  console.log('APP.tsx');
  return (
    <div>
      <Toast ref={toast} style={{ zIndex: 4000 }}   />
      {appData.initialized ? (
        <BrowserRouter>
          <div className={wrapperClass}>
            <AppTopbar getManuals={null} onToggleMenu={onToggleMenu} manuals={null} updateManual={null} />
            <div
              className={sidebarClassName}
              style={{
                background: 'var(--menu-bg-color)',
                marginTop: 0,
              }}
            >
              <img src={'api/app-settings/top-menu-logo'} width={250} style={{ height: '3rem' }} />
              <div>
                <AppProfile onClick={closeMenuItemClick} signOut={appData.keycloak.logout} />
                <ScrollPanel ref={layoutMenuScroller} style={{ height: '100vh' }}>
                  <div className="layout-sidebar-scroll-content">
                    <AppMenu onMenuItemClick={onMenuItemClick} />
                  </div>
                </ScrollPanel>
              </div>
            </div>

            <div className="layout-main" onClick={closeMenu} >
              <div className=" flex justify-content-center" style={{ marginTop: '2rem' }}>
                <div className="card" style={{ maxWidth: 1400, background: 'white', height: '100%' }}>
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default App;
