import { useTranslation } from 'react-i18next';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { PlantSelector } from '../plant-selector/plant-selector';
import { ElectricSgmAlarmConfig } from './electricsgm-alarm-config';

const AlarmConfig = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:alarmManagement'));

  return (
    <div className="grid">
      <div className="col-12">
        <PlantSelector selectionMode={'single'} />
        <ElectricSgmAlarmConfig />
      </div>
    </div>
  );
};

export default AlarmConfig;
