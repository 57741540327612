import dayjs from 'dayjs';
import { decimalFormat } from 'src/shared/utils/number-utils';
import { dateTimeFormat, timeStampFormat } from 'src/shared/utils/date-utils';

export const headerCenterTemplate = (text: any) => {
  return <div style={{ textAlign: 'center' }}>{text}</div>;
};

export const bodyRightTemplate = (entity: any, column: any) => {
  return <div style={{ textAlign: 'right' }}>{entity[column.field]}</div>;
};
export const bodyLeftTemplate = (entity: any, column: any) => {
  return <div style={{ textAlign: 'left' }}>{entity[column.field]}</div>;
};

export const bodyCenterTemplate = (entity: any, column: any) => {
  return <div style={{ textAlign: 'center' }}>{entity[column.field]}</div>;
};

export const bodyLeftUpperCaseTemplate = (entity: any, column: any) => {
  return <div style={{ textAlign: 'left' }}>{entity[column.field]?.toUpperCase()}</div>;
};

export const bodyDateTemplate = (entity: any, column: any) => {
  const field = entity[column.field] ? timeStampFormat(entity[column.field]) : dateTimeFormat(dayjs());
  return <div style={{ textAlign: 'center' }}>{field}</div>;
};

export const bodyEuroMoneyTemplate = (entity: any, column: any) => {
  const field = entity[column.field] ? decimalFormat(entity[column.field], 2) + ' €' : '0.00 €';
  return <div style={{ textAlign: 'right' }}>{field}</div>;
};

export const bodyEnergyDecimalTemplate = (entity: any, column: any) => {
  const energy = entity[column.field] ? entity[column.field] : 0;
  const field = energy < 10 ? decimalFormat(entity[column.field], 2) : decimalFormat(entity[column.field], 0);
  return <div style={{ textAlign: 'center' }}>{field + ' kWh'}</div>;
};

export const bodyWaterDecimalTemplate = (entity: any, column: any) => {
  const field = entity[column.field] ? decimalFormat(entity[column.field], 0) + ' m3' : '0 m3';
  return <div style={{ textAlign: 'center' }}>{field}</div>;
};

export const bodyPlantTypePlate = (entity: any, column: any) => {
  return <div style={{ textAlign: 'center' }}>{entity[column.field] === 1 ? 'ELÉCTRICA' : 'FOTOVOLTÁICA'}</div>;
};
