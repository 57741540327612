import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from './utils/reducer.utils';
import { IAppSetting, defaultValue } from 'src/shared/model/app-setting.model';

const initialState: EntityState<IAppSetting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/app-settings';

// Actions
export const getEntity = createAsyncThunk(
  'appSettings/fetch_entity',
  async () => {
    return axios.get<IAppSetting>(apiUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'appSettings/update_entity',
  async (entity: IAppSetting, thunkAPI) => {
    const result = await axios.put<IAppSetting>(`${apiUrl}`, entity);
    thunkAPI.dispatch(getEntity());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ASlice = createEntitySlice({
  name: 'appSettings',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ASlice.actions;

// Reducer
export default ASlice.reducer;
