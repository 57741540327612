import i18next from 'i18next';
import { decimalFormat } from 'src/shared/utils/number-utils';
const t = i18next.t;

export const exportFormatter = (field: any, entity: any, objOptions: any) => {
  if (field === 'zone') {
    return entity.zone === 0 ? 'Todas' : entity.zone === 1 ? 'Peninsula' : entity.zone === 2 ? 'baleares' : entity.zone === 3 ? 'canarias' : '-';
  } else if (field === 'day') {
    return t('convElectricTariff:' + entity.day);
  } else if (field === 'months') {
    return entity.months
      .split(',')
      .map(month => t('convElectricTariff:' + month))
      .join(',');
  } else if (field === 'schedules') {
    return entity.schedules
      .split(',')
      .map(schedule => schedule + ' ')
      .join(',');
  } else if (field.includes('energyTerm')) {
    const value = entity && entity[field] && entity[field];
    return decimalFormat(value ? value : 0, 6) + ' €/kWh';
  } else if (field.includes('powerTerm')) {
    const value = entity && entity[field] && entity[field];
    return decimalFormat(value ? value : 0, 6) + ' €/(kW*año)';
  } else {
    return entity && entity[field] && entity[field];
  }
};

export const fieldTemplate = (entity: any, column: any, objOptions) => {
  const field = column['field'];
  const value = exportFormatter(field, entity, objOptions);
  if (column['field'] === 'months') {
    return (
      <div>
        {entity.months.split(',').map((month, index) => (
          <div key={month + index} style={{ textAlign: 'center' }}>
            {t('convElectricTariff:' + month)}
          </div>
        ))}
      </div>
    );
  } else if (column['field'] === 'schedules') {
    return (
      <div>
        {entity.schedules.split(',').map((schedule, index) => (
          <div key={schedule + index} style={{ textAlign: 'center' }}>
            {schedule + ' '}
          </div>
        ))}
      </div>
    );
  } else {
    return <div style={{ textAlign: 'center' }}>{value}</div>;
  }
};
