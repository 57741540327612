import { createSlice } from '@reduxjs/toolkit';

export const navigationMenuSlice = createSlice({
  name: 'navigationMenuTitle',
  initialState: { navigationMenuTitle: '' },
  reducers: {
    setNavigationMenuTitle(state, action) {
      state.navigationMenuTitle = action.payload;
    },
  },
});

export const { setNavigationMenuTitle } = navigationMenuSlice.actions;
export default navigationMenuSlice.reducer;
