import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

//0=convElectricDataEnergy
//1=convElectricDataElectrical
//2=convElectricPvInstallation
//3=convElectricPvDataEnergy
//4=convElectricPvDataElectrical

export const sSlice = createSlice({
  name: 'sSlice',
  initialState: {
    rangeDate0: [dayjs().toISOString(), dayjs().toISOString()],
    rangeDate1: [dayjs().toISOString(), dayjs().toISOString()],
    rangeDate2: [dayjs().toISOString(), dayjs().toISOString()],
    rangeDate3: [dayjs().toISOString(), dayjs().toISOString()],
    rangeDate4: [dayjs().toISOString(), dayjs().toISOString()],
    variableType0: 'insEpIT',
    variableType1: 'insPT',
    variableType2: '',
    variableType3: 'insEpIT',
    variableType4: 'insPT',
    dateFormat0: 'YYYY-MM-DD HH',
    dateFormat1: 'YYYY-MM-DD HH:mm',
    dateFormat2: 'YYYY-MM-DD HH',
    dateFormat3: 'YYYY-MM-DD HH',
    dateFormat4: 'YYYY-MM-DD HH:mm',
    style0: 'bar',
    style1: 'bar',
    style2: 'bar',
    style3: 'bar',
    style4: 'bar',
    highlightPeriod0: true,
    highlightPeriod1: true,
    highlightPeriod2: true,
    highlightPeriod3: true,
    highlightPeriod4: true,
    plots: [],
  },
  reducers: {
    setRangeDate0(state, action) {
      state.rangeDate0 = action.payload;
    },
    setRangeDate1(state, action) {
      state.rangeDate1 = action.payload;
    },
    setRangeDate2(state, action) {
      state.rangeDate2 = action.payload;
    },
    setRangeDate3(state, action) {
      state.rangeDate3 = action.payload;
    },
    setRangeDate4(state, action) {
      state.rangeDate4 = action.payload;
    },
    setVariableType0(state, action) {
      state.variableType0 = action.payload;
    },
    setVariableType1(state, action) {
      state.variableType1 = action.payload;
    },
    setVariableType2(state, action) {
      state.variableType2 = action.payload;
    },
    setVariableType3(state, action) {
      state.variableType3 = action.payload;
    },
    setVariableType4(state, action) {
      state.variableType4 = action.payload;
    },
    setDateFormat0(state, action) {
      state.dateFormat0 = action.payload;
    },
    setDateFormat1(state, action) {
      state.dateFormat1 = action.payload;
    },
    setDateFormat2(state, action) {
      state.dateFormat2 = action.payload;
    },
    setDateFormat3(state, action) {
      state.dateFormat3 = action.payload;
    },
    setDateFormat4(state, action) {
      state.dateFormat4 = action.payload;
    },
    setStyle0(state, action) {
      state.style0 = action.payload;
    },
    setStyle1(state, action) {
      state.style1 = action.payload;
    },
    setStyle2(state, action) {
      state.style2 = action.payload;
    },
    setStyle3(state, action) {
      state.style3 = action.payload;
    },
    setStyle4(state, action) {
      state.style4 = action.payload;
    },
    setHighlightPeriod0(state, action) {
      state.highlightPeriod0 = action.payload;
    },
    setHighlightPeriod1(state, action) {
      state.highlightPeriod1 = action.payload;
    },
    setHighlightPeriod2(state, action) {
      state.highlightPeriod2 = action.payload;
    },
    setHighlightPeriod3(state, action) {
      state.highlightPeriod3 = action.payload;
    },
    setHighlightPeriod4(state, action) {
      state.highlightPeriod4 = action.payload;
    },
    addPlots(state, action) {
      state.plots = [...state.plots, action.payload];
    },
    setPlots(state, action) {
      state.plots = action.payload;
    },
  },
});
export const {
  setRangeDate0,
  setRangeDate1,
  setRangeDate2,
  setRangeDate3,
  setRangeDate4,
  setVariableType0,
  setVariableType1,
  setVariableType2,
  setVariableType3,
  setVariableType4,
  setDateFormat0,
  setDateFormat1,
  setDateFormat2,
  setDateFormat3,
  setDateFormat4,
  setStyle0,
  setStyle1,
  setStyle2,
  setStyle3,
  setStyle4,
  setHighlightPeriod0,
  setHighlightPeriod1,
  setHighlightPeriod2,
  setHighlightPeriod3,
  setHighlightPeriod4,
  addPlots,
  setPlots,
} = sSlice.actions;

export default sSlice.reducer;
