import axios from "axios";
import { setShow } from "src/app/reducers/loading-bar.reducer";
import getStore from "src/app/store";
import { AUTH_TOKEN_KEY } from "../reducers/authentication";
const store = getStore();
const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = "";

const setupAxiosInterceptors = (onUnauthenticated: any) => {
  const onRequestSuccess = (config: any) => {
    const token =
      localStorage.getItem(AUTH_TOKEN_KEY) ||
      sessionStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    store.dispatch(setShow(true));
    return config;
  };
  const onResponseSuccess = (response: any) => {
    store.dispatch(setShow(false));
    return response;
  };
  const onResponseError = (err: any) => {
    store.dispatch(setShow(false));
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    if (status === 503) {
      window.location.href = window.location.origin + "/error/";
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
