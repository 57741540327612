import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/app/hooks';
import { PlantSelector } from '../plant-selector/plant-selector';
import { ElectricSgmAlarmLog } from './electricsgm/electric-sgm-alarm-log';

export const AlarmLog = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:alarmLog'));
  return (
    <div>
      <PlantSelector selectionMode={'single'} />
      <ElectricSgmAlarmLog />
    </div>
  );
};

export default AlarmLog;
