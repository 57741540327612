import { useState } from 'react';
import classNames from 'classnames';
import { useAppSelector } from './app/hooks';
import { useTranslation } from 'react-i18next';
import { OIDC_URL_KEY } from './app/reducers/authentication';

export const AppProfile = (props: { onClick; signOut: any }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const onClick = () => {
    setExpanded(!expanded);
  };

  const keycloakUr = localStorage.getItem(OIDC_URL_KEY) || sessionStorage.getItem(OIDC_URL_KEY);
  const accountManagementUrl = keycloakUr + '/realms/smartmanager/account?referrer=web_app_smartmanager&referrer_uri=' + window.location.href;

  return (
    <div className="profile" style={{ fontSize: '1.1rem' }}>
      <a className="profile-link" onClick={onClick}>
        <span className="username" style={{ display: 'block' }}>
          {account.firstName ? account.firstName : ''} {account.lastName ? account.lastName : ''}
        </span>
        <div className="p-text-wrap" style={{ fontSize: '0.8rem' }}>
          {account.email}
        </div>
        <i style={{ fontSize: 30 }} className="pi pi-fw pi-cog" />
      </a>

      <ul className={classNames({ 'profile-expanded': expanded })}>
        <li>
          <a href={accountManagementUrl}>
            <i className="pi pi-fw pi-user" />
            <span>Cuenta</span>
          </a>
        </li>
        <li>
          <a onClick={() => props.signOut()}>
            <i className="pi pi-fw pi-power-off" />
            <span>{t('global:global.menu.account.logout')}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};
