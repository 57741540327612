import React from 'react';
import { RouteProps, useLocation, Navigate } from 'react-router-dom';

interface IErrorBoundaryProps {
  readonly children: React.ReactNode;
}

interface IErrorBoundaryState {
  readonly error: any;
  readonly errorInfo: any;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  readonly state: IErrorBoundaryState = { error: undefined, errorInfo: undefined };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      // const errorDetails = DEVELOPMENT ? (
      //   <details className="preserve-space">
      //     {error && error.toString()}
      //     <br />
      //     {errorInfo.componentStack}
      //   </details>
      // ) : undefined;
      return (
        <div>
          {/* <Navigate replace to="/" /> */}
          <h2 className="error">An unexpected error has occurred.</h2>
          {/* {errorDetails} */}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
