import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

export const LineGraph = (props: { data: any; suffix: any; height: any; rangeSlider: any; dateFormat: any; yAxisRange: any; ySimetric?: any; xAxisType?: any }) => {
  const currentLocale = 'es';
  const [layout, setLayout] = useState({
    barmode: 'stack',
    autosize: true,
    datarevision: 0,
    margin: {
      b: 30,
      t: 0,
    },
    xaxis: {
      rangemode: 'tozero',
      type: props.xAxisType || 'date',
      tickformat: '%d %B',
    },
    yaxis: {
      showticksuffix: 'all',
      ticksuffix: props.suffix,
      zeroline: true,
      rangemode: 'tozero',
      range: props.yAxisRange === 0 ? null : props.yAxisRange < 0 ? [props.yAxisRange, props.ySimetric ? -props.yAxisRange : 0] : [props.ySimetric ? -props.yAxisRange : 0, props.yAxisRange],
    },
    legend: {
      orientation: 'h',
      yanchor: 'bottom',
      y: 1,
    },
  });

  useEffect(() => {
    setLayout({
      ...layout,
      datarevision: layout.datarevision + 1,
      yaxis: {
        range: props.yAxisRange === 0 ? null : props.yAxisRange < 0 ? [props.yAxisRange, props.ySimetric ? -props.yAxisRange : 0] : [props.ySimetric ? -props.yAxisRange : 0, props.yAxisRange],
        showticksuffix: 'all',
        ticksuffix: props.suffix,
        zeroline: true,
        rangemode: 'tozero',
      },
    });
  }, []);

  useEffect(() => {
    setLayout({
      ...layout,
      datarevision: layout.datarevision + 1,
      xaxis: {
        rangemode: 'tozero',
        type: props.xAxisType || 'date',
        tickformat:
          props.xAxisType &&
          props.xAxisType === 'date' &&
          (props.dateFormat === 'YYYY-MM-DD HH' ? '%b %d\n%H:%M' : props.dateFormat === 'YYYY-MM-DD' ? '%b %d' : props.dateFormat === 'YYYY-MM' ? '%b' : '%b %d\n%H:%M'),
      },
      yaxis: {
        zeroline: true,
        rangemode: 'tozero',
        range: props.yAxisRange === 0 ? null : props.yAxisRange < 0 ? [props.yAxisRange, props.ySimetric ? -props.yAxisRange : 0] : [props.ySimetric ? -props.yAxisRange : 0, props.yAxisRange],
        showticksuffix: 'all',
        ticksuffix: props.suffix,
      },
    });
  }, [props.data, props.yAxisRange]);

  return (
    <div style={{ height: props.height }}>
      <Plot className="w-full h-full" data={props.data} revision={layout.datarevision} layout={layout} displaylogo={false} config={{ responsive: true, locale: currentLocale, displaylogo: false }} />
    </div>
  );
};
