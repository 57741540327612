import { TabPanel, TabView } from 'primereact/tabview';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { ElectricSgmAlarmComponent } from 'src/shared/components/alarm-config/alarm-config';
import { updateEntity } from 'src/app/reducers/entities/electric-sgm-alarm-config.reducer';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { AUTHORITIES } from 'src/app/config/constants';

export const ElectricSgmAlarmConfig = () => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const selectedPlant = useAppSelector(state => state.plantSelector.selectedPlant);
  const plantId = selectedPlant.id;
  const devices = selectedPlant.devices;
  const dispatch = useAppDispatch();

  const updateElectricSgmAlarmConfig = entity => {
    dispatch(updateEntity(entity));
  };
  

  return (
    <TabView>
      {devices &&
        devices.map(res => {
          const device = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines };
          const devName = 'D' + res.index + (isAdmin && '(' + res.macId + ')') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
          const url = 'api/electric-sgm-alarm-configs-by-macid?macId=' + device.macId + '&plantId=' + plantId;
          return (
            <TabPanel key={res.macId} header={devName}>
              <ElectricSgmAlarmComponent electricSgmDevice={device} plantId={plantId} updateAlarmConfig={updateElectricSgmAlarmConfig} url={url} />
            </TabPanel>
          );
        })}
    </TabView>
  );
};
