import _ from 'lodash';
import dayjs from 'dayjs';
import { useAppSelector } from 'src/app/hooks';
import { useFetchElectricDataPvCombined } from 'src/app/hooks/use-fetch-electric-data-pv-combined';
import { motion } from 'framer-motion';
import { Panel } from 'primereact/panel';
import { ConvElectricPvSLoad } from 'src/shared/components/conv-electric-pv/conv-electric-pv-load';
import { ConvElectricPvCombinedPlot } from 'src/shared/components/plots/pv-combined-plot';
import { ConvElectricPvWheatherComponent } from 'src/shared/components/conv-electric-pv/conv-electric-pv-wheather';

export const ElectricSgmPvDashboard = (props: { plant: any }) => {
  const appSetting = useAppSelector(state => state.appSettings.entity);
  const startDate = dayjs().startOf('day').toISOString();
  const endDate = dayjs().endOf('day').toISOString();
  const selectedPlant = useAppSelector(state => state.plantSelector.selectedPlant);

  const variants = {
    animate: {
      scale: [1.01, 1],
    },
    whileHover: {
      scale: 1.01,
      boxShadow: '0px 0px 8px ' + appSetting.appMainColor,
    },
  };

  const { dataTable, dataLine, dounutData, lastData } = useFetchElectricDataPvCombined({
    startDate,
    endDate,
  });

  return (
    <div className="grid">
      <motion.div style={{ textAlign: 'center' }} className="lg:col-8 col-12" variants={variants} animate="animate" whileHover="whileHover" transition={{ duration: 0.2, delay: 0.1 }}>
        <Panel header="Flujo de Potencia" style={{ textAlign: 'center' }}>
          <div className="p-grid p-justify-center p-align-center" style={{ height: 330 }}>
            <div className="p-col-12" style={{ maxWidth: 700 }}>
              <ConvElectricPvSLoad data={lastData} color="var(--main-color-0)" />
            </div>
          </div>
        </Panel>
      </motion.div>
      <motion.div style={{ textAlign: 'center' }} className="lg:col-4 col-12" variants={variants} animate="animate" whileHover="whileHover" transition={{ duration: 0.2, delay: 0.1 }}>
        <Panel header="Información del Tiempo" style={{ textAlign: 'left' }}>
          <ConvElectricPvWheatherComponent latitude={props.plant.latitude} longitude={props.plant.longitude} />
        </Panel>
      </motion.div>

      <motion.div style={{ textAlign: 'center' }} className="col-12" variants={variants} animate="animate" whileHover="whileHover" transition={{ duration: 0.2, delay: 0.1 }}>
        <ConvElectricPvCombinedPlot dataTable={dataTable} dataLine={dataLine} dateFormat={'YYYY-MM-DD HH'} dataType={'kw'} dounutData={dounutData} suffix={'kW'} showDounut={true} />
      </motion.div>
    </div>
  );
};
