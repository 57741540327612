import { useTranslation } from 'react-i18next';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { ElectricSgmSimpleData } from 'src/modules/instant-data/conv-electric-instant-data';
import { useAppSelector } from 'src/app/hooks';
import { PlantSelector } from '../plant-selector/plant-selector';

export const SimpleData = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:instantData'));

  return (
    <div>
      <PlantSelector selectionMode={'single'} />
      <ElectricSgmSimpleData />
      {/* {deviceType === 'convelectricpv' && <ConvelectricPvSimpleData />} */}
    </div>
  );
};
