import { useTranslation } from 'react-i18next';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useAppSelector } from 'src/app/hooks';
import { PlantSelector } from '../plant-selector/plant-selector';
import { ConvElectricTariff } from './conv-electric-tariff';

export const Tariff = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:customTariff'));
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  return (
    <div>
      <PlantSelector selectionMode={'single'} />
      <ConvElectricTariff />
    </div>
  );
};
