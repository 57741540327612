import dayjs from 'dayjs';
import i18next from 'i18next';
import { ModbusStatusToIcon, WifiSignalToIcon } from 'src/shared/utils/device-utils';
import { timeStampFormat } from 'src/shared/utils/date-utils';
const t = i18next.t;

const DeviceCsq = ({ csq, timeStamp }) => {
  return (
    <div className="flex align-items-center justify-content-end h-2rem" style={{ width: '200px' }}>
      <WifiSignalToIcon csq={csq} />
      <span style={{ marginLeft: 12, display: 'inline-block' }}>{timeStampFormat(timeStamp)}</span>
    </div>
  );
};
const DeviceFull = ({ csq, timeStamp, entity }) => {
  return (
    <div className="flex align-items-center justify-content-center h-2rem">
      <WifiSignalToIcon csq={csq} />
      {entity.data &&
        entity.data.slice(0, entity.slaves).map((res) => {
          const modbusStatus = res.modbusStatus;
          return <ModbusStatusToIcon key={res.id} modbusStatus={modbusStatus} csq={csq} />;
        })}
      <span style={{ marginLeft: 12, display: 'inline-block' }}>{timeStampFormat(timeStamp)}</span>
    </div>
  );
};

const DeviceModbusStatus = ({ csq, modbusStatus, timeStamp }) => {
  return (
    <div className="flex align-items-center justify-content-end h-2rem">
      <ModbusStatusToIcon modbusStatus={modbusStatus} csq={csq} />
    </div>
  );
};
export const exportFormatter = (field: any, entity: any, objOptions: any) => {
  if (field === 'appUser' || field === 'commercialUser') {
    return entity[field] || '--';
  } else if (field === 'tariff') {
    return entity.tariff;
  } else if (field === 'available' || field === 'multiple') {
    return entity[field] ? 'true' : 'false';
  } else if (field === 'control') {
    return entity[field] ? 'Si' : 'No';
  } else if (field === 'power') {
    return entity[field] + 'kW';
  } else if (field === 'reactive') {
    return entity[field] ? 'Si' : 'No';
  } else if (field === 'readerType') {
    const status = entity.readerType ? entity.readerType : '0';
    return t('electricSgmDevice:readerTypes.' + status);
  } else if (field === 'status') {
    const status = entity.status ? entity.status : 'instock';
    return t('electricSgmDevice:statuses.' + status);
  } else if (field === 'timeStamp') {
    return dayjs(entity[field] + 'Z').format('DD/MM/YY HH:mm');
  } else if (field === 'convElectricCustomTariff') {
    return entity.convElectricCustomTariff && entity.convElectricCustomTariff.name;
  } else if (field === 'csq') {
    return entity['csq'];
  } else if (field === 'modbusStatus') {
    return entity['modbusStatus'];
  } else if (field === 'plantId') {
    if (objOptions['plantId']) {
      const planId = objOptions['plantId'].find(res => res.value === entity['plantId']);
      return planId && planId.label?.toUpperCase();
    } else {
      return '';
    }
  } else if (field === 'plantType') {
    if (objOptions['plantId']) {
      const planId = objOptions['plantId'].find(res => res.value === entity['plantId']);
      return planId ? (planId.type === 1 ? 'ELÉCTRICA' : 'FOTOVOLTÁICA') : '';
    } else {
      return '';
    }
  } else if (field === 'voltageLines') {
    if (objOptions['voltageLines']) {
      const voltageLines = objOptions['voltageLines'].find(res => res.value === entity['voltageLines']);
      return voltageLines && voltageLines.label;
    } else {
      return '';
    }
  } else if (field === 'deviceType') {
    const deviceType = entity.deviceType ? entity.deviceType : 0;
    return deviceType === 0 ? 'Eléctrico' : deviceType === 1 ? 'Fotovoltáico' : 'ParqueSolar';
  } else if (field === 'maintainerUser') {
    return entity[field] && entity[field].map(val => <div key={val}>{val}</div>);
  } else if (field === 'notificationEmails') {
    return entity[field] && entity[field].split(',').map(val => <div key={val}>{val}</div>);
  } else {
    return entity && entity[field] && entity[field];
  }
};

export const fieldTemplate = (entity: any, column: any, objOptions: any) => {
  const field = column['field'];
  const value = exportFormatter(field, entity, objOptions);
  if (field === 'csq') {
    const timeStamp = entity['timeStamp'];
    return <DeviceCsq csq={value} timeStamp={timeStamp} />;
  } else if (column['field'] === 'csqFull') {
    const csq = entity['csq'];
    const timeStamp = entity['timeStamp'];
    return <DeviceFull csq={csq} timeStamp={timeStamp} entity={entity} />;
  } else if (column['field'] === 'modbusStatus') {
    const csq = entity['csq'];
    const timeStamp = entity['timeStamp'];
    const modbusStatus = entity['modbusStatus'];
    return <DeviceModbusStatus csq={csq} timeStamp={timeStamp} modbusStatus={modbusStatus} />;
  } else {
    return <div style={{ textAlign: 'center' }}>{value}</div>;
  }
};
