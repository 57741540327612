import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
// import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IElectricPeriod, defaultValue } from 'src/shared/model/electric-period.model';
import { createEntitySlice, EntityState, serializeAxiosError } from 'src/app/reducers/utils/reducer.utils';
import { cleanEntity } from 'src/shared/utils/entity-utils';

const initialState: EntityState<IElectricPeriod> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/electric-periods';

// Actions

export const getEntities = createAsyncThunk('convElectricPeriod/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return axios.get<IElectricPeriod[]>(requestUrl);
});

export const loadFromDB = createAsyncThunk('convElectricPeriod/load_from_db', async () => {
  const requestUrl = `${apiUrl}-load-from-db`;
  return axios.get<IElectricPeriod[]>(requestUrl);
});

export const loadCustomFromDB = createAsyncThunk('convElectricPeriod/load_custom_from_db', async () => {
  const requestUrl = `${apiUrl}-load-custom-from-db`;
  return axios.get<IElectricPeriod[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'electricPeriod/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IElectricPeriod>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'electricPeriod/create_entity',
  async (entity: IElectricPeriod, thunkAPI) => {
    const result = await axios.post<IElectricPeriod>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'electricPeriod/update_entity',
  async (entity: IElectricPeriod, thunkAPI) => {
    const result = await axios.put<IElectricPeriod>(`${apiUrl}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'electricPeriod/partial_update_entity',
  async (entity: IElectricPeriod, thunkAPI) => {
    const result = await axios.patch<IElectricPeriod>(`${apiUrl}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntities = createAsyncThunk(
  'electricPeriod/delete_entities',
  async (ids: string[], thunkAPI) => {
    let requestUrl = `${apiUrl}-by-ids?ids=`;
    ids.map(id => {
      requestUrl += id + '&ids=';
    });
    const result = await axios.delete<IElectricPeriod>(requestUrl);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// export const deleteEntity = createAsyncThunk(
//   'convElectricPeriod/delete_entity',
//   async (id: string | number, thunkAPI) => {
//     const requestUrl = `${apiUrl}/${id}`;
//     const result = await axios.delete<IConvElectricPeriod>(requestUrl);
//     thunkAPI.dispatch(getEntities());
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

// slice

export const ASlice = createEntitySlice({
  name: 'electricPeriod',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntities.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ASlice.actions;

// Reducer
export default ASlice.reducer;
