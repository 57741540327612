import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IElectricTariff, defaultValue } from 'src/shared/model/electric-tariff.model';
import { createEntitySlice, EntityState, serializeAxiosError } from 'src/app/reducers/utils/reducer.utils';
import { cleanEntity } from 'src/shared/utils/entity-utils';

const initialState: EntityState<IElectricTariff> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/electric-tariffs';

// Actions

export const updateEntity = createAsyncThunk(
  'convElectricTariff/update_entity',
  async (entity: IElectricTariff, thunkAPI) => {
    const result = await axios.put<IElectricTariff>(`${apiUrl}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const ASlice = createEntitySlice({
  name: 'convElectricTariff',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })

      .addMatcher(isPending(updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ASlice.actions;

// Reducer
export default ASlice.reducer;
