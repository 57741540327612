import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
  name: 'toastMessage',
  initialState: { message: { severity: 'success', summary: 'Sessión', detail: 'Iniciada Correctamente' }, display: false },
  reducers: {
    setToastMessage(state, action) {
      state.message = action.payload;
    },
  },
});

export const { setToastMessage } = toastSlice.actions;
export default toastSlice.reducer;
