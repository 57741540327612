import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import { PLOT_ENERGY, PLOT_PV_ENERGY } from 'src/app/config/constants';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useConvElectricPlotGenerator } from 'src/app/hooks/use-conv-electric-plot-generator';
import { addPlots } from 'src/app/reducers/modules/plots.reducer';
import { setToastMessage } from 'src/app/reducers/toast-message.reducer';
import { convElectricPlotVariableOptions, energyDateFormatOptions, variableSuffixOptions } from 'src/shared/utils/conv-electric-data-utils';
import { ConvElectricPlotsDataTable } from './conv-electric-plots-datatable';
import { PlotDonut } from './plots-donut';
import { LineGraph } from './LineGraph';
import { PlotsOptions } from './plots-options';

export const SimpleOptionsPlot = (props: {
  device: any;
  datas?: any[];
  plotVariable: any;
  showDateFormatOptions?;
  showHighlightPeriodOptions?: any;
  showDounut?: boolean;
  showDataTable?: boolean;
  showOptions?: boolean;
  height?: any;
  customPlotTitle?: any;
}) => {
  const { t } = useTranslation();
  const variableType = useAppSelector(state => state.plots['variableType' + props.plotVariable]);
  const style = useAppSelector(state => state.plots['style' + props.plotVariable]);
  const highlightPeriod = useAppSelector(state => state.plots['highlightPeriod' + props.plotVariable]);
  const dateFormat = useAppSelector(state => state.plots['dateFormat' + props.plotVariable]);
  const dispatch = useAppDispatch();

  const variableName = t('convElectricData:' + (props.device?.voltageLines === 1 ? 'mono.' : 'tri.') + variableType) + ' ';
  const variableSuffix = variableSuffixOptions({ plotVariable: props.plotVariable });
  const variableOptions = convElectricPlotVariableOptions({ plotVariable: props.plotVariable, model: props.device.model, voltajeLines: props.device.voltageLines });
  const suffix = variableSuffix[variableType];
  const devName = props.device.readerType === 1 ? 'Panel(' + props.device.name + ')' : 'Red(' + props.device.name + ')';
  const dateFormatOptions = props.plotVariable === PLOT_PV_ENERGY || props.plotVariable === PLOT_ENERGY ? energyDateFormatOptions() : [];

  const { lineData, dounutData, dataTable } = useConvElectricPlotGenerator({
    datas: props.datas,
    style,
    highlightPeriod,
    variableName,
    variableType,
    suffix,
    devName,
    dateFormat,
  });
  const setPlotsFunction = () => {
    dispatch(setToastMessage({ severity: 'success', summary: 'Comparativa', detail: 'Gráfica Agreagada Correctamente' }));
    dispatch(addPlots(lineData));
  };
  return (
    <div className="grid" style={{ padding: 0 }}>
      {props.showOptions && (
        <div className={'col-12 '}>
          <PlotsOptions plotVariable={props.plotVariable} variableTypeOptions={variableOptions} dateFormatOptions={dateFormatOptions} addPlotToCompare={setPlotsFunction} />
        </div>
      )}
      <div className={'p-panel col-12 ' + (props.showDounut && 'lg:col-8')} style={{ textAlign: 'center' }}>
        <Panel header={'Gestión de Potencia del Día'} style={{ padding: 0 }}>
          <LineGraph rangeSlider={true} height={props.height ? props.height : '600px'} data={lineData.datas} suffix={suffix} dateFormat={dateFormat} yAxisRange={0} />
        </Panel>
      </div>
      <div className={'col-12 lg:col-4'} style={{ textAlign: 'center', display: props.showDounut ? 'block' : 'none' }}>
        <Panel header={'Consumo de Energía de la Instalación'}>{dounutData && <PlotDonut centerText={dounutData.centerText} data={dounutData} height={props.height ? props.height : '600px'} />}</Panel>
      </div>
      {props.showDataTable && (
        <div className="col-12" style={{ paddingTop: 15, textAlign: 'center' }}>
          <ConvElectricPlotsDataTable data={dataTable} variableName={variableName} dateFormat={dateFormat} />
        </div>
      )}
    </div>
  );
};
