
export interface IElectricSgmAlarmConfig {
  id?: string;
  macId?: string;
  volPAEna?: number;
  volPBEna?: number;
  volPCEna?: number;
  volLAEna?: number;
  volLBEna?: number;
  volLCEna?: number;
  curAEna?: number;
  curBEna?: number;
  curCEna?: number;
  pwrFAEna?: number;
  pwrFBEna?: number;
  pwrFCEna?: number;
  insPTEna?: number;
  insPAEna?: number;
  insPBEna?: number;
  insPCEna?: number;
  volPA?: number;
  volPB?: number;
  volPC?: number;
  volLA?: number;
  volLB?: number;
  volLC?: number;
  curA?: number;
  curB?: number;
  curC?: number;
  pwrFA?: number;
  pwrFB?: number;
  pwrFC?: number;
  insPT?: number;
  insPA?: number;
  insPB?: number;
  insPC?: number;
  volPAMessage?: string;
  volPBMessage?: string;
  volPCMessage?: string;
  volLAMessage?: string;
  volLBMessage?: string;
  volLCMessage?: string;
  curAMessage?: string;
  curBMessage?: string;
  curCMessage?: string;
  pwrFAMessage?: string;
  pwrFBMessage?: string;
  pwrFCMessage?: string;
  insPTMessage?: string;
  insPAMessage?: string;
  insPBMessage?: string;
  insPCMessage?: string;
}

export const defaultValue: Readonly<IElectricSgmAlarmConfig> = {
  volPA: 0,
  volPB: 0,
  volPC: 0,
  volLA: 0,
  volLB: 0,
  volLC: 0,
  volPAMessage: '-',
  volPBMessage: '-',
  volPCMessage: '-',
  volLAMessage: '-',
  volLBMessage: '-',
  volLCMessage: '-',
  insPTMessage: '-',
  insPAMessage: '-',
  insPBMessage: '-',
  insPCMessage: '-',
};
