import { IElectricCostActiveEnergyReport } from './electric-cost-active-energy-report.model';
import { IElectricCostPowerReport } from './electric-cost-power-report.model';
import { IElectricCostReactiveEnergyReport } from './electric-cost-reactive-report.model';

export interface IElectricCostReport {
  id?: string;
  macId?: string;
  devName?: string;
  deviceLocation?: string;
  invoiceNumber?: string;
  cups?: string;
  holder?: string;
  address?: string;
  state?: string;
  startDate?: string;
  endDate?: string;
  createdBy?: string;
  energy?: number;
  powerCost?: number;
  energyCost?: number;
  convElectricCostActiveEnergyReportList?: IElectricCostActiveEnergyReport[];
  convElectricCostPowerReportList?: IElectricCostPowerReport[];
  convElectricCostReactiveEnergyReportList?: IElectricCostReactiveEnergyReport[];
  convElectricTariff?: string;
  energyConsumption?: number;
  subtotal?: number;
  electricTax?: number;
  iva?: number;
  total?: number;
}

export const defaultValue: Readonly<IElectricCostReport> = {};
