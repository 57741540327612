export const decimalFormat = (number, digits) => {
  return number ? number.toLocaleString('it', { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits }) : 0;
};

export const decimalAutoFormat = number => {
  const decimals = number ? (number < 1 ? 4 : number < 10 ? 2 : 0) : 0;
  return number ? number.toLocaleString('it', { style: 'decimal', minimumFractionDigits: decimals, maximumFractionDigits: decimals }) : 0;
};

export const decimalFormatForXLXS = (number, digits) => {
  return number ? number.toLocaleString('us', { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits }) : 0;
};

export const decimalFormatArrayNumber = (number, digits) => {
  return number
    ? '[' +
        number[0].toLocaleString('it', { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits }) +
        ' ; ' +
        number[1].toLocaleString('it', { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits }) +
        ']'
    : +'[' + 0 + ';' + 0 + ']';
};
