import _ from 'lodash';
import dayjs from 'dayjs';
import { useAppSelector } from 'src/app/hooks';
import useSWR from 'swr';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import { TabPanel, TabView } from 'primereact/tabview';
import { AUTHORITIES, PLOT_ENERGY } from 'src/app/config/constants';
import { SimpleOptionsPlot } from 'src/shared/components/plots/simple-options-plot';
import { ConvElectricEnergyPrice as ElectricEnergyPrice } from './components/conv-electric-energy-price-component';
import { hasAnyAuthority } from 'src/shared/auth/private-route';

const ElectricSgmDashboardComponent = ({ device, showDounut }) => {
  const selectedConvElectricPlant = useAppSelector(state => state.plantSelector.selectedPlant);
  const id = selectedConvElectricPlant?.id;
  const startDate = dayjs().startOf('day').toISOString();
  const endDate = dayjs().endOf('day').toISOString();
  const { data: datas } = useSWR<any>('api/electric-datas-energy-data-by-date-range?plantId=' + id + '&macId=' + device.macId + '&startDate=' + startDate + '&endDate=' + endDate, swrFetcher);
  return datas && <SimpleOptionsPlot height={'300px'} device={device} datas={datas} plotVariable={PLOT_ENERGY} showDounut={showDounut} />;
};

export const ElectricSgmDashboard = () => {
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const devices = plant.devices;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  return (
    <TabView>
      {devices &&
        devices.map((res, index) => {
          const device = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines };
          const devName = 'D' + res.index + (isAdmin && '(' + res.macId + ')') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
          return (
            <TabPanel key={res.macId} header={devName}>
              <ElectricSgmDashboardComponent device={device} showDounut={true} />
              <ElectricEnergyPrice />
            </TabPanel>
          );
        })}
    </TabView>
  );
};
