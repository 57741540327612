import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from './shared/error/error-boundary-routes';
import Home from './modules/dashboard';
import PrivateRoute from './shared/auth/private-route';
import { AUTHORITIES } from './app/config/constants';
import { ConvElectricPeriod } from './modules/electric-periods/electric-period';
import { AlarmLog } from './modules/alarm-log/alarm-log';
import AlarmConfig from './modules/alarm-config/alarm-config';
import { PlantManagement } from './modules/plants-management/plant';
import { DeviceManagement } from './modules/devices-management/device-management';
import { Plots } from './modules/plots-single/plots';
import { PlotsComparative } from './modules/plots-comparative/plots-comparative';
import { Tariff } from './modules/tariff/tariff';
import { SimpleData } from './modules/instant-data/instant-data';
import { CostReport } from './modules/cost-reports/cost-report';

const AppRoutes = () => {
  //prettier-ignore
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route index element={<Home />} />
        <Route path="/conv-electric-period_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><ConvElectricPeriod /></PrivateRoute> } />
        <Route path="/simple-data_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><SimpleData /></PrivateRoute> } />
        <Route path="/alarm-log_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><AlarmLog /></PrivateRoute> } />
        <Route path="/alarm-config_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><AlarmConfig /></PrivateRoute> } />
        <Route path="/plant-management_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><PlantManagement /></PrivateRoute> } />
        <Route path="/device-management_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><DeviceManagement /></PrivateRoute>}/>
        <Route path="/tariff_" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MAINTAINER, AUTHORITIES.APP_USER]}><Tariff /></PrivateRoute>}/>
        <Route path="/plots_" element={<Plots />} />
        <Route path="/plots-comparative_" element={<PlotsComparative />}/>
        <Route path="/cost_reports_" element={<CostReport />}/>
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
