import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'src/app/reducers/utils/reducer.utils';
import { IElectricSgmAlarmConfig, defaultValue } from 'src/shared/model/electric-sgm-alarm-config.model';
import { cleanEntity } from 'src/shared/utils/entity-utils';

const initialState: EntityState<IElectricSgmAlarmConfig> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/electric-sgm-alarm-configs';

// Actions

// export const getConvElectricAlarmConfigsByDevId = createAsyncThunk('convElectricAlarmConfig/fetch_entity_list', async (devId: string) => {
//   const requestUrl = `${apiUrl}-by-devid?devId=${devId}`;
//   return axios.get<IConvElectricAlarmConfig[]>(requestUrl);
// });

// export const getConvElectricAlarmConfigByDevId = createAsyncThunk('convElectricAlarmConfig/fetch_entity', async (devId: string) => {
//   const requestUrl = `${apiUrl}-by-devid?devId=${devId}`;
//   return axios.get<IConvElectricAlarmConfig>(requestUrl);
// });

export const getEntities = createAsyncThunk('convElectricAlarmConfig/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IElectricSgmAlarmConfig[]>(requestUrl);
});

export const getConvElectricAlarmConfigByDevId = createAsyncThunk(
  'convElectricAlarmConfig/fetch_entity',
  async (devId: string | number) => {
    const requestUrl = `${apiUrl}-by-devid?devId=${devId}`;
    return axios.get<IElectricSgmAlarmConfig>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'convElectricPvAlarmConfig/update_entity',
  async (entity: any) => {
    const result = await axios.put<IElectricSgmAlarmConfig>(`${apiUrl}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'convElectricAlarmConfig/partial_update_entity',
  async (entity: IElectricSgmAlarmConfig) => {
    const result = await axios.patch<IElectricSgmAlarmConfig>(`${apiUrl}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntities = createAsyncThunk(
  'convElectricAlarmConfig/delete_entities',
  async (ids: string[]) => {
    let requestUrl = `${apiUrl}-by-ids?ids=`;
    ids.map(id => {
      requestUrl += id + '&ids=';
    });
    return await axios.delete<IElectricSgmAlarmConfig>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ASlice = createEntitySlice({
  name: 'convElectricAlarmConfig',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getConvElectricAlarmConfigByDevId.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntities.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      // .addMatcher(isFulfilled(getEntities, getConvElectricAlarmConfigsByDevId), (state, action) => {
      //   return {
      //     ...state,
      //     loading: false,
      //     entities: action.payload.data,
      //   };
      // })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      // .addMatcher(isPending(getEntities, getConvElectricAlarmConfigByDevId, getConvElectricAlarmConfigsByDevId), state => {
      //   state.errorMessage = null;
      //   state.updateSuccess = false;
      //   state.loading = true;
      // })
      .addMatcher(isPending(updateEntity, partialUpdateEntity, deleteEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ASlice.actions;

// Reducer
export default ASlice.reducer;
