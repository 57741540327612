import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { HKInputNumber } from 'src/shared/components/general/primereact-custom';
import { Fieldset } from 'primereact/fieldset';

export const TariffComponent = ({ convElectricTariff, saveConvElectricTariff }) => {
  const toast = useRef(null);
  const form = useForm({ defaultValues: convElectricTariff });

  const onSubmit = data => {
    saveConvElectricTariff(data);
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Fieldset legend="Potencia Contratada" className="mt-5">
          <div className="grid">
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerP1" label={'P1'} required="Dato Requerido" minFractionDigits={0} suffix="kW" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerP2" label={'P2'} required="Dato Requerido" minFractionDigits={0} suffix="kW" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerP3" label={'P3'} required="Dato Requerido" minFractionDigits={0} suffix="kW" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerP4" label={'P4'} required="Dato Requerido" minFractionDigits={0} suffix="kW" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerP5" label={'P5'} required="Dato Requerido" minFractionDigits={0} suffix="kW" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerP6" label={'P6'} required="Dato Requerido" minFractionDigits={0} suffix="kW" />
          </div>
        </Fieldset>
        <Fieldset legend="Termino de Potencia" className="mt-2">
          <div className="grid">
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerTermP1" label={'P1'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerTermP2" label={'P2'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerTermP3" label={'P3'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerTermP4" label={'P4'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerTermP5" label={'P5'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="powerTermP6" label={'P6'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
          </div>
        </Fieldset>
        <Fieldset legend="Exceso de Potencia" className="mt-2">
          <div className="grid">
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="maximeterTermP1" label={'P1'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="maximeterTermP2" label={'P2'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="maximeterTermP3" label={'P3'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="maximeterTermP4" label={'P4'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="maximeterTermP5" label={'P5'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="maximeterTermP6" label={'P6'} required="Dato Requerido" minFractionDigits={6} suffix="€/kW*dia" />
          </div>
        </Fieldset>
        <Fieldset legend="Termino de Energia" className="mt-2">
          <div className="grid">
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="energyTermP1" label={'P1'} required="Dato Requerido" minFractionDigits={6} suffix="€/kWh" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="energyTermP2" label={'P2'} required="Dato Requerido" minFractionDigits={6} suffix="€/kWh" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="energyTermP3" label={'P3'} required="Dato Requerido" minFractionDigits={6} suffix="€/kWh" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="energyTermP4" label={'P4'} required="Dato Requerido" minFractionDigits={6} suffix="€/kWh" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="energyTermP5" label={'P5'} required="Dato Requerido" minFractionDigits={6} suffix="€/kWh" />
            <HKInputNumber className={'col-12 md:col-4'} form={form} name="energyTermP6" label={'P6'} required="Dato Requerido" minFractionDigits={6} suffix="€/kWh" />
          </div>
        </Fieldset>
        <Fieldset legend="Impuesto" className="mt-2">
          <HKInputNumber className={'col-12 md:col-4'} form={form} name="electricTax" label={'Impuesto Electrico'} required="Dato Requerido" minFractionDigits={1} suffix="%" />
          <HKInputNumber className={'col-12 md:col-4'} form={form} name="iva" label={'IVA'} required="Dato Requerido" minFractionDigits={1} suffix="%" />
        </Fieldset>
        <Button className="mt-2" label="Actualiar" type="submit" icon="pi pi-check" />
      </form>
    </div>
  );
};
