export interface IElectricPeriod {
  id?: string;
  name?: string;
  isReactive?: boolean;
  reactivePenaltyValue1?: number;
  reactivePenaltyValue2?: number;
  reactivePenaltyPercent1?: number;
  reactivePenaltyPercent2?: number;
}

export const defaultValue: Readonly<IElectricPeriod> = {
  isReactive: false,
};
