import { useEffect, useState } from 'react';
import { createEntity as createPlant, updateEntity as updatePlant, deleteEntities as deletePlants } from 'src/app/reducers/entities/plant.reducer';
import axios from 'axios';
import { IUser } from 'src/shared/model/user.model';
import { AUTHORITIES } from 'src/app/config/constants';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { DatatableV1 } from 'src/shared/components/general/DatatableV1';
import { fieldTemplate, exportFormatter } from 'src/shared/components/datatable-templates/plant-management-template';

export const PlantManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useNavigationMenuTitle(t('menu:titlePlantManagement'));
  const updateSuccess = useAppSelector(state => state.plant.updateSuccess);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isCAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CADMIN]));
  const isMaintainer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.MAINTAINER]));
  const role = isAdmin ? 'admin' : isCAdmin ? 'cadmin' : isMaintainer ? 'maintainer' : 'appuser';
  const row = 200;
  const columns = [
    { field: 'id', header: '', type: 'String', width: 160, editable: false, roles: ['admin', 'cadmin'] },
    { field: 'name', header: '', type: 'String', width: 200, editable: true, filter: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'type', header: '', type: 'StringDropdown', width: 200, editable: true, filter: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'location', header: '', type: 'String', width: 180, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'latitude', header: '', type: 'String', width: 180, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'longitude', header: '', type: 'String', width: 130, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'appUser', header: '', type: 'StringDropdown', width: 200, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'maintainerUser', header: '', type: 'MultiSelect', width: 200, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'commercialUser', header: '', type: 'String', width: 200, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
  ]
    .filter(column => column.roles.includes(role))
    .map(column => ({ ...column, header: t('plant:' + column.field) }));

  const [objectOptions, setObjectOptions] = useState<any>({});

  const fetchOptions = async () => {
    const objOptions = {
      status: [
        { label: 'Activado', value: 'installed' },
        { label: 'Desactivado', value: 'instock' },
      ],
      type: [
        { label: 'ELÉCTRICA', value: '1' },
        { label: 'FOTOVOLTÁICA', value: '2' },
      ],
    };
    const users = await axios.get<IUser[]>(`/api/users`);
    objOptions['appUser'] = JSON.parse(JSON.stringify(users.data))
      .filter((user: IUser) => user.authorities.includes('ROLE_APP_USER'))
      .map((user: IUser) => {
        return { label: user.email + ' > (' + user.firstName + ' ' + user.lastName + ')', value: user.email };
      });
    objOptions['commercialUser'] = users.data
      .filter((user: IUser) => user.authorities.includes('ROLE_COMMERCIAL'))
      .map((user: IUser) => {
        return { label: user.firstName + ' ' + user.lastName, value: user.email };
      });
    delete objOptions['commercialUser'].authorities;
    objOptions['maintainerUser'] = users.data
      .filter((user: IUser) => user.authorities.includes('ROLE_MAINTAINER'))
      .map((user: IUser) => {
        return { label: user.firstName + ' ' + user.lastName, value: user.email };
      });
    setObjectOptions(objOptions);
  };

  useEffect(() => {
    if (isAdmin || isCAdmin || isMaintainer) {
      fetchOptions();
    }
  }, []);

  const updateEntity = entity => {
    dispatch(updatePlant(entity));
  };

  const saveEntity = entity => {
    dispatch(createPlant(entity));
  };

  const deleteEntity = entities => {
    dispatch(deletePlants(entities));
  };

  const template = (entity: any, column: any) => {
    return fieldTemplate(entity, column, objectOptions);
  };

  const formatter = (entity: any, column: any) => {
    return exportFormatter(entity, column, objectOptions);
  };
  return (
    <div>
      <DatatableV1
        scrollHeigh="calc( 100vh - 300px )"
        columns={columns}
        objectOptions={objectOptions}
        fieldTemplate={template}
        exportFormatter={formatter}
        rows={row}
        sortField={'name'}
        selectionMode={isAdmin || isCAdmin ? 'multiple' : 'single'}
        getEntitiesUrl={'api/plants'}
        saveEntityAction={isAdmin && saveEntity}
        updateEntityAction={updateEntity}
        deleteEntityAction={isAdmin && deleteEntity}
        updateSuccess={updateSuccess}
      />
    </div>
  );
};
