import i18next from 'i18next';
import { Column } from 'primereact/column';
const t = i18next.t;
const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then(FileSaver => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
};

export const exportExcel = (props: { exportFormatter; columns: any[]; data: any[] }) => {
  const exportData = [];
  props.data.map(res => {
    const newData = {};
    props.columns.forEach(col => {
      newData[col.header] = props.exportFormatter(col.field, res);
    });
    exportData.push(newData);
  });
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(exportData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'products');
  });
};

export const exportPdf = (props: { headerTranslationKey; exportFormatter; columns: any[]; data: any[] }) => {
  // const exportColumns = props.columns.map(col => ({
  //   title: translate(props.headerTranslationKey + col.field),
  //   dataKey: col.field,
  // }));
  // const exportedData = [];
  // props.data.map(res => {
  //   const newData = {};
  //   props.columns.forEach(col => {
  //     newData[col.field] = props.exportFormatter(col.field, res[col.field]);
  //   });
  //   exportedData.push(newData);
  // });
};
