import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
// import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { locale as primeLocale } from 'primereact/api';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setCurrentLocale } from './app/reducers/currentLocale.reducer';
import { hasAnyAuthority } from './shared/auth/private-route';
import { AUTHORITIES } from './app/config/constants';
import { NavLink } from 'react-router-dom';

export const AppTopbar = (props: { onToggleMenu: React.MouseEventHandler; manuals: any; updateManual: any; getManuals: any }) => {
  const navigationMenuTitle = useAppSelector(state => state.appMenu.navigationMenuTitle);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  // const [language, setLanguage] = useState('');
  const loadingBar = useAppSelector(state => state.loadingBar);
  const languages = [
    { label: 'Français', value: 'fr' },
    { label: 'Português', value: 'pt-pt' },
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' },
  ];

  useEffect(() => {
    primeLocale(currentLocale);
  }, []);

  const renderFooter = (
    <div>
      <Button label="Cerrar" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
      <Button
        label="Guardar"
        icon="pi pi-check"
        // onClick={() => {
        //   props.updateManual(manual);
        //   props.getManuals();
        // }}
        autoFocus
      />
    </div>
  );

  const onLanguageChange = e => {
    dispatch(setCurrentLocale(e.value));
    primeLocale(e.value);
  };

  const onHide = () => {
    setVisible(false);
  };

  const onShow = () => {
    if (props.manuals.length > 0) {
      // setManual(props.manuals[0]);
    }
  };

  const languagesOptionTemplate = option => {
    if (option) {
      return (
        <div>
          <img src="/static-images/flag_placeholder.png" className={`flag flag-${option.value.toLowerCase()}`} />
          <span>{option.label}</span>
        </div>
      );
    }
    return <span></span>;
  };
  const selectedLanguageTemplate = option => {
    if (option) {
      return (
        <div>
          <img src="/static-images/flag_placeholder.png" className={`flag flag-${option.value.toLowerCase()}`} />
          <span>{option.label}</span>
        </div>
      );
    }

    return <span></span>;
  };

  return (
    <div>
      <div className="layout-topbar" style={{ verticalAlign: 'center' }}>
        <a className="layout-menu-button" onClick={props.onToggleMenu} style={{ marginLeft: 10 }}>
          <span style={{ fontSize: '3rem', height: '3rem' }} className="pi pi-bars" />
        </a>
        <div
          style={{
            position: 'fixed',
            top: '-0.3rem',
            color: 'var(--top-font-color)',
            display: 'inline-block',
          }}
        >
          <span style={{ marginLeft: 5, padding: 0, fontSize: '3rem', top: '2rem', display: 'inline' }}>{navigationMenuTitle || '---'}</span>
          {loadingBar.display && (
            <div style={{ display: 'inline' }}>
              <ProgressSpinner style={{ width: '2rem', height: '2rem', marginLeft: 5 }} strokeWidth="10" />
            </div>
          )}
        </div>

        <div className="layout-topbar-icons">
          <NavLink to="/" className="d-flex align-items-center ml-3" style={{ top: '0.4rem' }}>
            <span style={{ padding: 0, margin: 0, fontSize: '2rem' }} className="mr-3 layout-topbar-icon pi pi-th-large" />
          </NavLink>
          {isAdmin && (
            <Dropdown value={currentLocale} options={languages} onChange={onLanguageChange} filter valueTemplate={selectedLanguageTemplate} itemTemplate={languagesOptionTemplate} className={'mr-3'} />
          )}
        </div>
      </div>
    </div>
  );
};
