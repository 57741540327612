import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'src/app/reducers/utils/reducer.utils';
import { IElectricCostReport, defaultValue } from 'src/shared/model/electric-cost-report.model';

const initialState: EntityState<IElectricCostReport> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/electric-cost-reports';

// Actions

export const createEntity = createAsyncThunk(
  'electricCostReport/create_entity',
  async (props: { plantId; macId; startDate; endDate; persist }) => {
    const requestUrl = `${apiUrl}?plantId=${props.plantId}&macId=${props.macId}&startDate=${props.startDate}&endDate=${props.endDate}&persist=${props.persist}`;
    const result = await axios.post<IElectricCostReport>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntities = createAsyncThunk(
  'electricCostReport/create_allentity',
  async (devId: string) => {
    const requestUrl = `${apiUrl}-create-all-by-devid?devId=${devId}`;
    const result = await axios.post<IElectricCostReport>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ASlice = createEntitySlice({
  name: 'electricCostReport',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(createEntity, createEntities), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(createEntity, createEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ASlice.actions;

// Reducer
export default ASlice.reducer;
