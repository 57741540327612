import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { PlantSelector } from '../plant-selector/plant-selector';
import { ElectricDataPlots } from './electric-data-plots';

export const Plots = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:plots'));
  return (
    <div>
      <PlantSelector selectionMode={'single'} />
      <ElectricDataPlots />
    </div>
  );
};
