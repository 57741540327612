import i18next from 'i18next';
import { timeStampFormat } from 'src/shared/utils/date-utils';
import { decimalFormat } from 'src/shared/utils/number-utils';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import useSWR from 'swr';
import { useAppSelector } from '../hooks';

const t = i18next.t;

export const useFetchElectricDataPvCombined = (props: { startDate: string; endDate: string; timeZone?: string }) => {
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const url = 'api/electric-datas-multiple-by-date-range?plantId=' + plant.id + '&startDate=' + props.startDate + '&endDate=' + props.endDate;
  const { data: electricSgmMultipleDatas } = useSWR(plant.id && url, swrFetcher);

  const appSetting = useAppSelector(state => state.appSettings.entity);
  const dataLine = [];
  const dataTable = [];
  const timeStamp = [];
  const panelProduction = [];
  const gridConsumption = [];
  const installationConsumption = [];
  const pylonEPITs: number[] = [];
  const pylonEPETs: number[] = [];
  const pvPanelEPITs: number[] = [];
  const dounutColor = [];
  let lastData = [];
  let dounutData = {
    labels: [],
    values: [],
    centerText: '',
    hole: 0.7,
    type: 'pie',
    automargin: true,
    showlegend: true,
    hoverinfo: 'none',
    legend: { orientation: 'h' },
    sort: false,
    marker: {
      colors: dounutColor,
    },
  };
  if (electricSgmMultipleDatas) {
    const keys = Object.keys(electricSgmMultipleDatas.datas).sort();

    if (keys.length > 0) {
      keys.forEach((res, index) => {
        if (electricSgmMultipleDatas.datas[res].length > 1) {
          const timeStampTemp = timeStampFormat(res, props.timeZone, 'YYYY-MM-DDTHH:mm:00');
          timeStamp.push(timeStampTemp);
          let pvData = 0;
          let gridData = 0;
          electricSgmMultipleDatas.datas[res].forEach(res1 => {
            if (res1.readerType === 1) {
              pvData = res1.insPT;
              panelProduction.push(pvData);
              pvPanelEPITs.push(res1.epIT);
            } else {
              gridData = res1.insPT;
              gridConsumption.push(res1.insPT);
              pylonEPITs.push(res1.epIT);
              pylonEPETs.push(res1.epET);
            }
          });
          const installation = pvData + gridData;
          dataTable.push({ id: index, timeStamp: res, pvData, gridData, installation });
          lastData = electricSgmMultipleDatas.datas[res];
          installationConsumption.push(installation);
        }
      });

      const pylonEPITTotal = Math.max(...pylonEPITs) - Math.min(...pylonEPITs);
      const pylonEPETTotal = Math.max(...pylonEPETs) - Math.min(...pylonEPETs);
      const pvPanelEPITTotal = Math.max(...pvPanelEPITs) - Math.min(...pvPanelEPITs);

      dataLine.push({
        type: 'scatter',
        x: timeStamp,
        y: panelProduction,
        fill: 'tozeroy',
        name: t('electricSgmPlots:panelProduction'),
        line: { shape: 'spline', smoothing: 0.5 },
        showlegend: true,
        marker: {
          color: appSetting.appPlotPvPanelColor,
        },
      });
      dataLine.push({
        type: 'scatter',
        x: timeStamp,
        y: gridConsumption,
        fill: 'tozeroy',
        name: t('electricSgmPlots:girdImported'),
        line: { shape: 'spline', smoothing: 0.5 },
        showlegend: true,
        marker: {
          color: appSetting.appPlotPvGridColor,
        },
      });
      dataLine.push({
        type: 'scatter',
        x: timeStamp,
        y: installationConsumption,
        fill: 'tozeroy',
        name: t('electricSgmPlots:installationConsumption'),
        line: { shape: 'spline', smoothing: 0.5 },
        showlegend: true,
        marker: {
          color: appSetting.appPlotPvInstallationColor,
        },
      });
      const dounutDataLables = [
        t('electricSgmPlots:panelImported') + ' ' + decimalFormat(pvPanelEPITTotal - pylonEPETTotal, 2) + 'kWh',
        t('electricSgmPlots:girdImported') + ' ' + decimalFormat(pylonEPITTotal, 2) + 'kWh',
      ];

      const dounutDataValues = [pvPanelEPITTotal - pylonEPETTotal, pylonEPITTotal];
      dounutColor.push(appSetting.appPlotPvPanelColor, appSetting.appPlotPvGridColor);

      dounutData = {
        labels: dounutDataLables,
        values: dounutDataValues,
        centerText:
          '<b>Panel</b> <br> Producción:' +
          decimalFormat(pvPanelEPITTotal, 2) +
          'kWh <br> <b>Instalación</b> <br> Consumo:' +
          decimalFormat(pylonEPITTotal + (pvPanelEPITTotal - pylonEPETTotal), 2) +
          'kWh <br> <b>Red</b> <br> Consumo:' +
          decimalFormat(pylonEPITTotal, 2) +
          'kWh <br> Generación:' +
          decimalFormat(pylonEPETTotal, 2) +
          'kWh',
        hole: 0.7,
        type: 'pie',
        automargin: true,
        showlegend: true,
        hoverinfo: 'none',
        legend: { orientation: 'h' },
        sort: false,
        marker: {
          colors: dounutColor,
        },
      };
    }
  }
  
  return { dataTable, dataLine, dounutData, lastData };
};
