import activated_es from 'src/shared/i18n/es/activate.json';
import alarmConfig_es from 'src/shared/i18n/es/alarmConfig.json';
import alarmLog_es from 'src/shared/i18n/es/alarmLog.json';
import report_es from 'src/shared/i18n/es/report.json';
import global_es from 'src/shared/i18n/es/global.json';
import entity_es from 'src/shared/i18n/es/entity.json';
import menu_es from 'src/shared/i18n/es/menu.json';

import electricSgmPlots_es from 'src/shared/i18n/es/electricSgmPlots.json';
import plant_es from 'src/shared/i18n/es/plant.json';
import electricSgmDevice_es from 'src/shared/i18n/es/electricSgmDevice.json';
import convElectricData_es from 'src/shared/i18n/es/convElectricData.json';
import combinerBoxDevice_es from 'src/shared/i18n/es/combinerBoxDevice.json';
import deviceSelectorType_es from 'src/shared/i18n/es/deviceSelectorType.json';
import convElectricTariff_es from 'src/shared/i18n/es/convElectricTariff.json';
import convElectricPeriod_es from 'src/shared/i18n/es/convElectricPeriod.json';
import convElectricCostReport_es from 'src/shared/i18n/es/electricCostReport.json';

export const i18nES = {
  activated: activated_es,
  alarmConfig: alarmConfig_es,
  alarmLog: alarmLog_es,
  report: report_es,
  global: global_es,
  entity: entity_es,
  menu: menu_es,
  plant: plant_es,
  electricSgmDevice: electricSgmDevice_es,
  combinerBoxDevice: combinerBoxDevice_es,
  deviceSelectorType: deviceSelectorType_es,
  convElectricData: convElectricData_es,
  convElectricPeriod: convElectricPeriod_es,
  convElectricTariff: convElectricTariff_es,
  electricSgmPlots: electricSgmPlots_es,
  convElectricCostReport: convElectricCostReport_es,
};
