import dayjs from 'dayjs';
import { decimalFormat } from 'src/shared/utils/number-utils';
import { DatatableRDV1 } from '../general/DatatableRDV1';
import i18next from 'i18next';

const exportFormatter = (field: any, entity: any) => {
  if (field === 'x') {
    return entity && dayjs(entity.x).format('DD-MM-YYYY HH:mm');
  } else if (field === 'y') {
    const value = entity && entity.y;
    return decimalFormat(value ? value : 0, 3) + ' ' + entity?.suffix;
  } else {
    return '';
  }
};

const fieldTemplate = (entity: any, column: any) => {
  const field = column['field'];
  const value = exportFormatter(field, entity);
  return <div style={{ textAlign: 'center' }}>{value}</div>;
};

export const ConvElectricPlotsDataTable = (props: { data: any[]; variableName; dateFormat }) => {
  const t = i18next.t;

  const columns = [
    { id: 1, field: 'x', header: t('electricSgmPlots:timeStamp'), type: 'String', width: 150, editable: true },
    { id: 3, field: 'y', header: props.variableName, type: 'String', width: 100, editable: true },
  ];

  return (
    <DatatableRDV1
      scrollHeigh="calc( 100vh - 300px )"
      columns={columns}
      data={props.data}
      objectOptions={{}}
      fieldTemplate={fieldTemplate}
      exportFormatter={exportFormatter}
      rows={50}
      sortField={'x'}
      sortOrder={-1}
    />
  );
};
