import useSWR from 'swr';
import { swrFetcher } from 'src/shared/utils/swrUtil';

export const ConvElectricPvWheatherComponent = (props: { latitude: any; longitude: any }) => {
  
  const { data: wheatherInfo } = useSWR('api/weather?q=' + props.latitude + ',' + props.longitude, swrFetcher, { revalidateOnFocus: false, dedupingInterval: 3600000 });

  return (
    wheatherInfo && (
      <div className="grid " style={{ height: 330 }}>
        <div className="col-12">
          <label>Localidad: </label>
          <span>{wheatherInfo && wheatherInfo.location && wheatherInfo.location.name}</span>
        </div>
        <div className="col-12">
          <label>Humedad: </label>
          <span>{wheatherInfo && wheatherInfo.current && wheatherInfo.current.humidity}%</span>
        </div>
        <div className="col-12">
          <label>Temperatura: </label>
          <span>{wheatherInfo && wheatherInfo.current && wheatherInfo.current.temp_c}ºC</span>
        </div>
        <div className="col-12">
          <label>Indice Rayos UV: </label>
          <span>{wheatherInfo && wheatherInfo.current && wheatherInfo.current.uv}</span>
        </div>
        <div className="col-12">
          <label>Nubes: </label>
          <span>{wheatherInfo && wheatherInfo.current && wheatherInfo.current.cloud}%</span>
        </div>
        <div className="col-12">
          <label>Condición: </label>
          <span>{wheatherInfo && wheatherInfo.current && wheatherInfo.current.condition.text}</span>
          <div>
            <img src={'https:' + wheatherInfo && wheatherInfo.current && wheatherInfo.current.condition.icon} />
          </div>
        </div>
      </div>
    )
  );
};
