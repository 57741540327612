import { PlantSelector } from '../plant-selector/plant-selector';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { ElectricSgmDashboard } from './electric-sgm';
import { useAppSelector } from 'src/app/hooks';
import { PLANT_TYPE_ELECTRIC, PLANT_TYPE_PHOTOVOLTAIC } from 'src/app/config/constants';
import { ElectricSgmPvDashboard } from './electric-sgm-pv';

export const Home = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:Dashboard'));
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  return (
    <div>
      <PlantSelector selectionMode={'single'} />
      {plant.type === PLANT_TYPE_ELECTRIC && <ElectricSgmDashboard />}
      {plant.type === PLANT_TYPE_PHOTOVOLTAIC && <ElectricSgmPvDashboard plant={plant} />}
    </div>
  );
};

export default Home;
