import { Text, Rect } from 'react-konva';
// import useImage from 'use-image';

export const InfoBoxMono = ({ name, value, pos }) => {
  const infoBoxWidth = 350;
  const textcolor = 'black';
  const offsetX = 110;
  return (
    <>
      <Rect x={10} y={10 + 25 * pos} width={infoBoxWidth * 0.3 - 20} height={16} fill="lightgray" shadowBlur={1} />
      <Text
        x={10}
        y={12 + 25 * pos}
        width={infoBoxWidth * 0.3 - 20}
        text={name}
        align={'left'}
        fontSize={12}
        fill={textcolor}
        fontStyle={'bold'}
      />
      <Rect x={offsetX} y={10 + 25 * pos} width={280} height={16} fill="white" shadowBlur={1} />
      <Text x={offsetX} y={12 + 25 * pos} text={value} align={'right'} width={280} fontSize={12} fill={textcolor} fontStyle={'bold'} />
    </>
  );
};

export const InfoBoxTri = (props: { name?: any; value1; value2; value3; pos; bgColor?: any }) => {
  const infoBoxWidth = 500;
  const textcolor = 'black';
  const offSX = 110;
  return (
    <>
      {props.name && <Rect x={10} y={10 + 25 * props.pos} width={infoBoxWidth * 0.3 - 20} height={16} fill="lightgray" shadowBlur={1} />}
      {props.name && (
        <Text
          x={10}
          y={12 + 25 * props.pos}
          width={infoBoxWidth * 0.3 - 20}
          text={props.name}
          align={'left'}
          fontSize={12}
          fill={textcolor}
          fontStyle={'bold'}
        />
      )}

      <Rect x={offSX} y={10 + 25 * props.pos} width={90} height={16} fill={props.bgColor ? props.bgColor : 'white'} shadowBlur={1} />
      <Text
        x={offSX}
        y={12 + 25 * props.pos}
        width={90}
        text={props.value1}
        align={'right'}
        fontSize={12}
        fill={textcolor}
        fontStyle={'bold'}
      />
      <Rect x={offSX + 95} y={10 + 25 * props.pos} width={90} height={16} fill={props.bgColor ? props.bgColor : 'white'} shadowBlur={1} />
      <Text
        x={offSX + 95}
        y={12 + 25 * props.pos}
        width={90}
        text={props.value2}
        align={'right'}
        fontSize={12}
        fill={textcolor}
        fontStyle={'bold'}
      />
      <Rect x={offSX + 190} y={10 + 25 * props.pos} width={90} height={16} fill={props.bgColor ? props.bgColor : 'white'} shadowBlur={1} />
      <Text
        x={offSX + 190}
        y={12 + 25 * props.pos}
        width={90}
        text={props.value3}
        align={'right'}
        fontSize={12}
        fill={textcolor}
        fontStyle={'bold'}
      />
    </>
  );
};

// export const wifiSignalImage = (csq: number) => {
//   const wifi =
//     csq >= 2 && csq < 10 ? 'wifi0' : csq >= 10 && csq < 15 ? 'wifi1' : csq >= 15 && csq < 20 ? 'wifi2' : csq >= 20 ? 'wifi3' : 'wifi';
//   const [wifiImage] = useImage('/content/images/' + wifi + '.png');
//   return wifiImage;
// };

// export const switch1ConvElectricStatusImage = (switch1: number) => {
//   const [onImage] = useImage('/content/images/on.png');
//   const [offImage] = useImage('/content/images/off.png');
//   return switch1 === 5 || switch1 === 4 ? onImage : offImage;
// };

// export const switch1CombinerBoxStatusImage = (switch1: number) => {
//   const [onImage] = useImage('/content/images/switch1_on.png');
//   const [offImage] = useImage('/content/images/switch1_off.png');
//   return switch1 ? onImage : offImage;
// };

// export const switch2CombinerBoxStatusImage = (switch2: number) => {
//   const [onImage] = useImage('/content/images/switch2_on.png');
//   const [offImage] = useImage('/content/images/switch2_off.png');
//   return switch2 ? onImage : offImage;
// };
