import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import { bodyDateTemplate, bodyEuroMoneyTemplate } from '../general/datatable-field-templates';
import { useTranslation } from 'react-i18next';

export const ReportHistory = ({ url, plantId, macId, updateSuccess }) => {
  const { t } = useTranslation();
  const historyUrl = url + '?plantId=' + plantId + '&macId=' + macId;
  const { data: convElectricInvoices } = useSWR(historyUrl, (url_: string) => axios.get(url_).then<[]>(r => r.data));

  useEffect(() => {
    if (updateSuccess) {
      mutate(historyUrl);
    }
  }, [updateSuccess]);

  const showPDFReport = (rowData: any) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          icon="pi pi-file-pdf"
          className="p-button-success p-button-raised p-button-rounded p-mr-2"
          onClick={() => {
            axios.get(url + '-retrive-pdf?id=' + rowData.id + '&plantId=' + plantId + '&macId=' + macId, { responseType: 'blob' }).then<any>(r => {
              const report = new Blob([r.data], {
                type: 'application/pdf',
              });
              window.open(URL.createObjectURL(report), '_blank');
            });
          }}
        />
      </div>
    );
  };

  const deleteReport = (rowData: any) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          icon="pi pi-trash"
          className="p-button-danger p-button-raised p-button-rounded p-mr-2"
          onClick={() => {
            axios.delete(url + '?id=' + rowData.id + '&plantId=' + plantId + '&macId=' + macId).then<any>(r => {
              mutate(historyUrl);
            });
          }}
        />
      </div>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <DataTable value={convElectricInvoices} header={'Facturas'} showGridlines stripedRows sortField="startDate" sortOrder={-1}>
          <Column field="startDate" body={bodyDateTemplate} sortable header={t('convElectricCostReport:startDate')}></Column>
          <Column field="endDate" body={bodyDateTemplate} sortable header={t('convElectricCostReport:endDate')}></Column>
          <Column field="deviceLocation" sortable header={t('convElectricCostReport:deviceLocation')}></Column>
          <Column field="subtotal" sortable body={bodyEuroMoneyTemplate} header={t('convElectricCostReport:subtotal')} style={{ minWidth: 100 }}></Column>
          <Column field="electricTax" sortable body={bodyEuroMoneyTemplate} header={t('convElectricCostReport:electricTax')} style={{ minWidth: 100 }}></Column>
          <Column field="iva" sortable body={bodyEuroMoneyTemplate} header={t('convElectricCostReport:vat')} style={{ minWidth: 100 }}></Column>
          <Column field="total" body={bodyEuroMoneyTemplate} header={t('convElectricCostReport:total')} style={{ minWidth: 100 }}></Column>
          <Column body={showPDFReport} header={t('convElectricCostReport:showPdf')}  style={{ width: 100 }}></Column>
          <Column body={deleteReport} header={t('convElectricCostReport:delete')} style={{ width: 100 }}></Column>
        </DataTable>
      </div>
    </div>
  );
};
