import { useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ToggleButton } from 'primereact/togglebutton';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useTranslation } from 'react-i18next';
import { PLOT_ENERGY, PLOT_ELECTRICAL, PLOT_PV_INSTALLATION, PLOT_PV_ELECTRICAL, PLOT_PV_ENERGY } from 'src/app/config/constants';
import {
  setDateFormat0,
  setDateFormat1,
  setDateFormat2,
  setDateFormat3,
  setDateFormat4,
  setHighlightPeriod0,
  setHighlightPeriod1,
  setHighlightPeriod2,
  setHighlightPeriod3,
  setHighlightPeriod4,
  setRangeDate0,
  setRangeDate1,
  setRangeDate2,
  setRangeDate3,
  setRangeDate4,
  setStyle0,
  setStyle1,
  setStyle2,
  setStyle3,
  setStyle4,
  setVariableType0,
  setVariableType1,
  setVariableType2,
  setVariableType3,
  setVariableType4,
} from 'src/app/reducers/modules/plots.reducer';

export const PlotsOptions = (props: { plotVariable: number; variableTypeOptions?: any[]; dateFormatOptions?: any[]; addPlotToCompare: any }) => {
  const { t } = useTranslation();
  const variableType = useAppSelector(state => state.plots['variableType' + props.plotVariable]);
  const dateFormat = useAppSelector(state => state.plots['dateFormat' + props.plotVariable]);
  const style = useAppSelector(state => state.plots['style' + props.plotVariable]);
  const highlightPeriod = useAppSelector(state => state.plots['highlightPeriod' + props.plotVariable]);
  const dispatch = useAppDispatch();
  const divs = (props.variableTypeOptions ? 2 : 0) + (props.dateFormatOptions ? 1 : 0);

  const setVariableType = e => {
    switch (props.plotVariable) {
      case PLOT_ENERGY:
        dispatch(setVariableType0(e.value));
        break;
      case PLOT_ELECTRICAL:
        dispatch(setVariableType1(e.value));
        break;
      case PLOT_PV_INSTALLATION:
        dispatch(setVariableType2(e.value));
        break;
      case PLOT_PV_ENERGY:
        dispatch(setVariableType3(e.value));
        break;
      case PLOT_PV_ELECTRICAL:
        dispatch(setVariableType4(e.value));
        break;
    }
  };

  const setDateFormat = e => {
    switch (props.plotVariable) {
      case PLOT_ENERGY:
        dispatch(setDateFormat0(e.value));
        break;
      case PLOT_ELECTRICAL:
        dispatch(setDateFormat1(e.value));
        break;
      case PLOT_PV_INSTALLATION:
        dispatch(setDateFormat2(e.value));
        break;
      case PLOT_PV_ENERGY:
        dispatch(setDateFormat3(e.value));
        break;
      case PLOT_PV_ELECTRICAL:
        dispatch(setDateFormat4(e.value));
        break;
    }
  };

  const setStyle = e => {
    switch (props.plotVariable) {
      case PLOT_ENERGY:
        dispatch(setStyle0(e.value ? 'bar' : 'lines'));
        break;
      case PLOT_ELECTRICAL:
        dispatch(setStyle1(e.value ? 'bar' : 'lines'));
        break;
      case PLOT_PV_INSTALLATION:
        dispatch(setStyle2(e.value ? 'bar' : 'lines'));
        break;
      case PLOT_PV_ENERGY:
        dispatch(setStyle3(e.value ? 'bar' : 'lines'));
        break;
      case PLOT_PV_ELECTRICAL:
        dispatch(setStyle4(e.value ? 'bar' : 'lines'));
        break;
    }
  };

  const setHighlightPeriod = e => {
    switch (props.plotVariable) {
      case PLOT_ENERGY:
        dispatch(setHighlightPeriod0(e.value));
        break;
      case PLOT_ELECTRICAL:
        dispatch(setHighlightPeriod1(e.value));
        break;
      case PLOT_PV_INSTALLATION:
        dispatch(setHighlightPeriod2(e.value));
        break;
      case PLOT_PV_ENERGY:
        dispatch(setHighlightPeriod3(e.value));
        break;
      case PLOT_PV_ELECTRICAL:
        dispatch(setHighlightPeriod4(e.value));
        break;
    }
  };

  return (
    <div className="flex">
      <div className="flex-initial flex align-items-center justify-content-center font-bold text-white border-round">
        <ToggleButton onLabel="Barras" offLabel="Líneas" checked={style === 'bar'} onChange={setStyle} style={{ marginRight: 10 }} onIcon="pi pi-chart-bar" offIcon="pi pi-chart-line" />
      </div>
      <div className="flex-initial flex align-items-center justify-content-center font-bold text-white border-round">
        <ToggleButton onLabel="Con Periodos" offLabel="Sin Peridos" checked={highlightPeriod} onChange={setHighlightPeriod} style={{ marginRight: 10 }} onIcon="pi pi-star-fill" offIcon="pi pi-star" />
      </div>
      <div className="flex-initial flex align-items-center justify-content-center font-bold text-white m-2 border-round">
        <Dropdown style={{ width: '100%' }} value={variableType} options={props.variableTypeOptions} onChange={setVariableType} />
      </div>
      {props.dateFormatOptions.length > 0 && (
        <div className="flex-initial flex align-items-center justify-content-center font-bold text-white m-2 border-round">
          <Dropdown style={{ width: '100%' }} value={dateFormat} options={props.dateFormatOptions} onChange={setDateFormat} />
        </div>
      )}
      <div className="flex-initial flex align-items-center justify-content-center font-bold text-white m-2 border-round">
        <Button label="Comparar" onClick={props.addPlotToCompare} />
      </div>
    </div>
  );
};

export const PlotsDateRange = (props: { plotVariable: number }) => {
  const { t } = useTranslation();
  const rangeDate = useAppSelector(state => state.plots['rangeDate' + props.plotVariable]);

  const dispatch = useAppDispatch();
  const calendarRef = useRef(null);
  const classDistribution = 'col-12 lg:col-6';

  const setRangeDate = e => {
    const rDate = e.value.map(e => (dayjs(e).isValid() ? dayjs(e).toISOString() : ''));
    switch (props.plotVariable) {
      case PLOT_ENERGY:
        dispatch(setRangeDate0(rDate));
        break;
      case PLOT_ELECTRICAL:
        dispatch(setRangeDate1(rDate));
        break;
      case PLOT_PV_INSTALLATION:
        dispatch(setRangeDate2(rDate));
        break;
      case PLOT_PV_ENERGY:
        dispatch(setRangeDate3(rDate));
        break;
      case PLOT_PV_ELECTRICAL:
        dispatch(setRangeDate4(rDate));
        break;
    }
  };

  return (
    <div className="grid">
      <div className={classDistribution}>
        <Calendar
          ref={calendarRef}
          value={rangeDate.map(e => (dayjs(e).isValid() ? dayjs(e).toDate() : null))}
          numberOfMonths={1}
          onChange={setRangeDate}
          selectionMode="range"
          readOnlyInput
          placeholder={t('electricSgmPlots:selectDate')}
          inputStyle={{ width: 250 }}
          appendTo={document.body}
          dateFormat="dd/mm/yy"
          headerTemplate={() => (
            <Button
              label="Cerrar"
              onClick={() => {
                calendarRef.current.onButtonClick();
              }}
            />
          )}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};
