import React, { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from './shared/auth/private-route';
// import { AUTHORITIES } from './config/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from './app/hooks';
import { AUTHORITIES } from 'src/app/config/constants';
import { useTranslation } from 'react-i18next';
export interface IAppSubmenuProps {
  className?: string;
  items: any;
  onMenuItemClick(e: { originalEvent: Event; item: any }): void;
  root?: boolean;
}

const AppSubmenu = (props: IAppSubmenuProps) => {
  const [activeIndex, setActiveIndex] = useState<React.Key>();

  const onMenuItemClick = (event: any, item: any, index: React.Key) => {
    if (item.disabled) {
      event.preventDefault();
      return true;
    }
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    if (item.items || !item.url) {
      event.preventDefault();
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item,
      });
    }
  };

  const items =
    props.items &&
    props.items.map((item, i) => {
      const active = activeIndex === i;
      const styleClass = classNames({ 'active-menuitem': active });
      const submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;

      return (
        <li className={styleClass} key={i} style={{ display: item.display }}>
          {item.items && props.root === true && <div className="arrow"></div>}
          <a href={item.url} onClick={e => onMenuItemClick(e, item, i)} target={item.target}>
            {item.icon ? <i className={item.icon}></i> : <FontAwesomeIcon icon={item.ficon} />}
            <span style={{ fontSize: '1rem' }}>{item.label}</span>
            {submenuIcon}
          </a>
          <AppSubmenu items={item.items as Array<any>} onMenuItemClick={props.onMenuItemClick} />
        </li>
      );
    });
  return items ? <ul className={props.className}>{items}</ul> : null;
};

export const AppMenu = (props: { onMenuItemClick(e: { originalEvent: Event; item: any }): void }) => {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isCAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CADMIN]));
  const isMaintainer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.MAINTAINER]));
  const isCommercial = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.COMMERCIAL]));
  const isCVE = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CVE]));
  const isCVEPV = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CVEPV]));
  const isCVEW = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CVEW]));
  const isCBX = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CBX]));
  const isPremiun = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.PREMIUM]));
  const isPro = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.PRO]));
  const navigate = useNavigate();
  // const { history } = useReactRouter();
  const menu = [
    {
      label: 'Super Admin',
      icon: 'pi pi-bars',
      items: [
        {
          label: 'swagger',
          icon: 'pi pi-th-large',
          command: () => navigate('/admin/docs'),
        },
        {
          label: t('menu:setting'),
          icon: 'pi pi-cog',
          command: () => navigate('/app-setting_'),
          display: isAdmin || isCAdmin ? 'block' : 'none',
        },
      ],
      display: isAdmin ? 'block' : 'none',
    },
    {
      label: 'Dashboard',
      icon: 'pi pi-th-large',
      command: () => navigate('/'),
    },
    {
      label: t('menu:management'),
      icon: 'pi pi-th-large',
      items: [
        {
          label: t('menu:plantManagement'),
          icon: 'pi pi-list',
          command: () => navigate('/plant-management_'),
        },
        {
          label: t('menu:deviceManagement'),
          icon: 'pi pi-list',
          command: () => navigate('/device-management_'),
        },
        {
          label: t('menu:periodManagement'),
          icon: 'pi pi-list',
          command: () => navigate('/conv-electric-period_'),
          display: isAdmin || isCAdmin ? 'block' : 'none',
        },
        {
          label: t('menu:tariffManagement'),
          icon: 'pi pi-list',
          command: () => navigate('/tariff_'),
        },
      ],
      display: !isCommercial ? 'block' : 'none',
    },
    {
      label: t('menu:deviceControl'),
      icon: 'pi pi-list',
      command: () => navigate('/device-control_'),
      display: isAdmin || isCVE ? 'block' : 'none',
    },
    {
      label: t('menu:plots'),
      icon: 'pi pi-chart-line',
      command: () => navigate('/plots_'),
    },
    {
      label: t('menu:plotsComparative'),
      icon: 'pi pi-chart-line',
      command: () => navigate('/plots-comparative_'),
    },
    {
      label: t('menu:instantData'),
      icon: 'pi pi-clock',
      command: () => navigate('/simple-data_'),
      display: isAdmin || isCVE ? 'block' : 'none',
    },
    {
      label: t('menu:alarms'),
      icon: 'pi pi-bell',
      items: [
        {
          label: t('menu:alarmManagement'),
          icon: 'pi pi-bell',
          command: () => navigate('/alarm-config_'),
        },
        {
          label: t('menu:alarmLogs'),
          icon: 'pi pi-bell',
          command: () => navigate('/alarm-log_'),
        },
      ],
      display: isAdmin || isPro || isPremiun ? 'block' : 'none',
    },
    {
      label: t('menu:reports'),
      icon: 'pi pi-print',
      items: [
        {
          label: t('menu:costReports'),
          icon: 'pi pi-money-bill',
          command: () => navigate('/cost_reports_'),
        },
        // {
        //   label: t('menu:alarmLogs'),
        //   icon: 'pi pi-bell',
        //   command: () => navigate('/alarm-log_'),
        // },
      ],
    },
  ];

  return (
    <div className="menu">
      <AppSubmenu items={menu} className="layout-main-menu" onMenuItemClick={props.onMenuItemClick} root={true} />
    </div>
  );
};

// export default AppMenu;
