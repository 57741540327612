import useSWR from 'swr';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import { TariffComponent } from 'src/shared/components/tariff/tariff';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { updateEntity as updateElectricTariff } from 'src/app/reducers/entities/electric-tariff.reducer';

export const ConvElectricTariff = () => {
  const dispatch = useAppDispatch();
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const url = plant.id && 'api/electric-tariffs?plantId=' + plant.id;
  const { data: convElectricTariff } = useSWR<any>(url, swrFetcher);

  const saveElectricTariff = value => {
    dispatch(updateElectricTariff(value));
  };

  return convElectricTariff && <TariffComponent convElectricTariff={convElectricTariff} saveConvElectricTariff={saveElectricTariff} />;
};
