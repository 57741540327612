import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'src/app/reducers/utils/reducer.utils';
import { cleanEntity } from 'src/shared/utils/entity-utils';
import { IPlant, defaultValue } from 'src/shared/model/plant.model';

const initialState: EntityState<IPlant> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/plants';

// Actions

export const createEntity = createAsyncThunk(
  'convElectricPlant/create_entity',
  async (entity: IPlant) => {
    return await axios.post<IPlant>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'convElectricPlant/update_entity',
  async (entity: IPlant) => {
    return await axios.put<IPlant>(`${apiUrl}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntities = createAsyncThunk(
  'convElectricPlant/delete_entities',
  async (ids: string[]) => {
    return await axios.delete<IPlant>(`${apiUrl}-by-ids?ids=${ids.join('&ids=')}`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const convElectricPlantSlice = createEntitySlice({
  name: 'convElectricPlant',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntities.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, deleteEntities), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = convElectricPlantSlice.actions;

// Reducer
export default convElectricPlantSlice.reducer;
