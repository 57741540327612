import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { exportExcel } from './datatable-utils';
import { Button } from 'primereact/button';

export const DatatableRDV1 = (props: {
  height?: any;
  scrollHeigh?: any;
  columns: any[];
  data: any;
  fieldTemplate: any;
  exportFormatter?: any;
  sortField?: any;
  sortOrder?: any;
  autoLayout?: any;
  rows: number;
  createButtonLabel?: any;
  objectOptions?: any;
  importerUrl?: any;
  importerLabel?: any;
  customDialog?: any;
  onSelect?: any;
  rowExpansionTemplate?: any;
  export?: boolean;
  loadingBar?: any;
  frozenWidth?: any;
  paginator?: any;
}) => {
  const { t } = useTranslation();

  const onExportExcel = () => {
    exportExcel({
      exportFormatter: props.exportFormatter,
      columns: props.columns,
      data: props.data,
    });
  };
  const header = (
    <div className="grid align-center">
      <div className="lg:col-8 col-12 " style={{ textAlign: 'left' }}></div>
      <div className="lg:col-4 col-12" style={{ textAlign: 'right' }}>
        <Button type="button" icon="pi pi-file-excel" onClick={onExportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
      </div>
    </div>
  );
  
  return (
    <div>
      <DataTable
        header={header}
        style={{ marginTop: 5 }}
        value={props.data || []}
        scrollable
        scrollHeight={props.scrollHeigh ? props.scrollHeigh : '100%'}
        paginator={props.paginator}
        rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 1000]}
        rows={props.rows}
        sortField={props.sortField}
        sortOrder={props.sortOrder ? props.sortOrder : 1}
        // dataKey="id"
        removableSort
        rowExpansionTemplate={props.rowExpansionTemplate}
        className="p-datatable-gridlines"
        stripedRows
        frozenWidth={props.frozenWidth}
      >
        {props.columns &&
          props.columns.map((col, i) => {
            return (
              <Column
                body={props.fieldTemplate}
                key={col + i}
                field={col.field}
                sortable={col.sortable}
                header={<div style={{ textAlign: 'center' }}>{col.header}</div>}
                style={{ minWidth: col.width }}
                filter={col.filter}
                filterMatchMode="in"
                frozen={col.frozen}
              />
            );
          })}
      </DataTable>
    </div>
  );
};
