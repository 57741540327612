import dayjs from 'dayjs';
import { APP_LOCAL_DATETIME_FORMAT } from 'src/app/config/constants';

// import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const timeStampFormat = (timeStamp?: any, timeZone?: any, format?: any) => (timeStamp ? dayjs.unix(timeStamp).format(format ? format : 'DD-MM-YYYY HH:mm') : '--/--/---- --:--');

export const dateTimeFormat = (dateTime?: any, timeZone?: any, format?: any) => (dateTime ? dayjs(dateTime).format(format ? format : 'DD-MM-YYYY HH:mm') : '--/--/---- --:--');

export const dateTimeFormatShot = (dateTime?: any, timeZone?: any, format?: any) => (dateTime ? dayjs(dateTime).format(format ? format : 'DD-MM-YY HH:mm') : '--/--/---- --:--');

export const dateTimeTz = (dateTime?: any, timeZone?: any) => dayjs(dateTime);
// .tz(timeZone ? timeZone : 'Europe/Madrid');

export const momentUtcToDate = (dateTime?: any, timeZone?: any, format?: any) =>
  dateTime
    ? dayjs(dateTime.toString() + 'Z')
        // .tz(timeZone ? timeZone : 'Europe/Madrid')
        .toDate()
    : Date.now();
