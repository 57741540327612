import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { ColorPicker } from 'primereact/colorpicker';
import { InputNumber } from 'primereact/inputnumber';

import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { Password } from 'primereact/password';
import { MultiSelect } from 'primereact/multiselect';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

export const PredefinedTemplate = () => {};

export const CheckboxV2 = props => {
  const handleCheckedChange = (event: any) => {
    const { checked } = event;
    props.setEntity({ ...props.entity, [props.field]: checked });
  };
  return (
    <div>
      <h6>{props.label}</h6>
      <div>
        <Checkbox checked={props.entity[props.field]} onChange={handleCheckedChange} />
      </div>
    </div>
  );
};

export const HKInputNumber = (props: { form; name; label; required?; validate?; minFractionDigits?; useGrouping?; suffix?; className? }) => {
  const errors = props.form.formState.errors;
  const getFormErrorMessage = name => {
    return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };
  return (
    <Controller
      name={props.name}
      control={props.form.control}
      rules={{
        required: props.required || 'Campo Requerido',
        validate: props.validate,
      }}
      render={({ field, fieldState }) => (
        <div className={props.className}>
          <div className="flex flex-column gap-2">
            <label htmlFor={field.name}>{props.label}</label>
            <InputNumber
              id={field.name}
              inputRef={field.ref}
              value={field.value}
              onBlur={field.onBlur}
              onValueChange={e => field.onChange(e)}
              useGrouping={props.useGrouping}
              minFractionDigits={props.minFractionDigits || 0}
              inputClassName={classNames({ 'p-invalid': fieldState.error })}
              suffix={props.suffix}
            />
            {getFormErrorMessage(field.name)}
          </div>
        </div>
      )}
    />
  );
};

export const InputNumberStrV2 = props => {
  const handleInputChange = (event: any) => {
    const { value } = event;
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <span style={{ fontSize: '1em' }}>{props.label}</span>
      <div>
        <InputNumber
          style={{ marginRight: 5 }}
          value={parseFloat(props.entity[props.field])}
          onChange={handleInputChange}
          minFractionDigits={props.minFractionDigits}
          showButtons={props.showButtons}
          step={props.step}
          suffix={props.suffix}
          locale="es-ES"
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export const InputNumberV2 = props => {
  const handleInputChange = (event: any) => {
    const { value } = event;
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <span style={{ fontSize: '1em' }}>{props.label}</span>
      <div>
        <InputNumber
          style={{ marginRight: 5 }}
          value={props.entity[props.field]}
          onChange={handleInputChange}
          minFractionDigits={props.minFractionDigits}
          showButtons={props.showButtons}
          step={props.step}
          suffix={props.suffix}
          mode="decimal"
          locale="es-ES"
        />
      </div>
    </div>
  );
};

export const InputTextV2 = props => {
  const handleInputChange = (event: any) => {
    const { value } = event.currentTarget;
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <label>{props.label}</label>
      <div>
        <InputText value={props.staticText ? props.staticText : props.entity[props.field] ? props.entity[props.field] : ''} onChange={handleInputChange} disabled={props.disabled} />
      </div>
    </div>
  );
};

export const InputTextStringArrayV2 = props => {
  const handleInputChange = (event: any) => {
    const { value } = event.currentTarget;
    props.setEntity({ ...props.entity, [props.field]: value.split(',') });
  };
  return (
    <div>
      <label>{props.label}</label>
      <div>
        <InputText value={props.staticText ? props.staticText : props.entity[props.field] ? props.entity[props.field] : ''} onChange={handleInputChange} disabled={props.disabled} />
      </div>
    </div>
  );
};

export const PasswordV2 = props => {
  const handleInputChange = (event: any) => {
    const { value } = event.currentTarget;
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <label>{props.label}</label>
      <div>
        <Password promptLabel={'Ingrese la contraseña'} value={props.entity[props.field] ? props.entity[props.field] : ''} onChange={handleInputChange} disabled={props.disabled} />
      </div>
    </div>
  );
};

export const CalendarV2 = props => {
  const [dateValue, setDateValue] = useState();
  const handleInputChange = (event: any) => {
    const { value } = event.value;
    setDateValue(value);
    props.setEntity({ ...props.entity, [props.field]: dayjs(value) });
  };
  return (
    <div>
      <label>{props.label}</label>
      <div>
        <Calendar value={dateValue} onChange={handleInputChange} appendTo={document.body} dateFormat={'yy-mm-dd'} />
      </div>
    </div>
  );
};

export const StringDropdownV2 = (props: { options: any[]; entity: any; setEntity: any; field: any; label: any; onChange?: any; itemTemplate?: any; valueTemplate?: any }) => {
  const handleInputChange = (event: any) => {
    const { value } = event;
    props.onChange && props.onChange(value);
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <label>{props.label}</label>
      <div>
        <Dropdown
          showClear
          filter
          value={props.entity[props.field]}
          options={props.options}
          onChange={handleInputChange}
          appendTo={document.body}
          valueTemplate={props.valueTemplate}
          itemTemplate={props.itemTemplate}
          emptyMessage={'No records Found'}
        />
      </div>
    </div>
  );
};

export const DropdownV2 = (props: { options: any[]; entity: any; setEntity: any; field: any; label: any; onChange?: any; itemTemplate?: any; valueTemplate?: any }) => {
  const handleInputChange = (event: any) => {
    const { value } = event;
    props.onChange && props.onChange(value);
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <label>{props.label}</label>
      {props.options && (
        <div>
          <Dropdown
            showClear
            filter
            value={props.entity[props.field] && props.options.find(res => res.value.id === props.entity[props.field].id).value}
            options={props.options}
            onChange={handleInputChange}
            appendTo={document.body}
            valueTemplate={props.valueTemplate}
            itemTemplate={props.itemTemplate}
            emptyMessage={'No records Found'}
          />
        </div>
      )}
    </div>
  );
};

export const MultiSelectV2 = (props: { options: any; entity: any; setEntity: any; field: any; label: any; onChange?: any; selectedItemTemplate?: any }) => {
  const handleInputChange = (event: any) => {
    const { value } = event;
    props.onChange && props.onChange(value);
    props.setEntity({ ...props.entity, [props.field]: value });
  };
  return (
    <div>
      <label>{props.label}</label>
      {props.options && props.options.length > 0 && (
        <div>
          <MultiSelect
            showClear
            filter
            value={props.entity[props.field]}
            options={props.options}
            onChange={handleInputChange}
            appendTo={document.body}
            selectedItemTemplate={props.selectedItemTemplate}
          />
        </div>
      )}
    </div>
  );
};

export const ColorPickerV2 = props => {
  const ref = useRef(null);

  const handleInputTextChange = (event: any) => {
    const { value } = event.currentTarget;
    props.setEntity({ ...props.entity, [props.field]: value });
    // if (value !== null && value !== undefined) {
    //   ref.current.updateHSBValue(value);
    //   ref.current.updateUI();
    // }
  };
  const handleColorPickertChange = (event: any) => {
    const { value } = event;
    props.setEntity({ ...props.entity, [props.field]: '#' + value });
  };

  return (
    <div>
      <h5>{props.label}</h5>
      <div>
        <InputText style={{ marginRight: 5 }} value={props.entity[props.field]} onChange={handleInputTextChange} />
        <ColorPicker ref={ref} value={props.entity[props.field]} onChange={handleColorPickertChange} />
      </div>
    </div>
  );
};

// export const FieldSelector = () => {
//   return <div>

//   </div>
//   if (col.type === 'String' && col.field !== 'id' && (!props.updateEntity || col.editable)) {
//     return (
//       <div key={col.field} className="p-field">
//         <InputTextV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//         />
//       </div>
//     );
//   }
//   if (col.type === 'StringUUID' && col.field !== 'id' && (!props.updateEntity || col.editable)) {
//     return (
//       <div key={col.field} className="p-field">
//         <InputTextV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//           staticText={uuid()}
//         />
//       </div>
//     );
//   }
//   if (col.type === 'Object' && col.field !== 'id' && (!props.updateEntity || col.editable)) {
//     const options = props.options[col.field];
//     return (
//       <div key={col.field} className="p-field">
//         <DropdownV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//           options={props.options ? options : []}
//         />
//       </div>
//     );
//   }
//   if (col.type === 'StringDropdown' && col.field !== 'id' && (!props.updateEntity || col.editable)) {
//     const options = props.options[col.field];
//     return (
//       <div key={col.field} className="p-field">
//         <StringDropdownV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//           options={props.options ? options : []}
//         />
//       </div>
//     );
//   }
//   if (col.type === 'MultiSelect' && col.field !== 'id' && (!props.updateEntity || col.editable)) {
//     const options = props.options[col.field];
//     return (
//       <div key={col.field} className="p-field">
//         <MultiSelectV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//           options={props.options ? options : []}
//         />
//       </div>
//     );
//   }

//   if (col.type === 'StringArray' && col.field !== 'id' && (!props.updateEntity || col.editable)) {
//     const options = props.options[col.field];
//     return (
//       <div key={col.field} className="p-field">
//         <InputTextStringArrayV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//         />
//       </div>
//     );
//   }

//   if (col.type === 'Date' && col.field !== 'id' && (!updateEntity || col.editable)) {
//     return (
//       <div key={col.field} className="p-field">
//         <CalendarV2
//           label={props.headerTranslationDomain ? t(props.headerTranslationDomain + col.field) : col.field}
//           entity={entity}
//           field={col.field}
//           setEntity={setEntity}
//         />
//       </div>
//     );
//   }
// };
