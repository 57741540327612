import { Panel } from 'primereact/panel';
import _ from 'lodash';
import { Divider } from 'primereact/divider';
import { LineGraph } from 'src/shared/components/plots/LineGraph';
import { dateTimeFormat, timeStampFormat } from 'src/shared/utils/date-utils';
import { decimalFormat } from 'src/shared/utils/number-utils';
import { DatatableRDV1 } from 'src/shared/components/general/DatatableRDV1';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import i18next from 'i18next';

const exportFormatter = (field: any, data: any) => {
  if (data) {
    if (field === 'timeStamp') {
      return timeStampFormat(data.timeStamp, 'Europe/Madrid');
    } else if (field === 'pvData') {
      return decimalFormat(data.pvData ? data.pvData : 0, 3) + ' kW';
    } else if (field === 'gridData') {
      return decimalFormat(data.gridData ? data.gridData : 0, 3) + ' kW';
    } else if (field === 'installation') {
      return decimalFormat(data.installation ? data.installation : 0, 3) + ' kW';
    }
  } else {
    return <div></div>;
  }
};

export const ConvElectricPvAllPlotDonutGraph = (props: { data: any; centerText: any }) => {
  const currentLocale = 'es';
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({
    datarevision: 0,
    annotations: [
      {
        font: {
          size: 20,
        },
        showarrow: false,
        textinfo: 'label+percent',
        text: '',
        x: 0.5,
        y: 0.5,
      },
    ],

    margin: { t: 10, b: 50, l: 10, r: 0 },
    autosize: true,
    showlegend: true,
    legend: {
      orientation: 'h',
      font: {
        size: 14,
      },
    },
  });

  useEffect(() => {
    if (props.data) {
      setLayout(layoutT => ({
        ...layoutT,
        annotations: [
          {
            font: {
              size: 14,
            },
            showarrow: false,
            textinfo: 'label+percent',
            textposition: 'outside',
            text: props.centerText,
            x: 0.5,
            y: 0.5,
          },
        ],
        datarevision: layout.datarevision + 1,
        showlegend: true,
        legend: {
          orientation: 'h',
          font: {
            size: 14,
          },
        },
      }));
      setData([props.data]);
    }
  }, [props.data]);

  return <Plot style={{ minHeight: '200px', height: '600px' }} data={data} layout={layout} config={{ responsive: true, locale: currentLocale, displaylogo: false }} graphDiv="graph" />;
};

export const ConvElectricPvAllTable = (props: { data: any[]; dataType: any; type: any; branch: any }) => {
  const t = i18next.t;
  const columns = [
    { id: 1, field: 'timeStamp', header: t('electricSgmPlots:timeStamp'), type: 'String', width: 150, editable: true },
    { id: 3, field: 'pvData', header: t('electricSgmPlots:pvData'), type: 'String', width: 100, editable: true },
    { id: 2, field: 'gridData', header: t('electricSgmPlots:gridData'), type: 'String', width: 150, editable: true },
    { id: 4, field: 'installation', header: t('electricSgmPlots:installation'), type: 'String', width: 200, editable: true },
  ];

  const fieldTemplate = (rowData: any, column: any) => {
    if (column['field'] === 'timeStamp') {
      return timeStampFormat(rowData[column['field']], 'Europe/Madrid');
    } else if (column['field'].includes('pvData')) {
      const value = rowData && rowData.pvData;
      return <div style={{ textAlign: 'right' }}>{decimalFormat(value ? value : 0, 3) + ' kW'}</div>;
    } else if (column['field'].includes('gridData')) {
      const value = rowData && rowData.gridData;
      return <div style={{ textAlign: 'right' }}>{decimalFormat(value ? value : 0, 3) + ' kW'}</div>;
    } else if (column['field'].includes('installation')) {
      const value = rowData && rowData['installation'];
      return <div style={{ textAlign: 'right' }}>{decimalFormat(value ? value : 0, 3) + ' kW'}</div>;
    } else {
      return <span></span>;
    }
  };

  return (
    props.data.length > 0 && (
      <DatatableRDV1
        scrollHeigh="calc( 100vh - 300px )"
        columns={columns}
        data={props.data}
        objectOptions={{}}
        fieldTemplate={fieldTemplate}
        exportFormatter={exportFormatter}
        rows={50}
        sortField={'timeStamp'}
        sortOrder={-1}
      />
    )
  );
};

export const ConvElectricPvCombinedPlot = (props: { dataTable: any; dataLine: any; dounutData: any; dateFormat: string; dataType: string; showDounut: boolean; suffix: any; branch?: number }) => {
  return (
    <>
      {props.dataTable && (
        <div className="grid p-panel" style={{ padding: 0, height: '100%' }}>
          <div className={'col-12 ' + (props.showDounut && 'lg:col-8')} style={{ textAlign: 'center' }}>
            <Panel header={'Gestión de Potencia del Día'}>
              {props.dataLine && <LineGraph rangeSlider={true} height={'600px'} data={props.dataLine} suffix={props.suffix} dateFormat={props.dateFormat} yAxisRange={0} />}
            </Panel>
          </div>
          <div className={'col-12 lg:col-4'} style={{ textAlign: 'center', display: props.showDounut ? 'block' : 'none' }}>
            <Panel header={'Consumo de Energía de la Instalación'}>
              <ConvElectricPvAllPlotDonutGraph centerText={props.dounutData && props.dounutData.centerText} data={props.dounutData} />
            </Panel>
          </div>
          <Divider />
          <div className="col-12" style={{ paddingTop: 15, textAlign: 'center' }}>
            <ConvElectricPvAllTable type="energyConsumption" dataType={props.dataType === '€' ? ' € ' : props.suffix} data={props.dataTable ? props.dataTable : []} branch={props.branch} />
          </div>
        </div>
      )}
    </>
  );
};
