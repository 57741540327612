import { IDevice } from "./device.model";

export interface IPlant {
  id?: number;
  name?: string;
  type?: number;
  status?: string;
  location?: string;
  latitude?: string;
  longitude?: string;
  appUser?: string;
  commercialUser?: string[];
  maintainerUser?: string;
  timeZone?: string;
  devices?: IDevice[];
}

export const defaultValue: Readonly<IPlant> = {};
