import { createSlice } from '@reduxjs/toolkit';

export const navigationMenuSlice = createSlice({
  name: 'loadingBar',
  initialState: { display: false },
  reducers: {
    setShow(state, action) {
      state.display = action.payload;
    },
  },
});

export const { setShow } = navigationMenuSlice.actions;
export default navigationMenuSlice.reducer;
