// import { IConvElectricCustomTariff } from './conv-electric-tariff.model';
import { IUser } from './user.model';
import { IElectricData } from './electric-data.model';

export interface IDevice {
  id?: number;
  macId?: string;
  name?: string;
  index?: number;
  modbusStatus?: string;
  dateTime?: string;
  readerType?: number;
  lastDataReceived?: string;
  lastDataSampleReceived?: string;
  samplingTime?: number;
  description?: string;
  groupId?: string;
  csq?: number;
  status?: string;
  location?: string;
  latitude?: string;
  longitude?: string;
  timeZone?: string;
  control?: boolean;
  available?: boolean;
  voltageLines?: number;
  power?: number;
  maxVoltage?: number;
  maxCurrent?: number;
  energyKpiDay?: number;
  energyKpiMonth?: number;
  energyKpiYear?: number;
  switch1?: number;
  model?: string;
  cups?: string;
  alarmCount?: number;
  alarmtSent?: boolean;
  hasCurrentConsumption?: boolean;
  convElectricTariff?: string;
  convElectricData?: IElectricData;
  epILastDay?: number;
  epETCurrentDay?: number;
  epITCurrentDay?: number;
  epITCostCurrentDay?: number;
  appUser?: IUser;
  commercialUser?: IUser;
  maintainerUser?: IUser;
  reactive?: Boolean;
}

export const defaultValue: Readonly<IDevice> = {};
