import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { ConvElectricPlotComparative } from './conv-electric-plot-comparative';
import { useAppSelector } from 'src/app/hooks';

export const PlotsComparative = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:plotsComparative'));
  const plots = JSON.parse(JSON.stringify(useAppSelector(state => state.plots.plots)));

  return (
    <div className="grid">
      {plots.map((lineData, index) => {
        return <ConvElectricPlotComparative lineData={lineData} index={index} plots={plots} />;
      })}
    </div>
  );
};
