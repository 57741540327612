import { useEffect, useState } from 'react';
import { createEntity as createDevice, updateEntity as updateDevice, updateConfig as updateDeviceConfig, deleteEntities as deleteDevices } from 'src/app/reducers/entities/device.reducer';
import { exportFormatter, fieldTemplate } from 'src/shared/components/datatable-templates/device-management-template';
import axios from 'axios';
import { AUTHORITIES } from 'src/app/config/constants';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { timeZones } from 'src/shared/utils/time-zones';
import { DatatableV1 } from 'src/shared/components/general/DatatableV1';
import { IPlant } from 'src/shared/model/plant.model';
import _ from 'lodash';
import { setToastMessage } from 'src/app/reducers/toast-message.reducer';
import { DeviceUpdateConfig as DeviceUpdateConfig } from 'src/shared/components/devices-management/device-management-config-dialog';

export const DeviceManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useNavigationMenuTitle(t('menu:deviceManagement'));
  const [visible, setVisible] = useState(false);
  const [entity, setEntity] = useState({});
  const updateSuccess = useAppSelector(state => state.device.updateSuccess);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isCAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CADMIN]));
  const isMaintainer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.MAINTAINER]));
  const role = isAdmin ? 'admin' : isCAdmin ? 'cadmin' : isMaintainer ? 'maintainer' : 'appuser';
  const row = 200;
  const columns = [
    { field: 'macId', header: '', type: 'String', width: 160, editable: true, filter: true, roles: ['admin'] },
    { field: 'name', header: '', type: 'String', width: 160, editable: true, filter: true, roles: ['appuser'] },
    { field: 'plantId', header: '', type: 'StringDropdown', width: 150, editable: true, filter: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'plantType', header: '', type: 'StringDropdown', width: 150, editable: false, filter: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'location', header: '', type: 'String', width: 190, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
    { field: 'csqFull', header: '', type: 'String', width: 300, editable: false, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'csq', header: '', type: 'String', width: 190, editable: false, roles: ['appuser'] },
    { field: 'slaves', header: '', type: 'StringDropdown', width: 150, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'notificationEmails', header: '', type: 'String', width: 190, editable: true, roles: ['appuser'] },
    { field: 'version', header: '', type: 'String', width: 70, editable: false, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'flash', header: '', type: 'String', width: 70, editable: false, roles: ['admin', 'cadmin', 'maintainer'] },
    { field: 'baudRate', header: '', type: 'StringDropdown', width: 70, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
  ]
    .filter(column => column.roles.includes(role))
    .map(column => ({ ...column, header: t('electricSgmDevice:' + column.field) }));

  const [objectOptions, setObjectOptions] = useState<any>({});

  const fetchOptions = async () => {
    const objOptions = {
      status: [
        { label: 'Instalado', value: 'installed' },
        { label: 'En Almacen', value: 'instock' },
        { label: 'Averiado', value: 'damaged' },
      ],
    };
    objOptions['timeZone'] = timeZones;
    objOptions['type'] = [
      { label: 'Contador Electrico/PV', value: 'convelectricpv' },
      { label: 'Contador Electrico', value: 'convelectric' },
      { label: 'Caja Combinada PV', value: 'combinerbox' },
    ];
    objOptions['readerType'] = [
      { label: 'Panel', value: 1 },
      { label: 'Red', value: 2 },
    ];
    objOptions['deviceType'] = [
      { label: 'Eléctrico', value: 0 },
      { label: 'Fotovoltáico', value: 1 },
      { label: 'ParqueSolar', value: 2 },
    ];
    objOptions['baudRate'] = [
      { label: '9600 bauds', value: 9600 },
      { label: '2400 bauds', value: 2400 },
    ];
    objOptions['voltageLines'] = [
      { label: 'Monofásica', value: 1 },
      { label: 'Trifásica', value: 3 },
    ];
    objOptions['slaves'] = [
      { label: '1 Contador', value: 1 },
      { label: '2 Contadores', value: 2 },
      { label: '3 Contadores', value: 3 },
    ];
    objOptions['control'] = [
      { label: 'Si', value: true },
      { label: 'No', value: false },
    ];
    objOptions['multiple'] = [
      { label: 'Si', value: true },
      { label: 'No', value: false },
    ];
    objOptions['electricTariff'] = [
      { label: '2.0TD', value: '2.0TD' },
      { label: '3.0TD', value: '3.0TD' },
    ];
    objOptions['reactive'] = [
      { label: 'Si', value: true },
      { label: 'No', value: false },
    ];
    const convElectricPlants = await axios.get<IPlant[]>(`/api/plants`);
    objOptions['plantId'] = (convElectricPlants.data || []).map(convElectricPlant => {
      return { label: convElectricPlant.name.toUpperCase(), type: convElectricPlant.type, value: convElectricPlant.id };
    });
    setObjectOptions(objOptions);
  };

  useEffect(() => {
    if (isAdmin || isCAdmin || isMaintainer) {
      fetchOptions();
    }
  }, []);

  const template = (entity: any, column: any) => {
    return fieldTemplate(entity, column, objectOptions);
  };

  const updateEntity = entity => {
    dispatch(updateDevice(entity));
  };

  const saveEntity = entity => {
    dispatch(createDevice(entity));
  };

  const deleteEntity = entities => {
    dispatch(deleteDevices(entities));
  };

  const updateConfig = entity => {
    if (entity.csq === 0) {
      dispatch(setToastMessage({ severity: 'Warning', summary: 'Dispositivo desconectado', detail: 'Es posible que las ordenes no se ejecuten correctamente' }));
    } else {
      dispatch(setToastMessage({ severity: 'success', summary: 'Ejecutando', detail: 'Espere al menos 1 minuto para verificar los cambios' }));
      dispatch(updateDeviceConfig(entity));
    }
  };

  const rowExpansionTemplate = row => {
    const rowExpansionColumns = [
      { field: 'macId', header: '', type: 'String', width: 160, editable: true, roles: ['admin'] },
      { field: 'plantId', header: '', type: 'StringDropdown', width: 150, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
      { field: 'name', header: '', type: 'String', width: 150, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
      { field: 'serial', header: '', type: 'String', width: 150, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
      { field: 'readerType', header: '', type: 'StringDropdown', width: 130, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
      { field: 'modbusStatus', header: '', type: 'String', width: 80, editable: false, roles: ['admin', 'cadmin', 'maintainer'] },
      { field: 'location', header: '', type: 'String', width: 200, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
      { field: 'voltageLines', header: '', type: 'StringDropdown', width: 200, editable: true, roles: ['admin', 'cadmin', 'maintainer'] },
      { field: 'electricTariff', header: '', type: 'StringDropdown', width: 50, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
      { field: 'notificationEmails', header: '', type: 'String', width: 500, editable: true, roles: ['admin', 'cadmin', 'maintainer', 'appuser'] },
    ]
      .filter(column => column.roles.includes(role))
      .map(column => ({ ...column, header: t('electricSgmDevice:' + column.field) }));

    return (
      <div>
        {row.data && row.data.length > 0 && (
          <DatatableV1
            scrollHeigh="calc( 100vh - 300px )"
            data={row.data.slice(0, row.slaves ? row.slaves : 2)}
            columns={rowExpansionColumns}
            objectOptions={objectOptions}
            fieldTemplate={template}
            exportFormatter={exportFormatter}
            rows={row}
            dataKey={'macId'}
            sortField={'macId'}
            selectionMode={isAdmin || isCAdmin ? 'multiple' : 'single'}
            saveEntityAction={isAdmin && saveEntity}
            updateEntityAction={updateEntity}
            deleteEntityAction={isAdmin && deleteEntity}
            mapData={mapData}
            updateSuccess={updateSuccess}
          />
        )}
      </div>
    );
  };

  const mapData = data => {
    const dataT = _(
      (data || [])
        .sort((a, b) => (a.macId > b.macId ? 1 : -1))
        .map(res => {
          return { ...res, mac: res.macId.split('_')[0] };
        })
    )
      .groupBy('mac')
      .map((objs, key) => {
        return {
          macId: key,
          id: _.head(objs)['id'],
          csq: _.head(objs)['csq'],
          version: _.head(objs)['version'],
          baudRate: _.head(objs)['baudRate'],
          flash: _.head(objs)['flash'],
          timeStamp: _.head(objs)['timeStamp'],
          slaves: _.head(objs)['slaves'],
          deviceType: _.head(objs)['deviceType'],
          plantId: _.head(objs)['plantId'],
          data: objs,
        };
      })
      .value();
    return dataT;
  };
  const updateCustomDialog = (visible, entity) => {
    if (entity.csq === 0) {
      dispatch(setToastMessage({ severity: 'error', summary: 'No Permitido', detail: 'El dispositivo debe estár conectado' }));
    } else {
      setVisible(true);
      setEntity(entity);
    }
  };

  return (
    <>
      <DeviceUpdateConfig onHide={setVisible} visible={visible} entity={entity} updateConfig={updateDeviceConfig} />
      <DatatableV1
        scrollHeigh="calc( 100vh - 300px )"
        columns={columns}
        objectOptions={objectOptions}
        fieldTemplate={template}
        exportFormatter={exportFormatter}
        rows={row}
        dataKey={'id'}
        sortField={'macId'}
        selectionMode={'checkbox'}
        getEntitiesUrl={'api/devices'}
        saveEntityAction={isAdmin && saveEntity}
        updateEntityAction={isAdmin || isCAdmin ? updateConfig : updateEntity}
        updateSuccess={updateSuccess}
        editButtonLabel={(isAdmin || isCAdmin) && 'Reconfigurar'}
        paginator={true}
        mapData={(isAdmin || isCAdmin) && mapData}
        updateCustomDialog={(isAdmin || isCAdmin) && updateCustomDialog}
        rowExpansionTemplate={(isAdmin || isCAdmin) && rowExpansionTemplate}
        refreshButton={true}
      />
    </>
  );
};
