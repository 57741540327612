import { useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { setNavigationMenuTitle } from '../reducers/app-menu.reducer';

export const useNavigationMenuTitle = title => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setNavigationMenuTitle(title));
  }, []);
};
