import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { KeycloakProvider } from './security/KeycloakProvider';
import setupAxiosInterceptors from './app/config/axios-interceptor';
import { clearAuthentication } from 'src/app/reducers/authentication';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { i18nES } from './shared/i18n/i18nLanguajes';
import { loadIcons } from './app/config/icon-loader';
import { bindActionCreators } from '@reduxjs/toolkit';
import getStore from './app/store';
import App from './App';
import { AppContext, AppProvider } from './app/contexts/AppContext';
const store = getStore();
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(actions.clearAuthentication);
loadIcons();

const container = document.getElementById('root')!;
const root = createRoot(container);

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'es', // language to use
  resources: {
    es: i18nES,
  },
  debug: process.env.NODE_ENV === 'development',
});

const appRender = () =>
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <AppProvider>
          <App />
        </AppProvider>
      </I18nextProvider>
    </Provider>
    // </React.StrictMode>
  );

appRender();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
