'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'wifi-slash';
var width = 640;
var height = 512;
var aliases = [];
var unicode = 'f1eb';
var svgPathData = 'M 54.1 202.9 C 41.4 215.2 21.1 214.8 8.9 202 C -3.3 189.2 -2.9 169 9.8 156.7 C 39.718 128.009 73.614 103.419 110.579 83.846 L 153.783 133.754 C 116.947 151.478 83.374 174.909 54.2 202.9 L 54.1 202.9 Z M 320 32 C 440.4 32 549.7 79.5 630.1 156.8 C 642.8 169 643.2 189.3 631 202 C 618.8 214.7 598.6 215.1 585.8 202.9 C 516.8 136.7 423.2 96 320 96 C 288.971 96 258.81 99.679 229.925 106.625 L 183.62 53.134 C 226.621 39.41 272.445 32 320 32 Z M 171.9 312 C 158.6 323.7 138.4 322.5 126.7 309.2 C 115 295.9 116.2 275.7 129.5 264 C 155.726 240.826 186.26 222.361 219.731 209.935 L 265.411 262.702 C 230.181 271.521 198.294 288.68 171.9 312 Z M 320 192 C 393 192 459.8 219.2 510.6 264 C 523.8 275.7 525.1 296 513.4 309.2 C 501.7 322.4 481.5 323.7 468.2 312 C 438.797 286.087 402.669 267.782 362.762 260.073 L 304.202 192.426 C 309.433 192.143 314.7 192 320 192 Z M 320 480 C 284.7 480 256 451.3 256 416 C 256 380.7 284.7 352 320 352 C 330.243 352 339.931 354.417 348.523 358.71 L 380.802 395.998 C 382.877 402.293 384 409.017 384 416 C 384 451.3 355.3 480 320 480 Z M 135.42 7.541 L 518.868 450.486 L 473.08 490.123 L 89.633 47.179 L 135.42 7.541 Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faWifiSlash = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;