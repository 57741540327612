export interface IElectricTariff {
  id?: string;
  name?: string;
  energyTerm1?: number;
  energyTerm2?: number;
  energyTerm3?: number;
  energyTerm4?: number;
  energyTerm5?: number;
  energyTerm6?: number;
  powerTerm1?: number;
  powerTerm2?: number;
  powerTerm3?: number;
  powerTerm4?: number;
  powerTerm5?: number;
  powerTerm6?: number;
}

export const defaultValue: Readonly<IElectricTariff> = {};
