import { AlarmType } from './enumerations/alarm-type.model';

export interface IElectricSgmAlarmLog {
  id?: string;
  devId?: string;
  type?: AlarmType;
  dateTime?: string;
  name?: string;
  alarm?: string;
  swStatus?: string;
  description?: string;
  variableType?: string;
  comparator?: string;
  instantValue?: number;
  compareValue?: number;
}

export const defaultValue: Readonly<IElectricSgmAlarmLog> = {};
