import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export const DeviceUpdateConfig = (props: { visible; onHide; entity; updateConfig }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deviceType, setDeviceType] = useState(0);
  const [baudRate, setBaudRate] = useState(0);
  const [slaves, setSlaves] = useState(0);
  const [reconfigured, setReconfigured] = useState(false);

  useEffect(() => {
    setDeviceType(props.entity.deviceType);
    setBaudRate(props.entity.baudRate);
    setSlaves(props.entity.slaves);
  }, [props.entity]);

  const deviceTypeOptions = [
    { label: 'Eléctrico', value: 0 },
    { label: 'Fotovoltáico', value: 1 },
    { label: 'ParqueSolar', value: 2 },
  ];
  const baudsOptions = [
    { label: '9600 bauds', value: 9600 },
    { label: '2400 bauds', value: 2400 },
  ];
  const slavesOptions = [
    { label: '1 Contador', value: 1 },
    { label: '2 Contadores', value: 2 },
    { label: '3 Contadores', value: 3 },
  ];

  const footerCreateDialog = (
    <>
      <Button label="Cerrar" icon="pi pi-times" outlined onClick={() => props.onHide(false)} />
      <Button
        label="Reiniciar Dispositivo"
        icon="pi pi-check"
        outlined
        severity="danger"
        disabled={!reconfigured}
        onClick={() => {
          dispatch(props.updateConfig({ macId: props.entity.macId, flash: 'restart' }));
          props.onHide(false);
        }}
      />
    </>
  );
  return (
    <Dialog
      visible={props.visible}
      style={{ width: '800px' }}
      header={'Configurar (' + props.entity.macId + ')'}
      modal
      className="p-fluid"
      onHide={() => props.onHide(false)}
      footer={footerCreateDialog}
    >
      <div className="grid">
        <div className="col-12">
          <div>
            <label>{t('electricSgmDevice:deviceType')}</label>
            <div className="mt-2">
              <Dropdown showClear filter value={deviceType} options={deviceTypeOptions} onChange={e => setDeviceType(e.value)} appendTo={document.body} />
              <Button
                className="mt-2"
                label="Aplicar Cambio"
                onClick={() => {
                  setReconfigured(true);
                  dispatch(props.updateConfig({ macId: props.entity.macId, deviceType }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="mt-2">
            <label>{t('electricSgmDevice:baudRate')}</label>
            <div>
              <Dropdown showClear filter value={baudRate} options={baudsOptions} onChange={e => setBaudRate(e.value)} appendTo={document.body} />
              <Button
                className="mt-2"
                label="Aplicar Cambio"
                onClick={() => {
                  setReconfigured(true);
                  dispatch(props.updateConfig({ macId: props.entity.macId, baudRate }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div>
            <label>{t('electricSgmDevice:slaves')}</label>
            <div className="mt-2">
              <Dropdown showClear filter value={slaves} options={slavesOptions} onChange={e => setSlaves(e.value)} appendTo={document.body} />
              <Button
                className="mt-2"
                label="Aplicar Cambio"
                onClick={() => {
                  setReconfigured(true);
                  dispatch(props.updateConfig({ macId: props.entity.macId, slaves }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          {reconfigured && (
            <div style={{ color: 'red', fontSize: '1.2rem' }}>
              Los cambios solo estarán disponible después de reiniciar el dispositivo. Haga click en reiniciar el dispositivo cuando termine de realizar todos los cambios.
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
