import { createContext, useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { setAccount, setOidcUrl, setSession } from '../reducers/authentication';
import { useAppDispatch, useAppSelector } from '../hooks';
import { hexToHSL, hexToRGB } from 'src/shared/utils/color-converter';
import { getEntity as getAppSetting } from 'src/app/reducers/app-setting.reducer';
import { primereactCustomLocale } from 'src/shared/utils/primereact-utils';
import dayjs from 'dayjs';
import { useIdleTimer } from 'react-idle-timer';

export const AppContext = createContext({ keycloak: null, initialized: false });

export const AppProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const [appData, setAppData] = useState({ keycloak: null, initialized: false });
  const appSetting = useAppSelector(state => state.appSettings.entity);

  useEffect(() => {
    if (appSetting && appSetting.appMainColor) {
      const mainColorRGB = hexToRGB(appSetting.appMainColor);
      const mainColor: any = hexToHSL(appSetting.appMainColor);
      document.title = appSetting.appName ? appSetting.appName : '';
      document.body.style.setProperty('--menu-font-color', appSetting.appMenuFontColor);
      document.body.style.setProperty('--menu-font-hover-color', appSetting.appMenuFontHoverColor);
      document.body.style.setProperty('--menu-active-bg-color', appSetting.appMenuActiveBgColor);
      document.body.style.setProperty('--menu-active-border-color', appSetting.appMenuActiveBorderColor);
      document.body.style.setProperty('--menu-bg-color', appSetting.appMenuBgColor);
      document.body.style.setProperty('--top-font-color', appSetting.appTopBarFontColor);
      document.body.style.setProperty('--rgb-main-color-0', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-1', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-2', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-3', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-4', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-5', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-l0', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--rgb-main-color-l1', 'rgb(' + mainColorRGB.r + ',' + mainColorRGB.g + ',' + mainColorRGB.b + ')');
      document.body.style.setProperty('--main-color-0', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%,' + (mainColor.l - 0) * 100 + '%)');
      document.body.style.setProperty('--main-color-1', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%,' + (mainColor.l - 5) * 100 + '%)');
      document.body.style.setProperty('--main-color-2', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%,' + (mainColor.l - 10) * 100 + '%)');
      document.body.style.setProperty('--main-color-3', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%,' + (mainColor.l - 15) * 100 + '%)');
      document.body.style.setProperty('--main-color-4', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%,' + (mainColor.l - 20) * 100 + '%)');
      document.body.style.setProperty('--main-color-5', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%,' + (mainColor.l - 25) * 100 + '%)');
      document.body.style.setProperty('--main-color-l0', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%, 85%)');
      document.body.style.setProperty('--main-color-l1', 'hsl(' + mainColor.h + ',' + mainColor.s * 100 + '%, 95%)');
      document.body.style.setProperty('--hover-color', appSetting.appMainHoverColor);
      document.body.style.setProperty('--device-color', appSetting.appDeviceColor);
      document.body.style.setProperty('--instant-gadget-bg-color', appSetting.instantGadgetBgColor);
      document.body.style.setProperty('--instant-gadget-bar-color', appSetting.instantGadgetBarColor);
      document.body.style.setProperty('--toast-success', appSetting.appToastSuccessColor);
      document.body.style.setProperty('--desktop-font-size', appSetting.appDesktopFontSize);
      document.body.style.setProperty('--mobile-font-size', appSetting.appMobileFontSize);
      document.body.style.setProperty('--pv-string-normal', '#068a48');
      document.body.style.setProperty('--pv-string-low', '#f95151');
      document.body.style.setProperty('--pv-string-high', '#1100ff');
      document.body.style.setProperty('--pv-string-zero', '#797979');
      setAppData(prevAppData => {
        return { ...prevAppData, initialized: true };
      });
    }
  }, [appSetting]);

  useEffect(() => {
    const init = async () => {
      try {
        const keycloakInfo = await (await fetch(`/api/keycloak/info.json`)).json();
        const kc = new Keycloak(keycloakInfo);
        kc.onTokenExpired = () => {
          kc.updateToken(5).then(res => {
            if (res) {
              setSession(kc.token);
            }
          });
        };
        const authenticated = await kc.init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          enableLogging: process.env.NODE_ENV === 'development',
        });
        if (authenticated) {
          setOidcUrl(keycloakInfo.url);
          const tempAccount: any = {};
          tempAccount.email = kc.idTokenParsed.email;
          tempAccount.authorities = kc.realmAccess.roles;
          tempAccount.firstName = kc.idTokenParsed.given_name;
          tempAccount.lastName = kc.tokenParsed.family_name;
          setSession(kc.token);
          dispatch(setAccount(tempAccount));
          primereactCustomLocale();
          dayjs.locale('es');
          // // setLocale(tempAccount.langKey);
          dispatch(getAppSetting());
          setAppData(prevAppData => {
            return { ...prevAppData, keycloak: kc };
          });
        }
      } catch (e) {

      }
    };
    init();
  }, []);
  
  const onIdle = () => {
    appData.keycloak.logout();
  };

  useIdleTimer({ onIdle, timeout: 1000 * 60 * 5, debounce: 250 });
  if (appData.initialized) {
    return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
  } else {
    <div></div>;
  }
};
