export interface IAppSetting {
    id?: string;
    mqttPrefix?: string;
    companyName?: string;
    companyNif?: string;
    companyAddress?: string;
    companyLatitude?: string;
    companyLongitude?: string;
    companyCity?: string;
    companyState?: string;
    companyCountry?: string;
    companyZipCode?: string;
    companyPhone1?: string;
    companyPhone2?: string;
    companyWeb?: string;
    companyEmail?: string;
    companyReportingEmail?: string;
    appName?: string;
    dashboardType?: string;
    appDesktopFontSize?: string;
    appMobileFontSize?: string;
    appDashboardLogoFile?: string;
    appMenuTopImageFile?: string;
    appMenuBgImage?: any;
    appMainColor?: string;
    appMainHoverColor?: string;
    appTopBarFontColor?: string;
    appMenuFontColor?: string;
    appMenuFontHoverColor?: string;
    appMenuActiveBgColor?: string;
    appMenuActiveBorderColor?: string;
    appMenuBgColor?: string;
    appMenuShadowColor?: string;
    appDeviceColor?: string;
    instantGadgetBarColor?: string;
    instantGadgetBgColor?: string;
    appToastSuccessColor?: string;
    appPlotColor1?: string;
    appPlotColor2?: string;
    appPlotColor3?: string;
    appPlotColor4?: string;
    appPlotColor5?: string;
    appPlotColor6?: string;
    appPlotPvGridColor?: string;
    appPlotPvPanelColor?: string;
    appPlotPvInstallationColor?: string;
  }
  
  export const defaultValue: Readonly<IAppSetting> = {};
  