import { useTranslation } from 'react-i18next';
import { LineGraph } from 'src/shared/components/plots/LineGraph';
import { Panel } from 'primereact/panel';
import { useAppSelector } from 'src/app/hooks';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import useSWR from 'swr';
import { useEffect, useState } from 'react';

export const ConvElectricEnergyPrice = () => {
  const { t } = useTranslation();
  const appSetting = useAppSelector(state => state.appSettings.entity);
  const url = 'api/energy-price-by-day';
  const [dataLine, setDataLine] = useState([]);
  const { data: energyPriceDay } = useSWR<any>(url, swrFetcher);

  useEffect(() => {
    if (energyPriceDay !== null && energyPriceDay !== undefined) {
      const x = [];
      const y = [];
      const keys = Object.keys(energyPriceDay).sort();
      if (keys.length > 0) {
        keys.forEach(key => {
          x.push(energyPriceDay[key].hour.split('-')[0] + ':00');
          y.push(energyPriceDay[key].price);
        });
      }

      setDataLine([
        {
          type: 'scatter',
          x,
          y,
          name: t('convElectricPlots:energyPrice'),
          showlegend: true,
          marker: {
            color: appSetting.appPlotColor1,
          },
        },
      ]);
    }
  }, [energyPriceDay]);

  return (
    <div className="grid">
      <div className="col-12">
        <Panel header={'Precio Energía durante el día'} style={{ padding: 0 }}>
          <LineGraph rangeSlider={true} height={'300px'} data={dataLine} suffix={'€/Mwh'} yAxisRange={0} dateFormat={undefined} xAxisType={'category'} />
        </Panel>
      </div>
    </div>
  );
};
