import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from 'src/app/reducers/utils/reducer.utils';
import { IDevice, defaultValue } from 'src/shared/model/device.model';
import { cleanEntity } from 'src/shared/utils/entity-utils';

const initialState: EntityState<IDevice> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/devices';

// Actions

export const getEntities = createAsyncThunk('electricSgmDevice/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IDevice[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'electricSgmDevice/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDevice>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'electricSgmDevice/create_entity',
  async (entity: IDevice, thunkAPI) => {
    const result = await axios.post<IDevice>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'electricSgmDevice/update_entity',
  async (entity: IDevice, thunkAPI) => {
    const result = await axios.put<IDevice>(`${apiUrl}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateConfig = createAsyncThunk(
  'electricSgmDevice/update_convelectricdevice_config',
  async (entity: IDevice, thunkAPI) => {
    const result = await axios.put<IDevice>(`${apiUrl}-config`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'electricSgmDevice/partial_update_entity',
  async (entity: IDevice, thunkAPI) => {
    const result = await axios.patch<IDevice>(`${apiUrl}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'electricSgmDevice/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDevice>(requestUrl);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntities = createAsyncThunk(
  'electricSgmDevice/delete_entities',
  async (ids: string[]) => {
    return await axios.delete<IDevice>(`${apiUrl}-by-ids?ids=${ids.join('&ids=')}`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ASlice = createEntitySlice({
  name: 'electricSgmDevice',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntities.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, updateConfig, partialUpdateEntity, deleteEntities), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, updateConfig, partialUpdateEntity, deleteEntity, deleteEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ASlice.actions;

// Reducer
export default ASlice.reducer;
