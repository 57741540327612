import dayjs from 'dayjs';
import i18next from 'i18next';
import { ModbusStatusToIcon, WifiSignalToIcon } from 'src/shared/utils/device-utils';
import { dateTimeFormatShot } from 'src/shared/utils/date-utils';
const t = i18next.t;

const DeviceCsq = ({ csq, dateTime }) => {
  return (
    <div className="flex align-items-center justify-content-end h-2rem" style={{ width: '200px' }}>
      <WifiSignalToIcon csq={csq} />
      <span style={{ marginLeft: 12, display: 'inline-block' }}>{dateTimeFormatShot(dateTime)}</span>
    </div>
  );
};

const DeviceModbusStatus = ({ csq, modbusStatus, dateTime }) => {
  return (
    <div className="flex align-items-center justify-content-end h-2rem">
      <ModbusStatusToIcon modbusStatus={modbusStatus} csq={csq} />
    </div>
  );
};
export const exportFormatter = (field: any, entity: any, objOptions: any) => {
  if (entity !== null && entity !== undefined) {
    if (field === 'appUser' || field === 'commercialUser') {
      return entity[field] || '';
    } else if (field === 'tariff') {
      return entity.tariff;
    } else if (field === 'available' || field === 'multiple') {
      return entity[field] ? 'true' : 'false';
    } else if (field === 'control') {
      return entity[field] ? 'Si' : 'No';
    } else if (field === 'power') {
      return entity[field] + 'kW';
    } else if (field === 'reactive') {
      return entity[field] ? 'Si' : 'No';
    } else if (field === 'readerType') {
      const status = entity.readerType ? entity.readerType : '0';
      return t('electricSgmDevice:readerTypes.' + status);
    } else if (field === 'status') {
      const status = entity.status ? entity.status : 'instock';
      return t('electricSgmDevice:statuses.' + status);
    } else if (field === 'dateTime') {
      return dayjs(entity[field]).format('DD/MM/YY HH:mm');
    } else if (field === 'convElectricCustomTariff') {
      return entity.convElectricCustomTariff && entity.convElectricCustomTariff.name;
    } else if (field === 'type') {
      return entity.type === 1 ? 'ELÉCTRICA' : 'FOTOVOLTÁICA';
    } else if (field === 'csq') {
      const csq = entity['csq'];
      const dateTime = entity['dateTime'];
      const modbusStatus = entity['modbusStatus'];
      return csq;
    } else if (field === 'name') {
      const name = entity['name'];
      return name?.toUpperCase();
    } else if (field === 'modbusStatus') {
      const csq = entity['csq'];
      const dateTime = entity['dateTime'];
      const modbusStatus = entity['modbusStatus'];
      return modbusStatus;
    } else if (field === 'plantId') {
      if (objOptions['plantId']) {
        const planId = objOptions['plantId'].find(res => res.value === entity['plantId']);
        return planId && planId.label;
      } else {
        return '';
      }
    } else if (field === 'deviceType') {
      const deviceType = entity.deviceType ? entity.deviceType : 0;
      return deviceType === 0 ? 'Eléctrico' : deviceType === 1 ? 'Fotovoltáico' : 'ParqueSolar';
    } else if (field === 'maintainerUser') {
      return entity[field] && entity[field].map(val => <div key={val}>{val}</div>);
    } else if (field === 'notificationEmails') {
      return entity[field] && entity[field].map(val => <div key={val}>{val}</div>);
    } else {
      return entity && entity[field] && entity[field];
    }
  } else {
    return '';
  }
};

export const fieldTemplate = (entity: any, column: any, objOptions: any) => {
  const field = column['field'];
  const value = exportFormatter(field, entity, objOptions);
  if (field === 'csq') {
    const dateTime = entity['dateTime'];
    return <DeviceCsq csq={value} dateTime={dateTime} />;
  } else if (column['field'] === 'modbusStatus') {
    const csq = entity['csq'];
    const dateTime = entity['dateTime'];
    const modbusStatus = entity['modbusStatus'];
    return <DeviceModbusStatus csq={csq} dateTime={dateTime} modbusStatus={modbusStatus} />;
  } else {
    return <div style={{ textAlign: 'center' }}>{value}</div>;
  }
};
