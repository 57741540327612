import React, { useRef } from 'react';
import axios from 'axios';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';

export const FileUpload4Datatable = (props: { label?: any; url: any; onFileUploadCompleted?: any; showToast?: any }) => {
  const fileUpload = useRef<FileUpload>();
  const toast = useRef<Toast>();

  const uploadHandler = ({ files }) => {
    const [file] = files;
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(props.url, formData, config)
      .then((res: any) => {
        const messages = res.data.map(message => {
          return { severity: 'success', summary: 'Success Message', detail: message };
        });
        toast.current.show(messages);
        props.onFileUploadCompleted();
      })
      .catch(error => {
        const messages = error.response.data.map(message => {
          return { severity: 'error', summary: 'Error Message', detail: message };
        });
        toast.current.show(messages);
      });
    try {
      fileUpload.current.clear();
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <FileUpload
        style={{ display: 'inline-block' }}
        className="p-mr-2"
        ref={fileUpload}
        auto
        mode={'basic'}
        accept=".csv"
        multiple={false}
        customUpload={true}
        name={'file'}
        uploadHandler={uploadHandler}
        chooseLabel={props.label && props.label}
      />
    </>
  );
};
