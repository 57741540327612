import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { TabView, TabPanel } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { ReportHistory } from 'src/shared/components/report/report-history';
import { ReportCreator } from 'src/shared/components/report/report-creator';
import { createEntity as createConvElectricReport } from 'src/app/reducers/entities/electric-cost-report.reducer';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { AUTHORITIES, GRID } from 'src/app/config/constants';

export const ConvElectricCostPvReport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const selectedConvElectricPvPlant = useAppSelector(state => state.plantSelector.selectedPlant);
  const updateSuccess = useAppSelector(state => state.electricCostReport.updateSuccess);
  const report = useAppSelector(state => state.electricCostReport.entity);
  const devices = selectedConvElectricPvPlant.devices;
  const plantId = selectedConvElectricPvPlant.id;

  const createReport = value => {
    dispatch(createConvElectricReport(value));
  };

  return (
    <TabView>
      {devices &&
        devices
          .filter(res => res.readerType === GRID)
          .map(res => {
            const device = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines, reactive: res.reactive };
            const devName = 'D' + res.index + (isAdmin && '(' + res.macId + ')') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
            const url = 'api/electric-cost-reports';
            return (
              <TabPanel key={device.macId} header={devName}>
                <ReportCreator plantId={plantId} macId={device.macId} createReport={createReport} report={report} reactive={device.reactive} />
                <ReportHistory plantId={plantId} macId={device.macId} url={url} updateSuccess={updateSuccess} />
              </TabPanel>
            );
          })}
    </TabView>
  );
};
