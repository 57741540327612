import { TabPanel, TabView } from 'primereact/tabview';
import { useAppSelector } from 'src/app/hooks';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import useSWR from 'swr';
import { ConvElectricCompleteMono, ConvElectricCompleteTri } from 'src/modules/instant-data/components/conv-electric-complete-component';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { AUTHORITIES } from 'src/app/config/constants';

export const ConvelectricSimpleDataComponent = ({ device }) => {
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const id = plant?.id;
  const url = 'api/electric-data-instant?plantId=' + id + '&macId=' + device.macId;
  const { data: convElectricInstantData } = useSWR<any>(url, swrFetcher);

  return (
    convElectricInstantData &&
    (device.voltageLines === 1 ? (
      <ConvElectricCompleteMono device={device} id={id} convElectricData={convElectricInstantData} width={400} url={url} />
    ) : (
      <ConvElectricCompleteTri device={device} id={id} convElectricData={convElectricInstantData} width={400} url={url} />
    ))
  );
};

export const ElectricSgmSimpleData = () => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const devices = plant.devices;

  return (
    <TabView>
      {devices &&
        devices.map(res => {
          const device = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines };
          const devName = 'D' + res.index + (isAdmin ? '(' + res.macId + ')' : '') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
          return (
            <TabPanel key={res.macId} header={devName}>
              <ConvelectricSimpleDataComponent device={device} />
            </TabPanel>
          );
        })}
    </TabView>
  );
};
