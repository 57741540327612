import { useAppSelector } from 'src/app/hooks';
import { TabView, TabPanel } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFetchElectricDataPvCombined } from 'src/app/hooks/use-fetch-electric-data-pv-combined';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import useSWR from 'swr';
import { AUTHORITIES, PLANT_TYPE_PHOTOVOLTAIC, PLOT_PV_ELECTRICAL, PLOT_PV_ENERGY, PLOT_PV_INSTALLATION } from 'src/app/config/constants';
import { PlotsDateRange } from 'src/shared/components/plots/plots-options';
import { SimpleOptionsPlot } from 'src/shared/components/plots/simple-options-plot';
import { ConvElectricPvCombinedPlot } from 'src/shared/components/plots/pv-combined-plot';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { useEffect, useState } from 'react';

const ElectricSgmPvInstallationComsunptiom = ({ plotVariable }) => {
  const rangeDate = useAppSelector(state => state.plots['rangeDate' + plotVariable]);
  const startDate = dayjs(rangeDate[0].valueOf()).startOf('day').toISOString();
  const endDate = dayjs(rangeDate[1]).isValid() ? dayjs(rangeDate[1].valueOf()).endOf('day').toISOString() : dayjs(rangeDate[0].valueOf()).endOf('day').toISOString();
  const { dataTable, dataLine, dounutData } = useFetchElectricDataPvCombined({
    startDate,
    endDate,
  });

  return dataLine && <ConvElectricPvCombinedPlot dataTable={dataTable} dataLine={dataLine} dateFormat={'YYYY-MM-DD HH:mm'} dataType={'kw'} dounutData={dounutData} suffix={'kW'} showDounut={true} />;
};

const ElectricSgmSimplePlotComponent = ({ electricSgmDevice, plotVariable, url, showDounut }) => {
  const rangeDate = useAppSelector(state => state.plots['rangeDate' + plotVariable]);
  const startDate = dayjs(rangeDate[0].valueOf()).startOf('day').toISOString();
  const endDate = dayjs(rangeDate[1]).isValid() ? dayjs(rangeDate[1].valueOf()).endOf('day').toISOString() : dayjs(rangeDate[0].valueOf()).endOf('day').toISOString();
  const { data: datas } = useSWR<any>(url + '&startDate=' + startDate + '&endDate=' + endDate, swrFetcher);
  return datas && <SimpleOptionsPlot device={electricSgmDevice} showOptions datas={datas} plotVariable={plotVariable} showDounut={showDounut} showDataTable={true} />;
};

export const ElectricDataPlots = () => {
  const { t } = useTranslation();
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const devices = plant.devices;
  const plantId = plant.id;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(plant.type === PLANT_TYPE_PHOTOVOLTAIC ? 0 : 1);
  }, [plant]);

  return (
    <TabView activeIndex={activeIndex} onTabChange={e => setActiveIndex(e.index)}>
      {plant.type === PLANT_TYPE_PHOTOVOLTAIC && (
        <TabPanel header={t('electricSgmPlots:installationConsumption')}>
          <PlotsDateRange plotVariable={PLOT_PV_INSTALLATION} />
          <ElectricSgmPvInstallationComsunptiom plotVariable={PLOT_PV_INSTALLATION} />
        </TabPanel>
      )}
      <TabPanel header={t('electricSgmPlots:energyData')}>
        <PlotsDateRange plotVariable={PLOT_PV_ENERGY} />
        <TabView>
          {devices &&
            devices.map(res => {
              const electricSgmDevice = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines };
              const devName = 'D' + res.index + (isAdmin && '(' + res.macId + ')') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
              return (
                <TabPanel key={res.macId} header={devName}>
                  <ElectricSgmSimplePlotComponent
                    electricSgmDevice={electricSgmDevice}
                    plotVariable={PLOT_PV_ENERGY}
                    showDounut={true}
                    url={'api/electric-datas-energy-data-by-date-range?plantId=' + plantId + '&macId=' + electricSgmDevice.macId}
                  />
                </TabPanel>
              );
            })}
        </TabView>
      </TabPanel>
      <TabPanel header={t('electricSgmPlots:electricData')}>
        <PlotsDateRange plotVariable={PLOT_PV_ELECTRICAL} />
        <TabView>
          {devices &&
            devices.map(res => {
              const electricSgmDevice = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines };
              const devName = 'D' + res.index + (isAdmin && '(' + res.macId + ')') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
              return (
                <TabPanel key={res.macId} header={devName}>
                  <ElectricSgmSimplePlotComponent
                    electricSgmDevice={electricSgmDevice}
                    plotVariable={PLOT_PV_ELECTRICAL}
                    showDounut={false}
                    url={'api/electric-datas-electrical-data-by-date-range?plantId=' + plantId + '&macId=' + electricSgmDevice.macId}
                  />
                </TabPanel>
              );
            })}
        </TabView>
      </TabPanel>
    </TabView>
  );
};
