export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  CADMIN: 'ROLE_CADMIN',
  COMMERCIAL: 'ROLE_COMMERCIAL',
  INSTALLER: 'ROLE_INSTALLER',
  MAINTAINER: 'ROLE_MAINTAINER',
  CVE: 'ROLE_APP_CVE',
  CVEPV: 'ROLE_APP_CVEPV',
  CVEW: 'ROLE_APP_CVEW',
  CBX: 'ROLE_APP_CBX',
  DEMO: 'ROLE_DEMO',
  BASIC: 'ROLE_BASIC',
  PRO: 'ROLE_PRO',
  PREMIUM: 'ROLE_PREMIUM',
  APP_USER: 'ROLE_APP_USER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const PLANT_TYPE_ELECTRIC = 1;
export const PLANT_TYPE_PHOTOVOLTAIC = 2;

export const PLOT_ENERGY = 0;
export const PLOT_ELECTRICAL = 1;
export const PLOT_PV_INSTALLATION = 2;
export const PLOT_PV_ENERGY = 3;
export const PLOT_PV_ELECTRICAL = 4;

export const SINGLE_PHASE = 1;
export const THREE_PHASE = 3;

export const PANEL = 1;
export const GRID = 2;
