import { Stage, Layer, Text, Rect } from 'react-konva';
import { InfoBoxMono, InfoBoxTri } from 'src/shared/utils/konvak-utils';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat, timeStampFormat } from 'src/shared/utils/date-utils';
import { decimalFormat } from 'src/shared/utils/number-utils';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import axios from 'axios';

export const ConvElectricCompleteMono = (props: { width: any; id; device; convElectricData: any; url }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);

  const requestInstantData = () => {
    axios.put(`api/electric-data-instant?id=${props.id}&macId=${props.device.macId}`);
    setDisable(true);
    mutate(props.url);
    setTimeout(() => {
      mutate(props.url);
    }, 3000);
    setTimeout(() => {
      setDisable(false);
    }, 60000);
  };

  return (
    <div style={{ textAlign: 'center', width: props.width + 10, margin: '0 auto', border: '3px solid', background: 'rgb(230,230,230)' }}>
      <Stage width={props.width} height={330}>
        <Layer>
          <Rect x={0} y={0} width={props.width} height={330} />
          <InfoBoxMono name={t('convElectricData:vol')} value={decimalFormat(props.convElectricData.volPA, 0) + 'V'} pos={1} />
          <InfoBoxMono name={t('convElectricData:cur')} value={decimalFormat(props.convElectricData.curA, 1) + 'A'} pos={2} />
          <InfoBoxMono name={t('convElectricData:insP')} value={decimalFormat(props.convElectricData.insPA, 1) + 'kW'} pos={3} />
          <InfoBoxMono name={t('convElectricData:insQ')} value={decimalFormat(props.convElectricData.insQA, 1) + 'kVAr'} pos={4} />
          <InfoBoxMono name={t('convElectricData:insS')} value={decimalFormat(props.convElectricData.insSA, 1) + 'kVA'} pos={5} />
          <InfoBoxMono name={t('convElectricData:pwrF')} value={decimalFormat(props.convElectricData.pwrFA, 1) + ''} pos={6} />
          <InfoBoxMono name={t('convElectricData:freq')} value={decimalFormat(props.convElectricData.freq, 0) + 'Hz'} pos={7} />
          <Text x={0} y={210} text={props.device.name} fill="black" align={'left'} width={props.width} fontSize={14} />
          <Text x={0} y={230} text={timeStampFormat(props.convElectricData.timeStamp, props.device.timeZone)} fill="black" align={'left'} width={props.width} fontSize={14} />
        </Layer>
      </Stage>
      <Button label="Refrescar" onClick={requestInstantData} disabled={disable} />
    </div>
  );
};

export const ConvElectricCompleteTri = (props: { width: any; id; device; convElectricData; url }) => {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);

  const requestInstantData = () => {
    axios.get(`api/electric-data-instant-request?id=${props.id}&macId=${props.device.macId}`);
    setDisable(true);
    mutate(props.url);
    setTimeout(() => {
      mutate(props.url);
    }, 3000);
    setTimeout(() => {
      setDisable(false);
    }, 60000);
  };

  return (
    <div style={{ textAlign: 'center', width: props.width + 10, margin: '0 auto', border: '3px solid', background: 'rgb(230,230,230)' }}>
      <Stage width={props.width} height={310}>
        <Layer>
          <Rect x={0} y={0} width={props.width} height={330} />
          <InfoBoxTri value1={'L1'} value2={'L2'} value3={'L3'} pos={0} bgColor="lightgray" />
          <InfoBoxTri
            name={t('convElectricData:volP')}
            value1={decimalFormat(props.convElectricData.volPA, 0) + 'V'}
            value2={decimalFormat(props.convElectricData.volPB, 0) + 'V'}
            value3={decimalFormat(props.convElectricData.volPC, 0) + 'V'}
            pos={1}
          />
          <InfoBoxTri
            name={t('convElectricData:volL')}
            value1={decimalFormat(props.convElectricData.volLA, 0) + 'V'}
            value2={decimalFormat(props.convElectricData.volLB, 0) + 'V'}
            value3={decimalFormat(props.convElectricData.volLC, 0) + 'V'}
            pos={2}
          />
          <InfoBoxTri
            name={t('convElectricData:cur')}
            value1={decimalFormat(props.convElectricData.curA, 1) + 'A'}
            value2={decimalFormat(props.convElectricData.curB, 1) + 'A'}
            value3={decimalFormat(props.convElectricData.curC, 1) + 'A'}
            pos={3}
          />
          <InfoBoxTri
            name={t('convElectricData:insP')}
            value1={decimalFormat(props.convElectricData.insPA, 2) + 'kW'}
            value2={decimalFormat(props.convElectricData.insPB, 2) + 'kW'}
            value3={decimalFormat(props.convElectricData.insPC, 2) + 'kW'}
            pos={4}
          />
          <InfoBoxMono name={t('convElectricData:insPTotal')} value={decimalFormat(props.convElectricData.insPT, 2) + 'kW'} pos={5} />
          <InfoBoxTri
            name={t('convElectricData:insQ')}
            value1={decimalFormat(props.convElectricData.insQA, 1) + 'kVAr'}
            value2={decimalFormat(props.convElectricData.insQB, 1) + 'kVAr'}
            value3={decimalFormat(props.convElectricData.insQC, 1) + 'kVAr'}
            pos={6}
          />
          <InfoBoxTri
            name={t('convElectricData:insS')}
            value1={decimalFormat(props.convElectricData.insSA, 1) + 'kVA'}
            value2={decimalFormat(props.convElectricData.insSB, 1) + 'kVA'}
            value3={decimalFormat(props.convElectricData.insSC, 1) + 'kVA'}
            pos={7}
          />
          <InfoBoxTri
            name={t('convElectricData:pwrF')}
            value1={decimalFormat(props.convElectricData.pwrFA, 1) + ''}
            value2={decimalFormat(props.convElectricData.pwrFB, 1) + ''}
            value3={decimalFormat(props.convElectricData.pwrFC, 1) + ''}
            pos={8}
          />
          <InfoBoxMono name={t('convElectricData:freq')} value={decimalFormat(props.convElectricData.freq, 0) + 'Hz'} pos={9} />
          <Text x={2} y={270} text={props.device.name} fill="black" align={'left'} width={props.width} fontSize={14} />
          <Text x={2} y={290} text={timeStampFormat(props.convElectricData.timeStamp, props.device.timeZone)} fill="black" align={'left'} width={props.width} fontSize={14} />
        </Layer>
      </Stage>
      <Button label="Refrescar" onClick={requestInstantData} disabled={disable} />
    </div>
  );
};
