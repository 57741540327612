import { useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { ReportViewer } from './report-viewer';

export const ReportCreator = (props: { plantId; macId; reactive; createReport; report }) => {
  const { t } = useTranslation();
  const [rangeDate, setRangeDate] = useState([dayjs().toISOString(), dayjs().toISOString()]);
  // const [visible, setVisible] = useState(false);
  const calendarRef = useRef(null);
  const createReport = (persist: any) => {
    if (rangeDate[0] && rangeDate[1]) {
      const startDateString = dayjs(rangeDate[0]).startOf('day').toISOString();
      const endDateString = dayjs(rangeDate[1]).endOf('day').toISOString();
      props.createReport({ plantId: props.plantId, macId: props.macId, startDate: startDateString, endDate: endDateString, persist });
    }
  };

  const setData = e => {
    setRangeDate(e.value.map(e => (dayjs(e).isValid() ? dayjs(e).toISOString() : '')));
  };

  const classs = 'flex align-items-center justify-content-center pr-1 pb-1 ';

  return (
    <div className="lg:flex">
      {/* <Dialog visible={visible} style={{ width: '800px' }} header={'Estado'} modal className="p-fluid" onHide={() => setVisible(false)}>
        <ReportViewer
          reactive={props.reactive || false}
          powerReportList={props.report.convElectricPowerReportList}
          energyReportList={props.report.convElectricActiveEnergyReportList}
          reactiveEnergyReportList={props.report.convElectricReactiveEnergyReportList}
          subtotal={props.report.subtotal}
          electricTax={props.report.electricTax}
          iva={props.report.iva}
          total={props.report.total}
        />
      </Dialog> */}
      <div className={classs}>
        <Calendar
          ref={calendarRef}
          value={rangeDate.map(e => (dayjs(e).isValid() ? dayjs(e).toDate() : null))}
          numberOfMonths={1}
          onChange={setData}
          selectionMode="range"
          readOnlyInput
          appendTo={document.body}
          dateFormat="dd/mm/yy"
          headerTemplate={() => (
            <Button
              label="Cerrar"
              onClick={() => {
                calendarRef.current.onButtonClick();
              }}
            />
          )}
          style={{ width: '100%' }}
        />
      </div>
      <div className={classs}>
        <Button
          label={t('report:generate')}
          onClick={() => {
            createReport(true);
            // setVisible(true);
          }}
          disabled={!(rangeDate && rangeDate[0] && rangeDate[1])}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};
