import { createSlice } from '@reduxjs/toolkit';

export const currentLocaleSlice = createSlice({
  name: 'localeReducer',
  initialState: { currentLocale: 'es' },
  reducers: {
    setCurrentLocale(state, action) {
      state.currentLocale = action.payload;
    },
  },
});

export const { setCurrentLocale } = currentLocaleSlice.actions;

// Reducer
export default currentLocaleSlice.reducer;
