import { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DropdownV2, InputTextStringArrayV2, InputTextV2, MultiSelectV2, StringDropdownV2 } from './primereact-custom';
import { CalendarV2 } from './primereact-custom';
import { MultiSelect } from 'primereact/multiselect';
import { exportExcel } from './datatable-utils';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload4Datatable } from './fIle-upload-4-datatable';
import useSWR, { useSWRConfig } from 'swr';
import { swrFetcher } from '../../utils/swrUtil';

export const DatatableV1 = (props: {
  height?: any;
  scrollHeigh?: any;
  columns: any[];
  data?: any[];
  fieldTemplate: any;
  exportFormatter?: any;
  dataKey?: any;
  sortField?: any;
  sortOrder?: any;
  selectionMode?: any;
  updateSuccess?: any;
  getEntitiesUrl?: any;
  saveEntityAction?: any;
  updateEntityAction?: any;
  deleteEntityAction?: any;
  autoLayout?: any;
  rows: number;
  createButtonLabel?: any;
  editButtonLabel?: any;
  objectOptions?: any;
  importerUrl?: any;
  importerLabel?: any;
  saveCustomDialog?: any;
  updateCustomDialog?: any;
  onSelect?: any;
  rowExpansionTemplate?: any;
  export?: boolean;
  loadingBar?: any;
  frozenWidth?: any;
  paginator?: any;
  mapData?: any;
  refreshInterval?: any;
  refreshButton?: any;
}) => {
  const { mutate } = useSWRConfig();
  const { data } = useSWR(props.data ? null : props.getEntitiesUrl, swrFetcher, { refreshInterval: props.refreshInterval });
  const { t } = useTranslation();
  const dtRef = useRef(null);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [saveEntityDialog, setSaveEntityDialog] = useState(false);
  const [updateEntity, setUpdateEntity] = useState(false);
  const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
  const [entity, setEntity] = useState({});
  const [expandedRows, setExpandedRows] = useState(null);
  const [filters, setFilters] = useState({});
  useEffect(() => {
    if (props.updateSuccess) {
      mutate(props.getEntitiesUrl);
    }
  }, [props.updateSuccess]);

  const saveEntity = () => {
    if (updateEntity) {
      props.updateEntityAction(entity);
    } else {
      props.saveEntityAction(entity);
    }
    setSaveEntityDialog(false);
    setUpdateEntity(false);
    setSelectedEntities(null);
  };

  const onExportExcel = () => {
    exportExcel({
      exportFormatter: props.exportFormatter,
      columns: props.columns,
      data,
    });
  };

  const header = (
    <div className="grid align-center">
      <div className="lg:col-8 col-12 " style={{ textAlign: 'left' }}>
        {props.importerUrl && (
          <FileUpload4Datatable url={props.importerUrl} label={props.importerLabel ? props.importerLabel : t('entity:importer.label')} onFileUploadCompleted={() => mutate(props.getEntitiesUrl)} />
        )}
        {props.saveEntityAction && (
          <Button
            label={props.createButtonLabel ? props.createButtonLabel : t('entity:action.add')}
            icon="pi pi-plus"
            className="p-button-primary ml-2"
            style={{ marginRight: 5 }}
            onClick={() => {
              props.saveCustomDialog ? props.saveCustomDialog(true) : setSaveEntityDialog(true);
              setEntity({});
            }}
            disabled={props.loadingBar && props.loadingBar.default === 1}
          />
        )}
        {props.updateEntityAction && (
          <Button
            label={props.editButtonLabel ? props.editButtonLabel : t('entity:action.edit')}
            icon="pi pi-trash"
            className="p-button-warning"
            style={{ marginRight: 5 }}
            disabled={
              props.selectionMode === 'multiple'
                ? !(selectedEntities && selectedEntities.length === 1) || (props.loadingBar && props.loadingBar.default === 1)
                : !(selectedEntities !== null) || (props.loadingBar && props.loadingBar.default === 1)
            }
            onClick={() => {
              if (props.updateCustomDialog) {
                props.updateCustomDialog(true, props.selectionMode === 'multiple' ? selectedEntities[0] : selectedEntities);
              } else {
                setEntity(props.selectionMode === 'multiple' ? selectedEntities[0] : selectedEntities);
                setSaveEntityDialog(true);
                setUpdateEntity(true);
              }
            }}
          />
        )}
        {props.deleteEntityAction && (
          <Button
            label={t('entity:action.delete')}
            icon="pi pi-trash"
            className="p-button-danger"
            disabled={!selectedEntities || !selectedEntities.length || (props.loadingBar && props.loadingBar.default === 1)}
            onClick={() => setDeleteEntitiesDialog(true)}
            badge={selectedEntities ? selectedEntities.length.toString() : '0'}
          />
        )}
        {props.refreshButton && <Button label={t('entity:action.refresh')} icon="pi pi-refresh" severity="help" onClick={() => mutate(props.getEntitiesUrl)} />}
      </div>
      <div className="lg:col-4 col-12" style={{ textAlign: 'right' }}>
        {props.exportFormatter && <Button type="button" icon="pi pi-file-excel" onClick={onExportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />}
      </div>
    </div>
  );

  const deleteEntitiesDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteEntitiesDialog(false)} />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          setDeleteEntitiesDialog(false);
          props.deleteEntityAction(selectedEntities.map(e => e.id));
          setSelectedEntities([]);
        }}
      />
    </>
  );

  const footerSaveDialog = (
    <>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setSaveEntityDialog(false)} />
      <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEntity} />
    </>
  );

  const InputFilter = field => (
    <InputText
      value={filters[field]}
      onChange={e => {
        setFilters({ ...filters, [field]: e.currentTarget.value });
        dtRef.current.filter(e.currentTarget.value, field, 'contains');
      }}
      style={{ width: '100%' }}
      className="p-column-filter"
    />
  );

  const DropdownFilter = field =>
    props.objectOptions[field] && (
      <Dropdown
        filter
        value={filters[field]}
        options={props.objectOptions[field]}
        onChange={e => {
          setFilters({ ...filters, [field]: e.value });
          dtRef.current.filter(e.value, field, 'equals');
        }}
        style={{ width: '100%' }}
        className="p-column-filter"
        showClear
      />
    );

  const MultiSelectFilter = field =>
    props.objectOptions[field] &&
    props.objectOptions[field].length > 0 && (
      <MultiSelect
        value={filters[field]}
        options={props.objectOptions[field]}
        onChange={e => {
          const selets = e.value && e.value.map(res => res && props.objectOptions[field].find(res1 => res1.value.id === res.id).value);
          setFilters({ ...filters, [field]: selets });
          dtRef.current.filter(selets, field, 'equals');
        }}
        style={{ width: '100%' }}
        className="p-column-filter"
        showClear
      />
    );

  // const typeFilterTemplate = options => {
  //   return (
  //     <Dropdown
  //       value={plantType}
  //       options={typeOptions}
  //       onChange={e => {
  //         setPlantType(e.value);
  //         dt.current.filter(e.value, 'type', 'equals');
  //       }}
  //       placeholder="Select One"
  //       className="p-column-filter"
  //       showClear
  //     />
  //   );
  // };

  return (
    <div>
      <DataTable
        style={{ marginTop: 5 }}
        ref={dtRef}
        value={props.data || (props.mapData ? props.mapData(data) : [...(data || [])])}
        // globalFilterFields={props.globalFilterFields}
        header={header}
        scrollable
        scrollHeight={props.scrollHeigh ? props.scrollHeigh : '100%'}
        paginator={props.paginator}
        rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 1000]}
        rows={props.rows}
        sortField={props.sortField}
        sortOrder={props.sortOrder ? props.sortOrder : 1}
        selection={selectedEntities}
        selectionMode={props.selectionMode === 'multiple' ? 'checkbox' : 'radiobutton'}
        onSelectionChange={e => {
          setSelectedEntities(e.value);
          props.onSelect && props.onSelect(e.value);
        }}
        dataKey={props.dataKey || 'id'}
        removableSort
        expandedRows={expandedRows}
        onRowToggle={e => {
          setExpandedRows(e.data);
        }}
        rowExpansionTemplate={props.rowExpansionTemplate}
        className="p-datatable-gridlines"
        stripedRows
        frozenWidth={props.frozenWidth}
      >
        {props.selectionMode && <Column selectionMode={props.selectionMode === 'multiple' ? 'multiple' : 'single'} style={{ minWidth: 50, maxWidth: 50 }}></Column>}
        {props.rowExpansionTemplate && <Column expander style={{ minWidth: 50, maxWidth: 50 }} />}
        {props.columns &&
          props.columns.map((col, i) => {
            return (
              <Column
                body={props.fieldTemplate}
                key={col.field}
                field={col.field}
                sortable={col.sortable}
                header={<div style={{ textAlign: 'center' }}>{col.header}</div>}
                style={{ minWidth: col.width }}
                filter={col.filter}
                filterApply={false}
                filterMatchMode="in"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                showApplyButton={false}
                showClearButton={false}
                filterElement={col.type === 'MultiSelect' ? MultiSelectFilter(col.field) : col.type === 'StringDropdown' ? DropdownFilter(col.field) : InputFilter(col.field)}
                frozen={col.frozen}
              />
            );
          })}
      </DataTable>

      <Dialog visible={deleteEntitiesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEntitiesDialogFooter} onHide={() => setDeleteEntitiesDialog(false)}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: 5 }} />
          {<span>{t('entity:question.delete')}</span>}
        </div>
      </Dialog>

      <Dialog
        visible={saveEntityDialog}
        style={{ width: '800px' }}
        header={updateEntity ? t('entity:action.edit') : t('entity:action.add')}
        modal
        className="p-fluid"
        onHide={() => {
          setSaveEntityDialog(false);
          setUpdateEntity(false);
        }}
        footer={footerSaveDialog}
      >
        <div>
          {props.columns
            .filter(col => !col.readOnly)
            .map(col => {
              if (col.type === 'String' && col.field !== 'id' && col.editable) {
                return (
                  <div key={col.field} className="col-12">
                    <InputTextV2 label={col.header} entity={entity} field={col.field} setEntity={setEntity} />
                  </div>
                );
              }
              if (col.type === 'Object' && col.field !== 'id' && col.editable) {
                const options = props.objectOptions[col.field];
                return (
                  <div key={col.field} className="col-12">
                    <DropdownV2 label={col.header} entity={entity} field={col.field} setEntity={setEntity} options={props.objectOptions ? options : []} />
                  </div>
                );
              }
              if (col.type === 'StringDropdown' && col.field !== 'id' && col.editable) {
                const options = props.objectOptions[col.field];
                return (
                  <div key={col.field} className="col-12">
                    <StringDropdownV2 label={col.header} entity={entity} field={col.field} setEntity={setEntity} options={props.objectOptions ? options : []} />
                  </div>
                );
              }
              if (col.type === 'MultiSelect' && col.field !== 'id' && col.editable) {
                const options = props.objectOptions[col.field];
                return (
                  <div key={col.field} className="col-12">
                    <MultiSelectV2 label={col.header} entity={entity} field={col.field} setEntity={setEntity} options={props.objectOptions ? options : []} />
                  </div>
                );
              }

              if (col.type === 'StringArray' && col.field !== 'id' && col.editable) {
                const options = props.objectOptions[col.field];
                return (
                  <div key={col.field} className="col-12">
                    <InputTextStringArrayV2 label={col.header} entity={entity} field={col.field} setEntity={setEntity} />
                  </div>
                );
              }

              if (col.type === 'Date' && col.field !== 'id' && col.editable) {
                return (
                  <div key={col.field} className="col-12">
                    <CalendarV2 label={col.header} entity={entity} field={col.field} setEntity={setEntity} />
                  </div>
                );
              }
            })}
        </div>
      </Dialog>
    </div>
  );
};
