import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import useSWR from 'swr';
import { setSelectedPlant, setSelectedDeviceId } from 'src/app/reducers/plant-selector.reducer';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { AUTHORITIES } from 'src/app/config/constants';
import { timeStampFormat } from 'src/shared/utils/date-utils';
import { AlarmCountToIcon, ModbusStatusToIcon, WifiSignalToIcon } from 'src/shared/utils/device-utils';
import { swrFetcher } from 'src/shared/utils/swrUtil';
import { VirtualScroller } from 'primereact/virtualscroller';
import { PlantSelectorDataTable } from './components/plant-selector-datatable';
import { useLocalStorage } from 'primereact/hooks';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';

const PlantList = (props: { plants; selectionMode }) => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isCAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CADMIN]));
  const isMaintainer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.MAINTAINER]));
  const showId = isAdmin || isCAdmin || isMaintainer;
  const dispatch = useAppDispatch();
  const selectedPlant = useAppSelector(state => state.plantSelector.selectedPlant);
  const dropdownDevicePanel = useRef(null);
  const [, setPlant] = useLocalStorage(0, 'selectedPlant');
  return (
    selectedPlant &&
    selectedPlant.id && (
      <div className="flex">
        <Button icon="pi pi-caret-down" onClick={e => dropdownDevicePanel.current.toggle(e)} style={{ height: '3rem' }} />
        <OverlayPanel ref={dropdownDevicePanel} dismissable={true} style={{ width: 600 }}>
          <PlantSelectorDataTable
            plants={props.plants}
            selectionMode={'single'}
            rows={10}
            selectedDevices={selectedPlant}
            onSelectionChange={e => {
              if (props.selectionMode === 'single') {
                if (e.value !== null) {
                  dispatch(setSelectedPlant([e.value]));
                  setPlant(e.value.id);
                  if (e.originalEvent !== undefined && e.originalEvent.type === 'click') {
                    dropdownDevicePanel.current.hide();
                  }
                }
              } else {
                dispatch(setSelectedPlant(e.value));
              }
            }}
            onHide={() => dropdownDevicePanel && dropdownDevicePanel.current && dropdownDevicePanel.current.hide()}
            showDevId={showId}
          />
        </OverlayPanel>
        {selectedPlant && (
          <div className="w-100rem flex align-items-center justify-content-start ml-3">
            <FontAwesomeIcon icon={selectedPlant.type === 1 ? 'bolt' : 'solar-panel'} style={{ fontSize: '3rem', color: 'var(--main-color-0)' }} />
            <div className="ml-3">
              <div>{selectedPlant.name.toUpperCase()}</div>
              <div>{selectedPlant.location}</div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export const PlantSelector = (props: { selectionMode }) => {
  const dispatch = useAppDispatch();
  const selectedPlant = useAppSelector(state => state.plantSelector.selectedPlant);
  const updateSuccess = useAppSelector(state => state.electricSgmAlarmLog.updateSuccess);
  const url = 'api/plants-devices-dto';
  const { data: plants } = useSWR(url, swrFetcher);
  const [plantId] = useLocalStorage(0, 'selectedPlant');
  const [, setActiveIndex] = useLocalStorage(0, 'electricSgmAlarmLogHistory');
  useEffect(() => {
    if (plants && plants.length > 0) {
      if (selectedPlant.id === null || selectedPlant.id === undefined) {
        var plantFound = plants.find(res => res.id === plantId);
        if (plantFound !== undefined) {
          dispatch(setSelectedPlant([plantFound]));
        } else {
          dispatch(setSelectedPlant(plants[0]));
        }
      } else {
        dispatch(setSelectedPlant(plants.find(res => res.id === selectedPlant.id)));
      }
    }
  }, [plants]);

  useEffect(() => {
    if (updateSuccess) {
      mutate(url);
    }
  }, [updateSuccess]);

  const deviceTemplate = electricSgmDevice => {
    return (
      <div key={electricSgmDevice.macId} className="flex justify-content-start pt-3 pr-3 pl-3" style={{ background: 'none' }}>
        <div className="flex">
          <span className="pr-1 pt-1" style={{ fontSize: '1rem', marginRight: 0, display: 'inline-block' }}>
            {'D' + electricSgmDevice.index + ':'}
          </span>
          <WifiSignalToIcon csq={electricSgmDevice.csq} />
          <ModbusStatusToIcon modbusStatus={electricSgmDevice.modbusStatus} csq={electricSgmDevice.csq} />
          <Link
            to="/alarm-log_"
            onClick={() => {
              dispatch(setSelectedDeviceId(electricSgmDevice.index - 1));
            }}
          >
            <AlarmCountToIcon alarmCount={electricSgmDevice.alarmCount} />
          </Link>
          <span style={{ marginLeft: 12, display: 'inline-block' }}>{timeStampFormat(electricSgmDevice.timeStamp)}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="md:flex align-items-center md:h-6rem h-12rem">
      <div className="md:flex card h-6rem align-content-center flex-wrap">
        <PlantList plants={plants} selectionMode={props.selectionMode} />
      </div>
      <div className="flex card h-6rem align-items-center align-content-center justify-content-end flex-wrap w-auto" style={{ padding: 0 }}>
        <VirtualScroller className="pr-3" items={selectedPlant.devices} itemSize={2} itemTemplate={deviceTemplate} style={{ width: '290px', height: '75px' }} />
      </div>
    </div>
  );
};
