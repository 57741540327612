import { updateEntity as updateConvElectricPvAlarmLog } from 'src/app/reducers/entities/electric-sgm-alarm-log.reducer';
import { TabPanel, TabView } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { DatatableV1 } from 'src/shared/components/general/DatatableV1';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hasAnyAuthority } from 'src/shared/auth/private-route';
import { AUTHORITIES } from 'src/app/config/constants';
import { dateTimeFormat, timeStampFormat } from 'src/shared/utils/date-utils';
import dayjs from 'dayjs';
import { variableSuffix } from 'src/shared/utils/conv-electric-data-utils';
import { useLocalStorage } from 'primereact/hooks';
import { setSelectedDeviceId } from 'src/app/reducers/plant-selector.reducer';

export const ElectricSgmAlarmLogComponent = ({ id, electricSgmDevice }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.electricSgmAlarmLog.updateSuccess);
  const columns = [
    { field: 'status', type: 'StringDropdown', width: 130, editable: true },
    { field: 'variable', type: 'DateTime', width: 130, editable: false },
    { field: 'timeStamp', type: 'DateTime', header: 'Fecha', width: 130, editable: false },
    { field: 'currentValue', type: 'String', width: 100, editable: false },
    { field: 'referenceValue', type: 'DateTime', width: 130, editable: false },
    { field: 'message', type: 'String', width: 130, editable: false },
    { field: 'note', type: 'String', width: 130, editable: true },
  ].map(column => ({ ...column, header: t('alarmLog:' + column.field) }));

  const objectOptions = {
    status: [
      { label: t('alarmLog:new'), value: 'new' },
      { label: t('alarmLog:ack'), value: 'ack' },
      { label: t('alarmLog:unack'), value: 'unack' },
    ],
  };

  const updateEntity = entity => {
    entity['plantId'] = id;
    dispatch(updateConvElectricPvAlarmLog(entity));
  };

  const exportFormatter = (field: any, entity: any, objOptions: any) => {
    if (field === 'referenceValue') {
      const comparator = entity['comparator'] === 1 ? '> ' : '< ';
      return comparator + entity['referenceValue'] + variableSuffix[entity.variable];
    } else if (field === 'status') {
      return t('alarmLog:' + entity.status);
    } else if (field === 'timeStamp') {
      return timeStampFormat(dayjs(entity[field]), 'Europe/Madrid', 'DD/MM/YYYY HH:mm');
    } else if (field === 'variable') {
      return t('convElectricData:' + (electricSgmDevice.voltageLines === 1 ? 'mono.' : 'tri.') + entity[field]);
    } else if (field === 'currentValue') {
      return entity.currentValue + variableSuffix[entity.variable];
    } else if (field === 'referenceValue') {
      return entity.referenceValue + variableSuffix[entity.variable];
    } else {
      return entity[field];
    }
  };

  const fieldTemplate = (entity: any, column: any, objOptions: any) => {
    const field = column['field'];
    const value = exportFormatter(field, entity, objOptions);
    return <div style={{ textAlign: 'center' }}>{value}</div>;
  };

  return (
    <TabView>
      <TabPanel header="Alarmas Nuevas">
        <DatatableV1
          scrollHeigh="calc( 100vh - 300px )"
          columns={columns}
          objectOptions={objectOptions}
          fieldTemplate={fieldTemplate}
          exportFormatter={exportFormatter}
          rows={10}
          sortField={'timeStamp'}
          sortOrder={-1}
          selectionMode={'single'}
          getEntitiesUrl={'api/electric-sgm-alarm-logs-by-macid-and-status?plantId=' + id + '&macId=' + electricSgmDevice.macId + '&status=new'}
          updateEntityAction={updateEntity}
          updateSuccess={updateSuccess}
        />
      </TabPanel>
      <TabPanel header="Alarmas Conocidas">
        <DatatableV1
          scrollHeigh="calc( 100vh - 300px )"
          columns={columns}
          objectOptions={objectOptions}
          fieldTemplate={fieldTemplate}
          exportFormatter={exportFormatter}
          rows={10}
          sortField={'timeStamp'}
          sortOrder={-1}
          selectionMode={'single'}
          getEntitiesUrl={'api/electric-sgm-alarm-logs-by-macid-and-status?plantId=' + id + '&macId=' + electricSgmDevice.macId + '&status=ack'}
          updateEntityAction={updateEntity}
          updateSuccess={updateSuccess}
        />
      </TabPanel>
      <TabPanel header="Alarmas Desconocidas">
        <DatatableV1
          scrollHeigh="calc( 100vh - 300px )"
          columns={columns}
          objectOptions={objectOptions}
          fieldTemplate={fieldTemplate}
          exportFormatter={exportFormatter}
          rows={10}
          sortField={'timeStamp'}
          sortOrder={-1}
          selectionMode={'single'}
          getEntitiesUrl={'api/electric-sgm-alarm-logs-by-macid-and-status?plantId=' + id + '&macId=' + electricSgmDevice.macId + '&status=unack'}
          updateEntityAction={updateEntity}
          updateSuccess={updateSuccess}
        />
      </TabPanel>
    </TabView>
  );
};

export const ElectricSgmAlarmLog = () => {
  const dispatch = useAppDispatch();
  const plant = useAppSelector(state => state.plantSelector.selectedPlant);
  const selectedAlarmLogDeviceId = useAppSelector(state => state.plantSelector.selectedDeviceId);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const devices = plant.devices;
  const id = plant.id;

  return (
    <TabView activeIndex={selectedAlarmLogDeviceId} onTabChange={e => dispatch(setSelectedDeviceId(e.index))}>
      {devices &&
        devices.map(res => {
          const device = { macId: res.macId, name: res.name, model: res.model, voltageLines: res.voltageLines };
          const devName = 'D' + res.index + (isAdmin && '(' + res.macId + ')') + ':' + (res.readerType === 1 ? 'Panel' : 'Red') + (res.name ? '_' + res.name : '');
          return (
            <TabPanel key={res.macId} header={devName}>
              <ElectricSgmAlarmLogComponent electricSgmDevice={device} id={id} />
            </TabPanel>
          );
        })}
    </TabView>
  );
};
