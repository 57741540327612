import { useAppDispatch } from 'src/app/hooks';
import { useTranslation } from 'react-i18next';
import { Panel } from 'primereact/panel';
import { LineGraph } from 'src/shared/components/plots/LineGraph';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { setPlots } from 'src/app/reducers/modules/plots.reducer';
import { useState } from 'react';

const panelHeaderTemplate = (options, lineData, index, isAdmin, plots, dispatch) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} p-jc-start grid"`;
  return (
    <div>
      <div className={className}>
        <div className="col-12">
          <div className="grid">
            <div className="col-8" style={{ textAlign: 'left' }}>
              <span style={{ marginRight: 10, width: '100%', display: 'block', fontWeight: 'bolder' }} className="p-panel-title">
                {lineData.devName}
              </span>
              {lineData.yAxis === 0 ? <span>{'( Rango Automático ) '}</span> : <span>{'( Rango Manual ) '}</span>}
            </div>
            <div className="col-4" style={{ textAlign: 'right' }}>
              <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
                <Ripple />
              </button>
              <Button
                icon="pi pi-times"
                className="p-button-danger p-m-1 p-button-text"
                onClick={() => {
                  plots.splice(index, 1);
                  dispatch(setPlots(plots));
                }}
                style={{ padding: 0 }}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConvElectricPlotComparative = ({ lineData, index, plots }) => {
  const { t } = useTranslation();
  const [ySymetric, setYSymetric] = useState(false);
  const [yAxis, setYAxis] = useState(0);
  const dispatch = useAppDispatch();
  const datas = lineData.datas;
  const suffix = lineData.suffix;
  const dateFormat = lineData.dateFormat;

  return (
    <div className="md:col-12 lg:col-6" style={{ marginTop: 5 }}>
      <Panel headerTemplate={e => panelHeaderTemplate(e, lineData, index, true, plots, dispatch)} toggleable>
        <div style={{ textAlign: 'right' }}>
          <label htmlFor="cb1" className="p-checkbox-label p-m-1">
            Rango Simetrico
          </label>
          <Checkbox inputId="cb1" value="New York" onChange={e => setYSymetric(e.checked)} checked={ySymetric}></Checkbox>
          <InputNumber
            className={'p-m-1'}
            value={lineData.yAxis}
            onValueChange={e => setYAxis(e.value)}
            showButtons
            size={5}
            suffix={suffix}
            tooltip="Cambiar Rango eje Y"
            inputStyle={{ height: 25 }}
            style={{ height: 25 }}
          />
        </div>
        <LineGraph height={'500px'} rangeSlider={false} suffix={suffix} data={datas} dateFormat={dateFormat} yAxisRange={yAxis} ySimetric={ySymetric} />
      </Panel>
    </div>
  );
};
