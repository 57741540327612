// import { fieldTemplate } from './conv-electric-period-template';
import { DatatableV1 } from 'src/shared/components/general/DatatableV1';
import { useNavigationMenuTitle } from 'src/app/hooks/use-navigation-menu-title';
import { useTranslation } from 'react-i18next';
import { exportFormatter, fieldTemplate } from 'src/shared/components/datatable-templates/conv-electric-period-template';

export const ConvElectricPeriod = () => {
  const { t } = useTranslation();
  useNavigationMenuTitle(t('menu:titleConvElectricPeriod'));
  const row = 100;
  const columns = [
    { field: 'name', type: 'String', width: 100, editable: false },
    { field: 'powerPeriod', type: 'String', width: 120, editable: false },
    { field: 'energyPeriod', type: 'String', width: 120, editable: false },
    { field: 'zone', type: 'String', width: 100, editable: false },
    { field: 'months', type: 'String', width: 100, editable: false },
    { field: 'day', type: 'String', width: 150, editable: false },
    { field: 'schedules', type: 'String', width: 120, editable: false },
    { field: 'timeZone', type: 'String', width: 120, editable: false },
  ].map(column => ({ ...column, header: t('convElectricPeriod:' + column.field) }));

  return (
    <div>
      <DatatableV1
        scrollHeigh="calc( 100vh - 300px )"
        columns={columns}
        objectOptions={[]}
        fieldTemplate={fieldTemplate}
        exportFormatter={exportFormatter}
        rows={row}
        sortField={'macId'}
        getEntitiesUrl={'api/electric-periods'}
      />
    </div>
  );
};
